import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import gsap, { TweenMax, Power0, TimelineMax } from "gsap";
import * as ScrollMagic from "scrollmagic";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
import Conloader from "../componentsheaderfooter/Conloader";

function HireInnersix(props) {
  const { hire6inner } = props;
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    ScrollMagicPluginGsap(ScrollMagic, TweenMax);

    const controller = new ScrollMagic.Controller();

    const triggerElements = [".hireanimation6", ".hireanimation61"];

    triggerElements.forEach((triggerElement, index) => {
      const scene = new ScrollMagic.Scene({
        triggerElement: triggerElement,
        triggerHook: 0.8, // Adjust this value based on when you want the animation to start
        duration: "50%",
        reverse: true,
      });

      var animationTimeline = new TimelineMax();
      animationTimeline.to(
        $(".hireinneranimation6" + (index + 1)),
        {
          x: 0, // Adjust the value as needed
          opacity: 1,
          ease: Power0.easeNone,
        },
        0
      );

      // Set the timeline as the tween for the ScrollMagic scene
      scene.setTween(animationTimeline);
      // Add the scene to the controller
      scene.addTo(controller);
    });

    return () => {
      controller.destroy();
    };
  }, []);

  useEffect(() => {
    if (hire6inner.section_setting === true) {
      setIsLoading(false);
    }
  }, [hire6inner.section_setting]);

  return (
    <>
      {hire6inner.section_setting && hire6inner.section_setting === true ? (
        <section className="expertise-section section-spacing">
          <div className="container">
            <div className="title-box d-flex justify-content-between hireanimation6 hireinneranimation61">
              {isLoading ? (
                <Conloader />
              ) : (
                <>
                  <h2 className="pe-5">
                    {hire6inner.expertise_title}
                  </h2>
                  <p
                    // className="hireinneranimation61"
                    dangerouslySetInnerHTML={{
                      __html: hire6inner.expertise_description,
                    }}
                  ></p>
                </>
              )}
            </div>
            <div className="expertise-programmers hireanimation61 hireinneranimation62">
              {hire6inner.expertise__list &&
                hire6inner.expertise__list.map((res, index) => {
                  return (
                    <div className="box">
                      {isLoading ? (
                        <Conloader />
                      ) : (
                        <>
                          <h5>{res.list_title}</h5>
                          <p
                            dangerouslySetInnerHTML={{ __html: res.list_desc }}
                          ></p>
                        </>
                      )}
                    </div>
                  );
                })}
            </div>
            {isLoading ? (
              <Conloader />
            ) : (
              <Link
                to={hire6inner.expertise_button_link.url}
                className="comman-btn d-table m-auto"
                target={hire6inner.expertise_button_link.target}
                title={hire6inner.expertise_button_link.title}
              >
                {hire6inner.expertise_button_link.title}
              </Link>
            )}
          </div>
        </section>
      ) : (
        <div></div>
      )}
    </>
  );
}

export default HireInnersix;
