import React, { useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { apiCall } from ".././authentication";
const CareerForm = () => {
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
  const [error, setError] = useState([]);
  const handleCaptchaChange = (value) => {
    // This function will be called when the user successfully verifies the reCAPTCHA.
    setIsCaptchaVerified(true);
  };
  const [careerform, setCareerForm] = useState([]);
  const slug1 = "custom/v1/contact-form-fields/29330";
  const slug2 = "contact-form-7/v1/contact-forms/29330/feedback";
  const type = "get";
  const type2 = "post";

  useEffect(() => {
    const fetchForm = async () => {
      try {
        const data = await apiCall(slug1, type);
        setCareerForm(data);
      } catch (error) {
        console.error("Error fetching article:", error);
      }
    };
    fetchForm();
  }, []);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);

    // if (isCaptchaVerified) {
    let isEmpty = false;
    for (const entry of formData.entries()) {
      const [fieldName, value] = entry;
      if (fieldName !== "Resume") {
        if (value.trim() === "") {
          if (fieldName === "Email") {
            setError((error) => ({
              ...error,
              [fieldName]: `Please enter valid ${fieldName}`,
            }));
            isEmpty = true; // Set isEmpty to true if any field is empty
          } else if (fieldName === "hidefield") {
          } else {
            setError((error) => ({
              ...error,
              [fieldName]: `Please fill ${fieldName} field`,
            }));
            isEmpty = true; // Set isEmpty to true if any field is empty
          }
        } else {
          setError((error) => ({
            ...error,
            [fieldName]: "",
          }));
        }
      } else {
        if (value.name.trim() === "") {
          setError((error) => ({
            ...error,
            [fieldName]: `Please fill ${fieldName} field`,
          }));
        } else {
          setError((error) => ({
            ...error,
            [fieldName]: "",
          }));
        }
      }
    }
    if (!isEmpty) {
      if (isCaptchaVerified) {
        const submitForm = async () => {
          try {
            const data = await apiCall(slug2, type2, formData);
            if (data.status === "mail_sent") {
              window.location.replace("/thankyou-careers");
            } else {
              for (let errorMsg in data.invalid_fields) {
                let errorField = data.invalid_fields[errorMsg]["field"];
                let errorFieldMsg = data.invalid_fields[errorMsg]["message"];

                if (errorField === "Resume") {
                  setError((error) => ({
                    ...error,
                    [errorField]: `You are only allowed to upload PDF file.`,
                  }));
                } else {
                  setError((error) => ({
                    ...error,
                    [errorField]: `${errorFieldMsg}`,
                  }));
                }
              }
              // alert(data.data.message);
            }
          } catch (error) {
            console.error("Error submitting data:", error);
          }
        };
        submitForm();
      } else {
        alert("reCAPTCHA verification failed!");
      }
    }
  };

  return (
    <>
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
          <div className="modal-body">
            <div id="careerForm">
              <form id="careerSubmit" onSubmit={handleFormSubmit}>
                <h4 className="careers_heading mb-3"></h4>
                <p className="mb-3 text-center">
                  Share your details below and our HR team will contact you
                  shortly!
                </p>
                {careerform &&
                  careerform.map((response, index) => {
                    return (
                      <>
                        <div className="form-group">
                          {response.type === "select" ? (
                            <>
                              <label>
                                {response.label.replaceAll("-", " ")}
                              </label>
                              <div className="error-messages">
                                <select
                                  className="form-control"
                                  name={response.name}
                                >
                                  {response.options.map((data, key) => {
                                    return (
                                      <>
                                        <option value={data}>{data}</option>;
                                      </>
                                    );
                                  })}
                                </select>
                                {Object.keys(error).length > 0 && (
                                  <>
                                    {console.log("errpr", error)}
                                    {Object.entries(error).map(
                                      ([fieldName, errorMessage]) =>
                                        fieldName === response.name ? (
                                          <p
                                            className="text-danger"
                                            key={fieldName}
                                          >
                                            {errorMessage}
                                          </p>
                                        ) : null
                                    )}
                                  </>
                                )}
                              </div>
                            </>
                          ) : response.type === "hidden" ? (
                            <>
                              <input
                                placeholder={response.name}
                                className="form-control"
                                name={response.name}
                                type={response.type}
                              />
                            </>
                          ) : (
                            <>
                              <label>
                                {response.label.replaceAll("-", " ")}
                              </label>
                              <div className="error-messages">
                                <input
                                  placeholder={response.name}
                                  className="form-control"
                                  name={response.name}
                                  type={response.type}
                                />
                                {Object.keys(error).length > 0 && (
                                  <>
                                    {Object.entries(error).map(
                                      ([fieldName, errorMessage]) =>
                                        fieldName === response.name ? (
                                          <p
                                            className="text-danger"
                                            key={fieldName}
                                          >
                                            {errorMessage}
                                          </p>
                                        ) : null
                                    )}
                                  </>
                                )}
                              </div>
                            </>
                          )}
                        </div>
                      </>
                    );
                  })}
                <div className="recaptcha-error-messages error-messages">
                  <p className="fw-600">Click to confirm</p>
                  <ReCAPTCHA
                    sitekey="6Ldr0V8pAAAAAL6BsypDa0TA5tSgQsVKIoRsNz1s"
                    onChange={handleCaptchaChange}
                    className="recaptcha mt-3"
                  />
                  {Object.keys(error).length > 0 && (
                    <>
                      {Object.entries(error).map(([fieldName, errorMessage]) =>
                        fieldName === "g-recaptcha-response" ? (
                          <p className="text-danger" key={fieldName}>
                            {errorMessage}
                          </p>
                        ) : null
                      )}
                    </>
                  )}
                </div>
                <div className="submit_button">
                  <button type="submit" className="btn btn-primary">
                    Apply Now
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CareerForm;
