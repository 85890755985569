import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import gsap, { TweenMax, Power0, TimelineMax } from "gsap";
import * as ScrollMagic from "scrollmagic";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
import Conloader from "../componentsheaderfooter/Conloader";

function HireInnereight(props) {
  const { hire8inner } = props;
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    ScrollMagicPluginGsap(ScrollMagic, TweenMax);

    const controller = new ScrollMagic.Controller();

    const triggerElements = [".hireanimation8", ".hireanimation81"];

    triggerElements.forEach((triggerElement, index) => {
      const scene = new ScrollMagic.Scene({
        triggerElement: triggerElement,
        triggerHook: 0.8, // Adjust this value based on when you want the animation to start
        duration: "50%",
        reverse: true,
      });

      var animationTimeline = new TimelineMax();
      animationTimeline.to(
        $(".hireinneranimation8" + (index + 1)),
        {
          x: 0, // Adjust the value as needed
          opacity: 1,
          ease: Power0.easeNone,
        },
        0
      );

      // Set the timeline as the tween for the ScrollMagic scene
      scene.setTween(animationTimeline);
      // Add the scene to the controller
      scene.addTo(controller);
    });

    return () => {
      controller.destroy();
    };
  }, []);

  useEffect(() => {
    if (hire8inner.section_setting == true) {
      setIsLoading(false);
    }
  }, [hire8inner.section_setting]);

  return (
    <>
      {hire8inner.section_setting && hire8inner.section_setting == true ? (
        <section className="expert-mobile-services-section section-spacing techstack">
          <div className="container">
            <div className="title-box d-flex hireanimation8 hireinneranimation81">
              {isLoading ? (
                <Conloader />
              ) : (
                <>
                  <h2 className="white-color pe-5">
                    {hire8inner.techstack_title}
                  </h2>
                  <div
                    className="white-color"
                    dangerouslySetInnerHTML={{
                      __html: hire8inner.techstack_content,
                    }}
                  ></div>
                </>
              )}
            </div>
            <div className="all-service expertise hireanimation81 hireinneranimation82">
              {hire8inner.techstack__list &&
                hire8inner.techstack__list.map((res, index) => {
                  return (
                    <>
                      {isLoading ? (
                        <Conloader />
                      ) : (
                        <>
                          <div className="services-box">
                            <h6>{res.list_title}</h6>
                            <ul className="list">
                              {res.list_desc.map((Response, key) => {
                                return (
                                  <>
                                    <li>{Response.lists}</li>
                                  </>
                                );
                              })}
                            </ul>
                          </div>
                        </>
                      )}
                    </>
                  );
                })}
            </div>
            {isLoading ? (
              <Conloader />
            ) : (
              <>
                <Link
                  to={hire8inner.techstack_button_link.url}
                  className="comman-btn top-space white-bg"
                  title={hire8inner.techstack_button_link.title}
                  target={hire8inner.techstack_button_link.target}
                >
                  {hire8inner.techstack_button_link.title}
                </Link>
              </>
            )}
          </div>
        </section>
      ) : (
        <div></div>
      )}
    </>
  );
}

export default HireInnereight;
