import React, { useEffect, useState } from "react";
import BeniFits from "../../src/img/servicesimg/benifits.svg";
import Conloader from "../componentsheaderfooter/Conloader";

const HireInnereleven = (props) => {
  const [hire11inner, sethire11inner] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    sethire11inner(props.hire11innerdata);
  }, [props]);

  useEffect(() => {
    if (hire11inner.section_setting === true) {
      setIsLoading(false);
    }
  }, [hire11inner.section_setting])

  return (
    <>
      {hire11inner?.section_setting && hire11inner.section_setting === true ? (
        <section id="faq" className="section-spacing">
          <div className="container">
            <div className="row mx-0 p-0 justify-content-between faq_head">
              {isLoading ? (
                <Conloader />
              ) : (
                <>
                  <div className="col-md-4 p-0">
                    <h2>{hire11inner.faq_title}</h2>
                  </div>
                  <div className="col-md-7 p-0">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: hire11inner.faq_description,
                      }}
                    ></p>
                  </div>
                </>
              )}
            </div>
            <div className="row mx-0 p-0 justify-content-between faq_acc">
              <div className="col-md-3 p-0 faq_acc_head">
                {/* <p>Still have Questions?</p> */}
                <p>{hire11inner.faq_sub_title}</p>
              </div>
              <div className="col-md-9 p-0">
                <div className="accordion" id="accordionFirstExample">
                  {hire11inner.faq_list &&
                    hire11inner.faq_list.map((response, index) => {
                      return (
                        <>
                          {isLoading ? (
                            <Conloader />
                          ) : (
                            <>
                              <div className="accordion-item">
                                <h2
                                  className="accordion-header"
                                  id={`heading${index}`}
                                >
                                  <button
                                    className={`accordion-button ${index === 0 ? "" : "collapsed"
                                      }`}
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target={`#collapse${index}`}
                                    aria-expanded={index === 0 ? "true" : "false"}
                                    aria-controls={`collapse${index}`}
                                  >
                                    {response.post_title}
                                  </button>
                                </h2>
                                <div
                                  id={`collapse${index}`}
                                  className={`accordion-collapse ${index === 0 ? "collapse show" : "collapse"
                                    }`}
                                  aria-labelledby={`headinguiux${index}`}
                                  data-bs-parent="#accordionFirstExample"
                                >
                                  <div className="accordion-body">
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: response.post_content,
                                      }}
                                    ></p>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default HireInnereleven;
