import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Loader from "../Loader";
import { Link } from "react-router-dom";
import { apiCall } from "../authentication";
import Conloader from "../componentsheaderfooter/Conloader";
import Imgloader from "../componentsheaderfooter/Imgloader";

const Homesectioneight = (props) => {
  const [section8data, setsection8data] = useState([]);
  const [articaleData, setarticaleData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const testimonialslider = {
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    dots: true,
    arrows: false,
    cssEase: "ease",
    adaptiveHeight: true,
  };
  let selectedIds = [];
  let stringIds = "";

  if (
    props.section8data &&
    Array.isArray(props.section8data.select_testmonials)
  ) {
    selectedIds = props.section8data.select_testmonials?.map(
      (section9data) => section9data
    );
    stringIds = selectedIds.toString();
  }
  const slug = `hometestimonial/htestimonial/${stringIds}`;
  const type = "get";
  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      try {
        const data = await apiCall(slug, type);
        setsection8data(data);
        setarticaleData(props.section8data);
        setIsLoading(false);
      } catch (error) {
        // Handle API error
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [props]);
  return (
    <>
      <div>
        {isLoading ? (
          <Imgloader />
        ) : (
          <div>
            {articaleData && articaleData.section_settings === true ? (
              <div className="testimonial-section">
                <div className="homesectioneightstart"></div>
                <div className="section-spacing graybg pb-0">
                  <div className="container">
                    {isLoading ? (
                      <Conloader />
                    ) : (
                      <div className="row">
                        <div className="col-12 col-md-6 mb-md-0 mb-2">
                          <h2 className="page-title fw-bold">
                            {articaleData?.testimonials_title}
                          </h2>
                          <div className="sub-title fw-600">
                            {articaleData?.testimonials_sub_title}
                          </div>
                        </div>
                        <div className="col-12 col-md-6 text-md-end">
                          <Link
                            className="btn button-com mb-2 mx-1"
                            to={articaleData?.our_portfolio_link?.url}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="11.899"
                              height="19.555"
                              viewBox="0 0 11.899 19.555"
                            >
                              <path
                                id="Path_1094"
                                data-name="Path 1094"
                                d="M1122.005,1674.389l7.656,7.656-7.656,7.656"
                                transform="translate(-1119.884 -1672.268)"
                                fill="none"
                                stroke="#fff"
                              ></path>
                            </svg>{" "}
                            {articaleData?.our_portfolio_link?.title}
                          </Link>
                          <Link
                            className="btn button-com mb-2 mx-1"
                            to={articaleData?.testimonials_link?.url}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="11.899"
                              height="19.555"
                              viewBox="0 0 11.899 19.555"
                            >
                              <path
                                id="Path_1094"
                                data-name="Path 1094"
                                d="M1122.005,1674.389l7.656,7.656-7.656,7.656"
                                transform="translate(-1119.884 -1672.268)"
                                fill="none"
                                stroke="#fff"
                              ></path>
                            </svg>{" "}
                            {articaleData?.testimonials_link?.title}
                          </Link>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="section-spacing graybg pt-5">

                  <Slider id="protfolioslider" {...testimonialslider}>
                    {section8data &&
                      section8data?.map((response, index) => {
                        return (
                          <>
                            <div
                              className="slick-slider dot-animation"
                              key={index}
                            >
                              <div className="container">
                                {isLoading ? (
                                  <Imgloader />
                                ) : (
                                  <div className="row">
                                    <div className="col-md-8 col-lg-7 col-12 gx-lg-5 mb-md-0 mb-4">
                                      <div className="d-flex dot-flex-testi">
                                        <div className="dot-testi light-green-testi"></div>
                                        <div className="dot-testi green-testi"></div>
                                        <div className="dot-testi blue-testi"></div>
                                      </div>
                                      <div className="testimonialauthor mt-4">
                                        <div className="sub-title fw-bold mb-2">
                                          {response.project_data?.project_name}
                                        </div>
                                        {/* <p>{response.yoast_head_json?.og_description}</p> */}
                                      </div>
                                      <div className="testimonialauthourwithimage mt-md-4 mt-2 d-flex align-items-center">
                                        <div className="testi-img">
                                          <img
                                            src={response?.featured_image?.src}
                                            alt={response?.featured_image?.alt}
                                          />
                                        </div>
                                        <div className="testiname">
                                          <div className="sub-title mb-0 fw-bold">
                                            {response?.title}
                                          </div>
                                          <div className="fw-bold f14">
                                            {response?.designation}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="mt-md-4 mt-2 testimonialcontent">
                                        <p
                                          dangerouslySetInnerHTML={{
                                            __html: response?.content,
                                          }}
                                        ></p>
                                      </div>
                                      <b
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            response?.testimonial_youtube_title,
                                        }}
                                      ></b>
                                    </div>
                                    <div className="col-md-4 col-lg-5 col-12 text-md-end text-center">
                                      <img
                                        src={
                                          response.project_data?.project_image
                                            ?.src
                                        }
                                        className="img-fluid project-img"
                                        alt={response.project_data?.project_name}
                                      />
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </>
                        );
                      })}
                  </Slider>
                </div>
              </div>
            ) : (
              <div></div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default Homesectioneight;
