import React, { useEffect, useState } from "react";
import PortfolioSlider from "./PortfolioSlider";
import Conloader from "../componentsheaderfooter/Conloader";
import Imgloader from "../componentsheaderfooter/Imgloader";

const PortfolioInnerThree = ({ post }) => {
  const [isLoading, setIsLoading] = useState(true);
  const employ1Setting = {
    centerMode: true,
    variableWidth: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    dots: false,
    arrows: false,
    autoplay: true,
    cssEase: "ease",
    responsive: [
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 1,
          centerMode: false,
          variableWidth: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          centerMode: false,
          variableWidth: false,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          centerMode: false,
          variableWidth: false,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
          centerMode: false,
          variableWidth: false,
        },
      },
    ],
  };

  useEffect(() => {
    if (post.acf.projectportfolio_detail_sections[2].section_settings === true) {
      setIsLoading(false);
    }
  }, [post.acf.projectportfolio_detail_sections[2].section_settings]);
  return (
    <>
      {post &&
        post.acf &&
        post.acf.projectportfolio_detail_sections &&
        post.acf.projectportfolio_detail_sections.length > 0 &&
        post.acf.projectportfolio_detail_sections[2].section_settings ===
        true && (
          <section className="went-section mt-3 mt-md-5 pt-5 pt-md-5 mb-4 pb-4">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  {isLoading ? (
                    <Conloader />
                  ) : (
                    <div className="went-text">
                      <h3>
                        {
                          post?.acf?.projectportfolio_detail_sections[2]
                            ?.portfolio_inner_slider_title
                        }
                      </h3>
                      <p
                        dangerouslySetInnerHTML={{
                          __html:
                            post?.acf?.projectportfolio_detail_sections[2]
                              ?.portfolio_inner_slider_content,
                        }}
                      ></p>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div id="portfolio_slider">
              {isLoading ? (
                <div className="d-flex">
                  <Imgloader />
                  <Imgloader />
                  <Imgloader />
                </div>
              ) : (
                <PortfolioSlider
                  imagePath={
                    post?.acf?.projectportfolio_detail_sections[2]
                      ?.inner_slider_image_section
                  }
                  sliders={employ1Setting}
                />
              )}
            </div>
          </section>
        )}
    </>
  );
};

export default PortfolioInnerThree;
