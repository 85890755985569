import React, { useEffect, useState } from 'react'
import './Brand.css'
import BrandOne from './brand/BrandOne'
import BrandTwo from './brand/BrandTwo';
import BrandThree from './brand/BrandThree';
import CommonCta from './CommonCta';
import { apiCall } from "./authentication";
import { HelmetProvider } from 'react-helmet-async';
import { Helmet } from 'react-helmet';
import Conloader from './componentsheaderfooter/Conloader';

const Brand = () => {
    const [users2, setUsers2] = useState([]);
    const [brandsection1, setBrandsection1] = useState([]);
    const [brandsection2, setBrandsection2] = useState([]);
    const [brandsection3, setBrandsection3] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [yoastData, setYoastData] = useState({});
    const slug = "wp/v2/pages/?slug=brand-journey&acf_format=standard";
    const type = "get";
    let finalreacturl;
    useEffect(() => {
        window.scrollTo(0, 0);
        const fetchData = async () => {
            try {
                const data = await apiCall(slug, type);
                setUsers2(data);
                setBrandsection1(data[0].acf.common_section[0]);
                setBrandsection2(data[0].acf.brand_journey[0]);
                setBrandsection3(data[0].acf.brand_journey[1]);
                setYoastData(data[0].yoast_head_json);

                data[0].acf.common_section.forEach((element) => {
                    if (element.acf_fc_layout === "banner_section") {
                        setBrandsection1(element);
                    }
                });
                setIsLoading(false);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);

    if (yoastData.og_url !== undefined) {
        const wpurl = yoastData.og_url.toString()
        const newurl = wpurl.replace('wpatlas', 'www');
        finalreacturl = newurl.slice(0, newurl.lastIndexOf('/'))
    }
    return (
        <>
            <div>
                {isLoading ? (
                    <div className="container">
                        <div className="row height-animation align-items-center">
                            <div className="col-md-7">
                                <Conloader />
                            </div>
                        </div>
                    </div>
                ) : (
                    <div>
                        <HelmetProvider>
                            <Helmet>
                                <title>{yoastData.title}</title>
                                <meta name="description" content={yoastData.description} />
                                <link rel="canonical" hreflang="en" href={finalreacturl} />
                                <meta
                                    property="og:locale"
                                    content="en_US"
                                    className="yoast-seo-meta-tag"
                                />
                                <meta
                                    property="og:type"
                                    content="article"
                                    className="yoast-seo-meta-tag"
                                />
                                <meta
                                    property="og:title"
                                    content={yoastData.og_title}
                                    className="yoast-seo-meta-tag"
                                />
                                <meta
                                    property="og:description"
                                    content={yoastData.og_description}
                                    className="yoast-seo-meta-tag"
                                />
                                <meta
                                    property="og:url"
                                    content={finalreacturl}
                                    className="yoast-seo-meta-tag"
                                />
                                <meta
                                    property="og:site_name"
                                    content={yoastData.og_site_name}
                                    className="yoast-seo-meta-tag"
                                />
                                <meta
                                    property="article:modified_time"
                                    content={yoastData.article_modified_time}
                                    className="yoast-seo-meta-tag"
                                />
                                <meta
                                    property="og:image"
                                    content={yoastData.url}
                                    className="yoast-seo-meta-tag"
                                />
                                {/* <meta property="og:image:width" content={yoastData.og_image.width} className="yoast-seo-meta-tag" />
                    <meta property="og:image:height" content={yoastData.og_image.height} className="yoast-seo-meta-tag" /> */}
                                {/* <meta property="og:image:type" content={yoastData.og_image.type} className="yoast-seo-meta-tag" />
                    <meta name="twitter:card" content={yoastData.twitter_card} className="yoast-seo-meta-tag" /> */}
                                <meta
                                    name="twitter:label1"
                                    content="Est. reading time"
                                    className="yoast-seo-meta-tag"
                                />
                                <meta
                                    name="twitter:data1"
                                    content="1 minute"
                                    className="yoast-seo-meta-tag"
                                />
                            </Helmet>
                        </HelmetProvider>
                        <BrandOne brands1data={brandsection1} />
                        <BrandTwo brands2data={brandsection2} />
                        <BrandThree brands3data={brandsection3} />
                        <CommonCta />
                    </div>
                )
                }
            </div>
        </>
    )
}

export default Brand