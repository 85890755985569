import React, { useEffect, useState } from 'react';
import BeniFits from '../../src/img/servicesimg/benifits.svg';
import $ from "jquery";
import gsap, { TweenMax, Power0, TimelineMax } from "gsap";
import * as ScrollMagic from "scrollmagic";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
import Conloader from '../componentsheaderfooter/Conloader';
import Imgloader from '../componentsheaderfooter/Imgloader';

const Servicesectionfive = (props) => {
    const { services5data } = props;
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {

        ScrollMagicPluginGsap(ScrollMagic, TweenMax);

        const controller = new ScrollMagic.Controller();

        const scene = new ScrollMagic.Scene({
            triggerElement: '.benefits-section',
            triggerHook: 0.8, // Adjust this value based on when you want the animation to start      
            duration: "50%",
            reverse: true,
        })


        var animationTimeline = new TimelineMax();
        animationTimeline
            .to(
                $(".commonanimation3"),
                {
                    x: 0, // Adjust the value as needed
                    opacity: 1,
                    ease: Power0.easeNone,
                },
                0
            )


        // Set the timeline as the tween for the ScrollMagic scene
        scene.setTween(animationTimeline);
        // Add the scene to the controller
        scene.addTo(controller);

        if (services5data?.section_setting === true) {
            setIsLoading(false);
        }

        return () => {
            controller.destroy();
        };
    }, []);

    return (
        <>
            {services5data?.section_setting === true ? (
                <section className="benefits-section section-spacing pt-0">
                    <div className="container commonanimation3">
                        <div className="row mb-5 pb-md-5">
                            <div className="col-12">
                                {isLoading ? (
                                    <Conloader />
                                ) : (
                                    <div className="benefits-content">
                                        <h3>{services5data?.benefit_main_title}</h3>
                                        <h2>{services5data?.benefit_sub_title}</h2>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="row mt-5">
                            {services5data.benefits_details && services5data.benefits_details.map((res, index) => {
                                return (
                                    <div className="col-md-4 mb-4">
                                        {isLoading ? (
                                            <Conloader />
                                        ) : (
                                            <ServiceCard
                                                title={res?.benefits_title}
                                                details={res?.benefits_desc} />
                                        )}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </section>
            ) : (
                <div></div>
            )}
        </>
    )
}

function ServiceCard(props) {
    return (
        <>
            <div className="benefits-main-text d-flex align-items-center">
                <img src={BeniFits} className="img-fluiid" alt='' />
                <p>{props.title}</p>
            </div>
            <div className="benefits-content mt-3">
                <p>{props.details}</p>
            </div>
        </>
    )
}

export default Servicesectionfive