import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import gsap, { TweenMax, Power0, TimelineMax } from "gsap";
import * as ScrollMagic from "scrollmagic";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
import Conloader from "../componentsheaderfooter/Conloader";
import Imgloader from "../componentsheaderfooter/Imgloader";

const Hiresectionfive = (props) => {
  const { hire5data } = props;
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    ScrollMagicPluginGsap(ScrollMagic, TweenMax);

    const controller = new ScrollMagic.Controller();

    const scene = new ScrollMagic.Scene({
      triggerElement: ".hire-mobile-app-section",
      triggerHook: 0.8, // Adjust this value based on when you want the animation to start
      duration: "50%",
      reverse: true,
    });

    var animationTimeline = new TimelineMax();
    animationTimeline.to(
      $(".commonanimation3"),
      {
        x: 0, // Adjust the value as needed
        opacity: 1,
        ease: Power0.easeNone,
      },
      0
    );

    // Set the timeline as the tween for the ScrollMagic scene
    scene.setTween(animationTimeline);
    // Add the scene to the controller
    scene.addTo(controller);

    return () => {
      controller.destroy();
    };
  }, []);

  useEffect(() => {
    if (hire5data.section_setting === true) {
      setIsLoading(false);
    }
  }, [hire5data.section_setting])

  return (
    <>
      {hire5data.section_setting && hire5data.section_setting === true ? (
        <section className="hire-mobile-app-section section-spacing pt-0">
          <div className="container">
            <div className="title-box commonanimation3">
              {isLoading ? (
                <Conloader />
              ) : (
                <>
                  <h2>{hire5data.hmadev_section_title}</h2>
                </>
              )}
            </div>
            <div className="d-flex align-items-center justify-content-between commonanimation3">
              <div className="img-box">
                {isLoading ? (
                  <Imgloader />
                ) : (
                  <>
                    <img
                      src={hire5data.hmdev_section_image?.url}
                      alt="hire-mobile-app-developers.png"
                    />
                  </>
                )}
              </div>
              <div className="content-area">
                {isLoading ? (
                  <Conloader />
                ) : (
                  <>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: hire5data.hmdev_section_content,
                      }}
                    ></p>
                    <Link
                      to={hire5data.hmdev_section_button_link.url}
                      className="comman-btn"
                      title="Hire Our Experts"
                      data-bs-toggle="modal"
                      data-bs-target="#FormModal"
                      onClick={props.handleClose}
                    >
                      {hire5data.hmdev_section_button_link.title}
                    </Link>
                  </>
                )}
              </div>
            </div>
          </div>
        </section>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default Hiresectionfive;
