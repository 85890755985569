import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import VacuumSlide from '../../src/img/portfolio/vacuum-slide.png';

const PortfolioSlider = ({ sliders, imagePath }) => {
    return (
        <Slider {...sliders}>
            {imagePath && imagePath.map((res, index) => (
                <EmploySlid img={res.portfolio_inner_slider_image.url} />
            ))}
        </Slider>
    )
}


function EmploySlid(props) {
    return (
        <div className='slider_inner'>
            <figure>
                <img src={props.img} alt='' />
            </figure>
        </div>
    )
}

export default PortfolioSlider