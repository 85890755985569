// import React, { useEffect } from "react";
// import AtlasAccordion from "../../src/img/aboutimg/atlas_bg.png";
// import gsap from "gsap";
// import * as ScrollMagic from "scrollmagic";
// import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";

// const Aboutsectionthree = (props) => {
//   const { about3data } = props;

//   useEffect(() => {
//     // Initialize ScrollMagic and GSAP
//     ScrollMagicPluginGsap(ScrollMagic, gsap);
//     const controller = new ScrollMagic.Controller();

//     about3data?.vision_mission_details.forEach((item, index) => {
//       // Scene for fading in the element
//       const fadeInScene = new ScrollMagic.Scene({
//         triggerElement: `#flush-heading${index}`,
//         triggerHook: 0.5,
//       })
//         .setTween(
//           gsap.fromTo(
//             `#flush-collapse${index}`,
//             {
//               opacity: 0,
//               height: 0,
//               y: 50,
//             },
//             {
//               opacity: 1,
//               height: "auto", // Set to "auto" for full height
//               y: 0,
//               duration: 1,
//               ease: "power2.out",
//             }
//           )
//         )
//         .setClassToggle(`#flush-heading${index}`, 'active')
//         .addTo(controller);

//       // Scene for fading out the previous element with a delay
//       if (index > 0) {
//         const fadeOutScene = new ScrollMagic.Scene({
//           triggerElement: `#flush-heading${index}`,
//           triggerHook: 0.5,
//           offset: -0.3, // Add a delay of 0.3 seconds
//         })
//           .setTween(
//             gsap.to(`#flush-collapse${index - 1}`, {
//               opacity: 0,
//               height: 0,
//               y: -50,
//               duration: 1,
//               ease: "power2.out",
//             })
//           )
//           .addTo(controller);
//       }
//     });
//   }, [about3data]);

//   return (
//     <>
//       {about3data.section_settings === true ? (
//         <div className="section-spacing px-3 position-relative overflow-hidden">
//           <img className="atlas_bg" src={AtlasAccordion} alt="" />
//           <div className="container">
//             <div className="about-accordion">
//               <div
//                 className="accordion accordion-flush"
//                 id="accordionFlushExample"
//               >
//                 <div className="accordion-item accordion-item-head">
//                   {about3data?.vision_mission_details.map((item, index) => (
//                     <h2
//                       className="accordion-header"
//                       id={`flush-heading${index}`}
//                       key={index}
//                     >
//                       <button
//                         className="accordion-button"
//                         type="button"
//                         data-bs-toggle="collapse"
//                         aria-expanded={index === 0 ? "true" : "false"}
//                         aria-controls={`flush-collapse${index}`}
//                       >
//                         {item.vision_mission_title}
//                       </button>
//                     </h2>
//                   ))}
//                 </div>
//                 <div className="accordion-item">
//                   {about3data?.vision_mission_details.map((item, index) => (
//                     <div
//                       id={`flush-collapse${index}`}
//                       className="accordion-collapse collapse"
//                       aria-labelledby={`flush-heading${index}`}
//                       data-bs-parent="#accordionFlushExample"
//                       key={index}
//                     >
//                       <div className="accordion-body">
//                         <p
//                           dangerouslySetInnerHTML={{
//                             __html: item.vision_mission_content,
//                           }}
//                         />
//                       </div>
//                     </div>
//                   ))}
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       ) : (
//         <div></div>
//       )}
//     </>
//   );
// };

// export default Aboutsectionthree;
import React, { useEffect, useState } from "react";
import AtlasAccordion from "../../src/img/aboutimg/atlas_bg.png";
import Conloader from "../componentsheaderfooter/Conloader";

const Aboutsectionthree = (props) => {
  const { about3data } = props;
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (about3data.section_settings === true) {
      setIsLoading(false);
    }
  }, [about3data.section_settings]);
  return (
    <>
      {about3data.section_settings === true ? (
        <div className=" px-3 position-relative overflow-hidden">
          <img className="atlas_bg" src={AtlasAccordion} alt="" />
          <div className="container">
            <div className="section-spacing row align-items-center min-vh-100">
              <div className="col-12">
                <div className="about-accordion">
                  <div
                    className="accordion accordion-flush"
                    id="accordionFlushExample"
                  >
                    <div className="accordion-item accordion-item-head">
                      {isLoading ? (
                        <Conloader />
                      ) : (
                        <>
                          <h2 className="accordion-header" id="flush-headingOne">
                            <button
                              className="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#flush-collapseOne"
                              aria-expanded="true"
                              aria-controls="flush-collapseOne"
                            >
                              {
                                about3data?.vision_mission_details[0]
                                  ?.vision_mission_title
                              }
                            </button>
                          </h2>
                          <h2 className="accordion-header" id="flush-headingTwo">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#flush-collapseTwo"
                              aria-expanded="false"
                              aria-controls="flush-collapseTwo"
                            >
                              {/* Mission */}
                              {
                                about3data?.vision_mission_details[1]
                                  ?.vision_mission_title
                              }
                            </button>
                          </h2>
                          <h2 className="accordion-header" id="flush-headingThree">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#flush-collapseThree"
                              aria-expanded="false"
                              aria-controls="flush-collapseThree"
                            >
                              {/* Values */}
                              {
                                about3data?.vision_mission_details[2]
                                  ?.vision_mission_title
                              }
                            </button>
                          </h2>
                        </>
                      )}

                    </div>
                    <div className="accordion-item">
                      {isLoading ? (
                        <Conloader />
                      ) : (
                        <>
                          <div
                            id="flush-collapseOne"
                            className="accordion-collapse collapse show"
                            aria-labelledby="flush-headingOne"
                            data-bs-parent="#accordionFlushExample"
                          >
                            <div className="accordion-body">
                              <p
                                dangerouslySetInnerHTML={{
                                  __html:
                                    about3data?.vision_mission_details[0]
                                      ?.vision_mission_content,
                                }}
                              />
                            </div>
                          </div>
                          <div
                            id="flush-collapseTwo"
                            className="accordion-collapse collapse"
                            aria-labelledby="flush-headingTwo"
                            data-bs-parent="#accordionFlushExample"
                          >
                            <div className="accordion-body">
                              <p
                                dangerouslySetInnerHTML={{
                                  __html:
                                    about3data?.vision_mission_details[1]
                                      ?.vision_mission_content,
                                }}
                              />
                            </div>
                          </div>
                          <div
                            id="flush-collapseThree"
                            className="accordion-collapse collapse"
                            aria-labelledby="flush-headingThree"
                            data-bs-parent="#accordionFlushExample"
                          >
                            <div className="accordion-body">
                              <p
                                dangerouslySetInnerHTML={{
                                  __html:
                                    about3data?.vision_mission_details[2]
                                      ?.vision_mission_content,
                                }}
                              />
                            </div>
                          </div>
                        </>
                      )}

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default Aboutsectionthree;
