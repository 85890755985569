import React, { useEffect, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Slider1 = ({ settings, slider2, sliderdata }) => {
  return (
    <>
      <Slider
        className="technologies_thumb slider-thumb text-white"
        {...settings}
      >
        {sliderdata &&
          sliderdata.length > 0 &&
          sliderdata.map((key, index) => (
            <div key={index} className="thumb_name">
              <div className="thumb-icon">
                <h3>{key.technology_name}</h3>
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="38"
                    height="38"
                    viewBox="0 0 38 38"
                  >
                    <g
                      id="Group_5040"
                      data-name="Group 5040"
                      transform="translate(-762 -2216)"
                    >
                      <circle
                        id="Ellipse_194"
                        data-name="Ellipse 194"
                        cx="6"
                        cy="6"
                        r="6"
                        transform="translate(775 2229)"
                        fill="#a2ba5f"
                      />
                      <g
                        id="Group_3006"
                        data-name="Group 3006"
                        transform="translate(37 720)"
                      >
                        <g
                          id="Ellipse_199"
                          data-name="Ellipse 199"
                          transform="translate(725 1496)"
                          fill="none"
                          stroke="#a2ba5f"
                        >
                          <circle cx="19" cy="19" r="19" stroke="none" />
                          <circle cx="19" cy="19" r="18.5" fill="none" />
                        </g>
                        <path
                          id="Polygon_12"
                          data-name="Polygon 12"
                          d="M2.5,0,5,4H0Z"
                          transform="translate(759.5 1513) rotate(90)"
                          fill="#a2ba5f"
                        />
                        <path
                          id="Polygon_13"
                          data-name="Polygon 13"
                          d="M2.5,0,5,4H0Z"
                          transform="translate(728.5 1518) rotate(-90)"
                          fill="#a2ba5f"
                        />
                      </g>
                    </g>
                  </svg>
                </span>
              </div>
            </div>
          ))}
      </Slider>
    </>
  );
};

export default Slider1;
