import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Conloader from "../componentsheaderfooter/Conloader";
import Imgloader from "../componentsheaderfooter/Imgloader";

const Contacttwo = (props) => {
  const { contact2data } = props;
  const { contact2datainner } = props;
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (contact2data.section_settings === true) {
      setIsLoading(false);
    }
  }, [contact2data.section_settings]);
  return (
    <>
      {contact2data.section_settings == true ? (
        <section className="our-office-section section-spacing">
          <div className="container">
            <div className="d-flex office-acknowledgements align-items-center">
              <div className="our-office">
                {isLoading ? (
                  <div>
                    <Conloader />
                    <Conloader />
                  </div>
                ) : (
                  <>
                    <div className="title-box">
                      <div className="d-flex">
                        <img
                          src={contact2data.office_logo}
                          alt={contact2data.office_title}
                        />
                        <h2>{contact2data.office_title}</h2>
                      </div>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: contact2data.office_content,
                        }}
                      ></p>
                    </div>
                    <ul>
                      {contact2data.social_media_details &&
                        contact2data.social_media_details.map((response, index) => {
                          return (
                            <li>
                              <Link to={response?.social_link}>
                                <img
                                  width="30px"
                                  height="30px"
                                  src={response.logo}
                                  alt="facebook"
                                />
                              </Link>
                            </li>
                          );
                        })}
                    </ul>
                  </>
                )}
              </div>
              <div className="our-acknowledgements">
                {isLoading ? (
                  <>
                    <Imgloader />
                    <Imgloader />
                  </>
                ) : (
                  <img
                    src={contact2datainner.office_building_image}
                    className="border-radius"
                    alt={contact2datainner.office_building_image}
                  />
                )}
              </div>
            </div>
          </div>
        </section>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default Contacttwo;
