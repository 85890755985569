import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import requestimg from "../img/homeimg/request-quote-img.png";
import Button from "../button/button";
import Loader from "../Loader";
import { apiCall } from "../authentication";

const Hiresectionnine = () => {
  const [hiresection9, setHiresection9] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const slug1 = "options/all?_fields=footer_cta_section";
  const type = "get";
  useEffect(() => {
    window.scrollTo(0, 0);
    setIsLoading(true);
    const fetchCta = async (id) => {
      try {
        const data = await apiCall(slug1, type);
        setHiresection9(data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };
    fetchCta();
  }, []);
  return (
    <>
      <div>
        <div>
          {hiresection9?.footer_cta_section?.section_setting === true ? (
            <div className="graybg">
              <div className="container-fluid px-xl-0">
                <div className="row gx-lg-0 align-items-center dot-animation">
                  <div className="col-xl-6 col-12 mb-xl-0 mb-3 order-xl-2 p-0">
                    <img
                      src={hiresection9?.footer_cta_section?.cta_image}
                      className="img-fluid w-100"
                      alt="Request"
                    />
                  </div>
                  <div className="col-xl-6 text-xl-start text-center col-12 pb-xl-0 pb-5">
                    <div className="requestdiv">
                      <div className="d-flex dot-flex-testi mb-4 justify-content-xl-start justify-content-center">
                        <div className="dot-testi light-green-testi"></div>
                        <div className="dot-testi green-testi"></div>
                        <div className="dot-testi blue-testi"></div>
                      </div>
                      <div className="f94 mb-4">
                        {hiresection9?.footer_cta_section?.cta_title}
                      </div>
                      <div className="page-title fw-bold">
                        {hiresection9?.footer_cta_section?.cta_sub_title}
                      </div>

                      <Button
                        classadd="mt-4"
                        class="dark-bg"
                        link={hiresection9?.footer_cta_section?.cta_link?.url}
                        target={
                          hiresection9?.footer_cta_section?.cta_link?.target
                        }
                        name={
                          hiresection9?.footer_cta_section?.cta_link?.title
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </div>
    </>
  );
};

export default Hiresectionnine;
