import React, { useEffect, useState } from 'react'
import Imgloader from '../componentsheaderfooter/Imgloader';
import Conloader from '../componentsheaderfooter/Conloader';

function BrandTwo(props) {
    const { brands2data } = props;
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (brands2data.section_settings === true) {
            setIsLoading(false);
        }
    }, [brands2data.section_settings]);

    return (
        <>
            {brands2data.section_settings === true ? (
                <div className="section-spacing pt-0" id='brand-inception'>
                    <div className="container">
                        <div className="row justify-content-between gx-0 brandrow">
                            {brands2data.brand_journey_details && brands2data.brand_journey_details.map((res, index) => {
                                return (
                                    <div key={index} className='col-lg-4 mb-lg-0 mb-5 col-12 customtopborder'>
                                        {isLoading ? (
                                            <div>
                                                <Imgloader />
                                                <Conloader />
                                            </div>
                                        ) : (

                                            <div className='customtopborderinnerone'>
                                                <div className='customtopborderinnertwo px-lg-4'>
                                                    <p className='years'>{res.year}</p>
                                                    {res.image === false ? (
                                                        <></>
                                                    ) :
                                                        (
                                                            <img src={res.image.url} className='mb-5 logoimgbrand' alt={res.image.alt} />
                                                        )
                                                    }
                                                    <h3>{res.title}</h3>
                                                    <h2>{res.sub_title}</h2>
                                                    <div className='since-title mb-0' dangerouslySetInnerHTML={{ __html: res.content }}></div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            ) : (
                <div></div>
            )}
        </>
    )
}

export default BrandTwo