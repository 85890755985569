/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import playicon from "../img/homeimg/play.svg";
import $ from "jquery";
import gsap, { TweenMax, Power0, TimelineMax } from "gsap";
import * as ScrollMagic from "scrollmagic";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
import Imgloader from "../componentsheaderfooter/Imgloader";
import Conloader from "../componentsheaderfooter/Conloader";

const Aboutsectiontwo = (props) => {
  const { about2data } = props;
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    ScrollMagicPluginGsap(ScrollMagic, TweenMax);

    const controller = new ScrollMagic.Controller();

    const scene = new ScrollMagic.Scene({
      triggerElement: ".aboutsectiontwoanimation",
      triggerHook: 0.8, // Adjust this value based on when you want the animation to start
      duration: "50%",
      reverse: true,
    });

    var animationTimeline = new TimelineMax();
    animationTimeline.to(
      $(".commonanimation"),
      {
        x: 0, // Adjust the value as needed
        opacity: 1,
        ease: Power0.easeNone,
      },
      0
    );

    // Set the timeline as the tween for the ScrollMagic scene
    scene.setTween(animationTimeline);
    // Add the scene to the controller
    scene.addTo(controller);

    return () => {
      controller.destroy();
    };
  }, []);
  useEffect(() => {
    if (about2data[0].section_setting === true) {
      setIsLoading(false);
    }
  }, [about2data[0].section_setting]);
  return (
    <>
      {about2data[0].section_setting === true ? (
        <div className="section-spacing atlasdark aboutsectiontwoanimation">
          <div className="container">
            <div className="row justify-content-between aboutblockimg">
              <div className="col-md-5 col-12 mb-md-0 mb-3 commonanimation">
                {isLoading ? (
                  <Imgloader />
                ) : (
                  <div className="careerblock position-relative h-100">
                    <div className="careerblockimg position-relative h-100">
                      <img
                        src={about2data[0].image.url}
                        className="img-fluid w-100 careerblockimg"
                        alt="Career"
                      />
                      <iframe
                        class="embed-responsive-item w-100 h-100 atlas_life_videos"
                        src={about2data?.[0]?.video}
                        allow="autoplay"
                        allowfullscreen
                      ></iframe>
                    </div>
                    <div className="aboutblockcontent">
                      <div>
                        <Link
                          to="#"
                          data-bs-toggle="modal"
                          data-bs-target="#aboutModal"
                        >
                          <img src={playicon} className="playimg" alt="Play" />
                        </Link>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div
                className="modal fade"
                id="aboutModal"
                tabIndex="-1"
                aria-labelledby="aboutModallLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                  <div className="modal-content">
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                    <div className="modal-body">
                      <div className="embed-responsive embed-responsive-16by9">
                        <iframe
                          className="embed-responsive-item w-100"
                          src={`${about2data?.[0]?.video}`}
                        ></iframe>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-12 commonanimation">
                {isLoading ? (
                  <Conloader />
                ) : (
                  <>
                    <h2 className="page-title fw-bold mb-2 text-white">
                      {about2data[0].section_title}
                    </h2>
                    <p
                      className="atlas-paragraph text-white"
                      dangerouslySetInnerHTML={{
                        __html: about2data[0].description,
                      }}
                    ></p>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default Aboutsectiontwo;
