import React, { useEffect, useState } from "react";
import $ from "jquery";
import gsap, { TweenMax, Power0, TimelineMax } from "gsap";
import * as ScrollMagic from "scrollmagic";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
import Conloader from "../componentsheaderfooter/Conloader";

const Hiresectionthree = (props) => {
  const { hire3data } = props;
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    ScrollMagicPluginGsap(ScrollMagic, TweenMax);

    const controller = new ScrollMagic.Controller();

    const scene = new ScrollMagic.Scene({
      triggerElement: ".expert-mobile-services-section",
      triggerHook: 0.8, // Adjust this value based on when you want the animation to start
      duration: "50%",
      reverse: true,
    });

    var animationTimeline = new TimelineMax();
    animationTimeline.to(
      $(".commonanimation1"),
      {
        x: 0, // Adjust the value as needed
        opacity: 1,
        ease: Power0.easeNone,
      },
      0
    );

    // Set the timeline as the tween for the ScrollMagic scene
    scene.setTween(animationTimeline);
    // Add the scene to the controller
    scene.addTo(controller);

    return () => {
      controller.destroy();
    };
  }, []);

  useEffect(() => {
    if (hire3data?.section_setting === true) {
      setIsLoading(false);
    }
  }, [hire3data?.section_setting]);

  return (
    <>
      {hire3data && hire3data?.section_setting === true ? (
        <section className="expert-mobile-services-section section-spacing">
          <div className="container commonanimation1">
            <div className="title-box">
              {isLoading ? (
                <Conloader />
              ) : (
                <>
                  <h2 className="white-color">{hire3data?.expert_section_title}</h2>
                </>
              )}
            </div>
            <div className="all-service">
              {hire3data?.expert_section_content?.map((response, index) => {
                return (
                  <>
                    {isLoading ? (
                      <Conloader />
                    ) : (
                      <>
                        <div className="services-box dot-animation">
                          <div>
                            <h6>{response.title}</h6>
                            <p>{response.description}</p>
                          </div>
                          <div className="d-flex w-100 dot-flex mt-md-4 mt-2 justify-content-end mb-0">
                            <div className="dot-sec mb-0"></div>
                            <div className="dot-sec1 mb-0"></div>
                            <div className="dot-sec2 mb-0"></div>
                          </div>
                        </div></>
                    )}
                  </>
                );
              })}
            </div>
          </div>
        </section>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default Hiresectionthree;
