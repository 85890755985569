import React, { useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { apiCall } from "../authentication";
export default function Form() {
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
  const [error, setError] = useState([]);
  const [hireForm, setHireForm] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);

  const slug1 = "contact-form-7/v1/contact-forms/29799/feedback";
  const slug2 = "custom/v1/contact-form-fields/29799";
  const type = "post";
  const type2 = "get";
  const nextStep = () => {
    const form = document.getElementById("formCareer");
    const formFields = form.querySelectorAll("form .d-flex input");

    let isEmpty = false;

    if (currentStep === 0) {
      for (let i = 0; i < formFields.length; i++) {
        const fieldValue = formFields[i].value.trim();
        if (formFields[i].type === "email") {
          const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          const isValid = emailPattern.test(fieldValue);
          if (!isValid) {
            isEmpty = true;

            setError((error) => ({
              ...error,
              [formFields[i].name]: `Please fill valid ${formFields[i].name}`,
            }));
          } else {
            setError((error) => ({
              ...error,
              [formFields[i].name]: "",
            }));
          }
        } else {
          if (!fieldValue) {
            isEmpty = true;
            setError((error) => ({
              ...error,
              [formFields[i].name]: `Please fill valid ${formFields[i].name}`,
            }));
          } else {
            setError((error) => ({
              ...error,
              [formFields[i].name]: " ",
            }));
          }
        }
      }
    }

    if (!isEmpty) {
      setCurrentStep((prevStep) => prevStep + 1);
    }
  };

  const previousStep = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const handleCaptchaChange = (value) => {
    setIsCaptchaVerified(true);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);

    if (isCaptchaVerified) {
      const submitForm = async () => {
        try {
          const data = await apiCall(slug1, type, formData);
          if (data.status === "mail_sent") {
            window.location.replace("/thankyou");
          } else {
            alert(data.message);
          }
        } catch (error) {
          console.error("Error submitting data:", error);
        }
      };
      submitForm();
    } else {
      setError((error) => ({
        ...error,
        ["g-recaptcha-response"]: `Please fill valid g-recaptcha-response`,
      }));
    }

    // You can perform additional actions or make API requests here
  };

  useEffect(() => {
    const fetchForm = async () => {
      try {
        const data = await apiCall(slug2, type2);
        setHireForm(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchForm();
  }, []);

  const handleClose = () => {
    setCurrentStep(0);
    const formElement = document.getElementById("formCareer");
    if (formElement) {
      formElement.reset();
      setError({});
      setIsCaptchaVerified(false);
    }
  };
  return (
    <>
      <div class="modal-dialog modal-lg modal-dialog-centered hiredevloperform">
        <div className="modal-content">
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={handleClose}
          ></button>
          <div className="modal-body">
            <div id="careerForm">
              <form id="formCareer" onSubmit={handleFormSubmit}>
                <h4 className="careers_heading mb-3">
                  Hire Dedicated Developer
                </h4>
                <p className="mb-3 text-center ">
                  Fill out the form and let us reach out to you with a skilled
                  professional that meets your unique project needs!
                </p>
                {hireForm &&
                  hireForm.map((response, key) => {
                    return (
                      <>
                        <div
                          className={`form-group ${
                            response.type === "checkbox" ||
                            response.type === "radio"
                              ? "select_butons"
                              : ""
                          } ${
                            currentStep === 0 && key > 4 ? "d-none" : "d-flex"
                          } ${
                            currentStep === 1
                              ? key > 10 || key <= 4
                                ? "d-none"
                                : "d-flex"
                              : ""
                          } ${
                            currentStep === 2
                              ? key > 13 || key <= 10
                                ? "d-none"
                                : "d-flex"
                              : ""
                          }`}
                        >
                          {response.type === "checkbox" ||
                          response.type === "radio" ? (
                            <>
                              <label>
                                {response.label.replaceAll("-", " ")}
                              </label>
                              {response.options.map((data, index) => {
                                return (
                                  <>
                                    <div className="services-select">
                                      <input
                                        type={response.type}
                                        name={response.name}
                                        id={response.name + index}
                                        value={data}
                                      />
                                      <label for={response.name + index}>
                                        {data}
                                      </label>
                                    </div>
                                  </>
                                );
                              })}
                            </>
                          ) : (
                            <>
                              <label
                                className="contact-label"
                                for={response.name}
                              >
                                {response.label.replaceAll("-", " ")}
                              </label>
                              <div className="error-messages">
                                <input
                                  className={`contact-input ${
                                    response.type === "tel"
                                      ? "numericInput"
                                      : ""
                                  }`}
                                  type={response.type}
                                  id={response.name}
                                  name={response.name}
                                  placeholder={response.options?.[0]}
                                />
                                {Object.keys(error).length > 0 && (
                                  <>
                                    {Object.entries(error).map(
                                      ([fieldName, errorMessage]) =>
                                        fieldName === response.name ? (
                                          <p
                                            className="text-danger"
                                            key={fieldName}
                                          >
                                            {errorMessage}
                                          </p>
                                        ) : null
                                    )}
                                  </>
                                )}
                              </div>
                            </>
                          )}
                        </div>
                      </>
                    );
                  })}
                {currentStep === 2 ? (
                  <>
                    <div className="recaptcha-error-messages error-messages">
                      <p className="mb-0 text-center fw-600">
                        Click to confirm
                      </p>
                      <ReCAPTCHA
                        sitekey="6Ldr0V8pAAAAAL6BsypDa0TA5tSgQsVKIoRsNz1s"
                        onChange={handleCaptchaChange}
                        className="recaptcha google-recaptcha google-recaptchacenter mt-2"
                      />
                      {Object.keys(error).length > 0 && (
                        <>
                          {Object.entries(error).map(
                            ([fieldName, errorMessage]) =>
                              fieldName === "g-recaptcha-response" ? (
                                <p className="text-danger" key={fieldName}>
                                  {errorMessage}
                                </p>
                              ) : null
                          )}
                        </>
                      )}
                    </div>
                  </>
                ) : null}
                <div className="submit_button">
                  {currentStep > 0 && (
                    <button
                      className="btn btn-primary me-2"
                      type="button"
                      onClick={previousStep}
                    >
                      Previous
                    </button>
                  )}
                  {currentStep <= 1 && (
                    <button
                      className="btn btn-primary"
                      type="button"
                      onClick={nextStep}
                    >
                      Next
                    </button>
                  )}
                  {currentStep === 2 && (
                    <button type="submit" className="btn btn-primary">
                      Send
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
