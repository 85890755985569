import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Loader from "../Loader";
import { Spinner } from "../Spinner";
import image from "../img/placeholder-1-1.webp";
import Authentication, { apiCall } from "../authentication";
import Conloader from "../componentsheaderfooter/Conloader";
import Imgloader from "../componentsheaderfooter/Imgloader";

const PortfolioTwo = ({ caseStudydata, technology, industry }) => {
  let i = 0;
  const [visibleItems, setVisibleItems] = useState(6);
  const [portfolioPost, setPortfolioPost] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [netData, setNetData] = useState(
    caseStudydata ? "case-studies" : "project"
  );
  const [caseData, setCaseData] = useState(
    caseStudydata ? "case-studies" : "work"
  );
  const [filteredPosts, setFilteredPosts] = useState([]);
  let slugUrl = "";
  // eslint-disable-next-line no-lone-blocks
  {
    caseStudydata
      ? (slugUrl = "workcasestudies/wcslist/100?orderby=date&order=desc")
      : (slugUrl = "workproject/wprojectslist/100?orderby=date&order=desc");
  }
  const slug1 = `${slugUrl}`;
  const type = "get";

  useEffect(() => {
    window.scrollTo(0, 0);
    // Set netData based on caseStudy prop
    setNetData(caseStudydata ? "case-studies" : "project");
    setCaseData(caseStudydata ? "case-studies" : "work");
  }, [caseStudydata]);
  const fetchPost = async () => {
    setIsLoading(true);
    try {
      const data = await apiCall(slug1, type);

      setIsLoading(false);
      setPortfolioPost(data);
    } catch (error) {
      console.error("Error fetching portflioPost:", error);
    }
  };

  // Function to filter posts based on selected technology
  const filterDataByTechnology = (technology, netData) => {
    if (technology === undefined || technology === "Technology") {
      setFilteredPosts(portfolioPost); // Reset to display all posts if no technology selected
    } else {
      try {
        let filteredData = [];
        let filteredCaseData = [];
        // console.log("portfolioPost", portfolioPost);
        if (netData === "project") {
          filteredData = portfolioPost.filter((item) =>
            item?.technology.some((tech) => tech.ID === parseInt(technology))
          );
        } else {
          filteredCaseData = portfolioPost.filter((item) =>
            item?.technology.ID.some((tech) => tech.ID === parseInt(technology))
          );
        }

        const filteredPosts =
          netData === "project" ? filteredData : filteredCaseData;

        setFilteredPosts(filteredPosts); // Set filtered posts based on selected technology and netData
      } catch (error) {
        console.error(error);
        setFilteredPosts(portfolioPost); // Reset to display all posts in case of an error
      }
    }
  };

  const filterDataByIndustries = (industry, netData) => {
    if (industry === undefined || industry === "Industry") {
      setFilteredPosts(portfolioPost); // Reset to display all posts if no industry selected
    } else {
      try {
        let filteredData = [];
        if (netData === "project" || netData === "case-studies") {
          filteredData = portfolioPost.filter((item) =>
            item["industrytype"].some((tech) => tech === parseInt(industry))
          );
        }

        const filteredPosts = filteredData;

        setFilteredPosts(filteredPosts); // Set filtered posts based on selected industry and netData
      } catch (error) {
        console.error(error);
        setFilteredPosts(portfolioPost); // Reset to display all posts in case of an error
      }
    }
  };

  useEffect(() => {
    // Check if netData is not empty before fetching

    fetchPost();
  }, [netData]);
  const handleLoadMore = () => {
    setVisibleItems((prev) => prev + 3);
  };

  useEffect(() => {
    filterDataByTechnology(technology, netData);
  }, [technology, isLoading]);

  useEffect(() => {
    filterDataByIndustries(industry, netData);
  }, [industry, isLoading]);
  // portfolioPost.length > 0 ?  setIsLoading(false) : setIsLoading(true);
  return (
    <>
      {portfolioPost ? (
        <div className="section-spacing">
          <div className="container">
            <div className="porfolio-card-main">
              {filteredPosts !== null &&
                filteredPosts.length > 0 &&
                filteredPosts.slice(0, visibleItems).map((item, index) => {
                  if (i >= 7) {
                    i = 1;
                  } else {
                    i++;
                  }
                  return (
                    <PortfolioCard
                      post={item}
                      casenewData={caseData}
                      key={index}
                      netData={netData}
                      id={item["industrytype"]}
                      indexes={i}
                    />
                  );
                })}
            </div>
            {filteredPosts.length == 0 && <span>No content found</span>}
            {filteredPosts.slice(0, visibleItems).length >= 6 && (
              <div className="section-spacing pb-0 portfolio-btn">
                <Link onClick={handleLoadMore}>Load More</Link>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
};

function PortfolioCard({ post, casenewData, netData, id, indexes }) {
  const [isLoading, setIsLoading] = useState(true);
  const [type, setType] = useState([]);
  const slug1 = `wp/v2/industry-type/${id}`;
  const types = "get";
  const fetchIndustryType = async () => {
    setIsLoading(true);
    try {
      const data = await apiCall(slug1, types);      
      setType(data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching Industry:", error);
    }
  };
  useEffect(() => {
    fetchIndustryType();
  }, [id]);
  return (
    <>
      <Link
        className="porfolio-card"
        to={`/${casenewData}/${post?.slug}`}
        onClick={() => {
          localStorage.setItem("work index", indexes);
        }}
      >
        <div>
          <div className="images-card">
            {netData === "project"
              ? post && (
                <>
                  <figure>
                    {post?.proj_image && post?.proj_image.url ? (
                      <img
                        className="backgrounds img-fluid"
                        src={post?.proj_image.url}
                        alt={post?.proj_image.alt}
                      />
                    ) : (
                      <>
                        <img src={image} alt="placeholderimage" />
                      </>
                    )}
                    {post?.proj_feature_img && post?.proj_feature_img.src ? (
                      <img
                        className="logos img-fluid"
                        src={post?.proj_feature_img.src}
                        alt={post?.proj_feature_img.alt}
                      />
                    ) : (
                      <>
                        <img src={image} alt="placeholderimage" />
                      </>
                    )}
                  </figure>
                </>
              )
              : post && (
                <>
                  {isLoading ? (
                    <Imgloader />
                  ) : (
                    <figure>
                      {post?.cs_image ? (
                        <img
                          className="backgrounds img-fluid"
                          src={post?.cs_image}
                          alt="background img"
                        />
                      ) : (
                        <>
                          <img src={image} alt="placeholderimage" />
                        </>
                      )}
                      {post?.cs_feature_img && post?.cs_feature_img.src ? (
                        <img
                          className="logos img-fluid"
                          src={post?.cs_feature_img.src}
                          alt={post?.cs_feature_img.alt}
                        />
                      ) : (
                        <>
                          <img src={image} alt="placeholderimage" />
                        </>
                      )}
                    </figure>
                  )}
                </>
              )}
          </div>
          <div className="title">
            {isLoading ? (
              <Conloader />
            ) : (
              <>
                <div>
                  <h4>{post?.title}</h4>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: type.name,
                    }}
                  ></p>

                </div>
                <span className="card-btns">
                  <Link
                    to={`/${casenewData}/${post?.slug}`}
                    onClick={() => {
                      localStorage.setItem("work index", indexes);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="40"
                      viewBox="0 0 40 40"
                    >
                      <g
                        id="Group_4604"
                        data-name="Group 4604"
                        transform="translate(-1331 -5120)"
                      >
                        <circle
                          data-index={indexes}
                          id="Ellipse_66"
                          data-name="Ellipse 66"
                          cx="20"
                          cy="20"
                          r="20"
                          transform="translate(1331 5120)"
                          fill="#acbde1"
                        />
                        <path
                          id="Path_1094"
                          data-name="Path 1094"
                          d="M1122.005,1674.389l7.656,7.656-7.656,7.656"
                          transform="translate(225.187 3457.633)"
                          fill="none"
                          stroke="#234285"
                          strokeLinecap="round"
                          strokeWidth="3"
                        />
                      </g>
                    </svg>
                  </Link>
                </span>
              </>
            )}
          </div>
          <div className="card-tags">
            {post &&
              post?.technology.map((item, index) => (
                <>
                  {isLoading ? (
                    <Conloader />
                  ) : (
                    <span key={index}>{item.name}</span>
                  )}
                </>
              ))}
          </div>
        </div>
      </Link>
    </>
  );
}
export default PortfolioTwo;
