import React, { useEffect, useState } from "react";
import gsap, { TweenMax, Power0, TimelineMax } from "gsap";
import * as ScrollMagic from "scrollmagic";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
import Conloader from "../componentsheaderfooter/Conloader";

const ServicesDetailotwo = ({ inner }) => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    ScrollMagicPluginGsap(ScrollMagic, gsap); // Initialize ScrollMagic GSAP plugin
    const controller = new ScrollMagic.Controller({
      globalSceneOptions: { duration: 200, triggerHook: 0.5 },
    });

    inner?.service_inner_list.forEach((detail, index) => {
      new ScrollMagic.Scene({ triggerElement: `#green-card${index}` })
        .setClassToggle(`#green-card${index}`, "green-active")
        .addIndicators()
        .addTo(controller);
    });
    if (inner?.section_setting === true) {
      setIsLoading(false);
    }
  }, [inner]);
  return (
    <>
      {inner?.section_setting === true && (
        <div className="section-spacing graybg px-3">
          <section className="benefits-section">
            <div className="container">
              <div className="row">
                <div className="col-md-4">
                  {isLoading ? (
                    <Conloader />
                  ) : (
                    <div className="perks-content">
                      <h3>{inner?.service_inner_title}</h3>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: inner?.service_inner_description,
                        }}
                      ></p>
                    </div>
                  )}
                </div>
                <div className="col-md-1"></div>
                <div className="col-md-7 mt-md-2 mt-4">
                  <div className="position-relative">
                    {inner &&
                      inner !== null &&
                      inner.service_inner_list.length > 0 &&
                      inner?.service_inner_list.map((detail, index) => (
                        <div
                          className="perks-inner-section mb-4 dot-animation"
                          key={index}
                          id={`green-card${index}`}
                        >
                          {isLoading ? (
                            <Conloader />
                          ) : (
                            <>
                              <div
                                className="perks-text box-black"
                                id={`green-cards${index}`}
                              >
                                <p>{detail?.list_title}</p>
                                <p className="details">{detail?.list_content}</p>
                              </div>
                              <div className="d-flex dot-flex mt-md-4 mt-2 justify-content-end">
                                <div className="dot-sec"></div>
                                <div className="dot-sec1"></div>
                                <div className="dot-sec2"></div>
                              </div>
                            </>
                          )}
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </>
  );
};

export default ServicesDetailotwo;
