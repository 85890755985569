import React, { useEffect, useState } from "react";
import $ from "jquery";
import gsap, { TweenMax, Power0, TimelineMax } from "gsap";
import * as ScrollMagic from "scrollmagic";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
import Conloader from "../componentsheaderfooter/Conloader";
import Imgloader from "../componentsheaderfooter/Imgloader";

const ServicesDetailofive = ({ inner }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    ScrollMagicPluginGsap(ScrollMagic, TweenMax);

    const controller = new ScrollMagic.Controller();

    const scene = new ScrollMagic.Scene({
      triggerElement: ".servicesdetail6section",
      triggerHook: 0.7, // Adjust this value based on when you want the animation to start
      duration: "50%",
      reverse: true,
    });

    var animationTimeline = new TimelineMax();
    animationTimeline.to(
      $(".servicesdanimation5"),
      {
        x: 0, // Adjust the value as needed
        opacity: 1,
        ease: Power0.easeNone,
      },
      0
    );

    // Set the timeline as the tween for the ScrollMagic scene
    scene.setTween(animationTimeline);
    // Add the scene to the controller
    scene.addTo(controller);
    scene.setClassToggle(".servicesdetail6section", "title-colorchange");

    if (inner?.section_setting === true) {
      setIsLoading(false);
    }

    return () => {
      controller.destroy();
    };
  }, []);
  return (
    <>
      {inner?.section_setting === true && (
        <section id="design-tool" className="pb-0 servicesdetail6section">
          <div className="container">
            <div className="row mx-0 p-0 justify-content-between tools_head servicesdanimation5">
              {isLoading ? (
                <Conloader />
              ) : (
                <>
                  <div className="col-md-4 p-0">
                    <h2>{inner?.design_tools_title}</h2>
                  </div>
                  <div className="col-md-7 p-0">
                    <p
                      dangerouslySetInnerHTML={{ __html: inner?.design_tools_desc }}
                    ></p>
                  </div>
                </>
              )}
            </div >
            <div className="design-tools servicesdanimation5">
              {isLoading ? (
                <>
                  <Conloader />
                  <Imgloader />
                </>
              ) : (
                <>
                  {inner &&
                    inner !== null &&
                    inner?.design_tools__list.length > 0 &&
                    inner?.design_tools__list.map((detail, key) => (
                      <div
                        className="row m-0 justify-content-between align-items-center tools"
                        key={key}
                      >
                        <div className="col-md-4 p-0">
                          <h5>{detail?.tools_title}</h5>
                        </div>
                        <div className="col-md-7 p-0 tools_name">
                          {detail.tools_title_list.map((into, key) => (
                            <p key={key}>{into?.lists}</p>
                          ))}
                        </div>
                      </div>
                    ))}
                </>
              )}
            </div>
          </div >
        </section >
      )}
    </>
  );
};

export default ServicesDetailofive;
