import React, { useState, useEffect } from "react";
import OveServices from "../../src/img/servicesimg/our services.svg";
import { Link } from "react-router-dom";
import Form from "../Form/Form";
import Conloader from "../componentsheaderfooter/Conloader";
import Imgloader from "../componentsheaderfooter/Imgloader";
import { imageUpdate } from "../authentication";

const Servicesectionone = (props) => {
  const { services1data } = props;
  const [error, setError] = useState([]);
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const handleClose = () => {
    const formElement = document.getElementById("formCareer");
    const numericInputs = document.getElementsByClassName("numericInput");

    for (let i = 0; i < numericInputs.length; i++) {
      numericInputs[i].addEventListener("keypress", checkInputValue);
    }
    if (formElement) {
      formElement.reset();
      setError({});
      setIsCaptchaVerified(false);
    }
  };
  const checkInputValue = (e) => {
    const input = document.querySelector(".form-control.numericInput");

    // Check if input exists and the value length is already 10
    if (input && input.value.length >= 10 && e.keyCode !== 8 /* Backspace */) {
      e.preventDefault();
      return;
    }
    const key = String.fromCharCode(e.keyCode);
    const isNumber = /[0-9]/.test(key);

    if (!isNumber) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (services1data?.section_settings === true) {
      setIsLoading(false);
    }
  })

  console.log("services img", services1data);

  return (
    <>
      {services1data?.section_settings === true ? (
        <section className="pt-5 pb-md-5 pb-3">
          <div className="container pt-5 pb-5">
            <div className="our-service">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-md-6">
                    {isLoading ? (
                      <Conloader />
                    ) : (
                      <div className="service-content">
                        <h2>{services1data?.banner_title}</h2>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: services1data?.banner_content,
                          }}
                        ></p>
                        <Link
                          className="btn button-com link-animations"
                          data-bs-toggle="modal"
                          data-bs-target="#FormModal"
                          onClick={handleClose}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="11.899"
                            height="19.555"
                            viewBox="0 0 11.899 19.555"
                          >
                            <path
                              id="Path_1094"
                              data-name="Path 1094"
                              d="M1122.005,1674.389l7.656,7.656-7.656,7.656"
                              transform="translate(-1119.884 -1672.268)"
                              fill="none"
                              stroke="#fff"
                            />
                          </svg>
                          {services1data?.banner_button?.title}
                        </Link>
                      </div>
                    )}
                  </div>
                  <div className="col-md-6">
                    {isLoading ? (
                      <Imgloader />
                    ) : (
                      <div className="service-img">
                        <img src={services1data.banner_image.url} className="img-fluid doodles-image" alt="" />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="modal fade"
            id="FormModal"
            tabindex="-1"
            aria-labelledby="FormModal"
            aria-hidden="true"
          >
            <Form />
          </div>
        </section>
      ) : (
        <div></div>
      )}
    </>
  );
};

imageUpdate();

export default Servicesectionone;
