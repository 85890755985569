import React, { useEffect, useRef, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import HireInnerone from "./hireinnerpage/HireInnerone";
import HireInnertwo from "./hireinnerpage/HireInnertwo";
import HireInnerthree from "./hireinnerpage/HireInnerthree";
import HireInnerfour from "./hireinnerpage/HireInnerfour";
import HireInnerfive from "./hireinnerpage/HireInnerfive";
import HireInnersix from "./hireinnerpage/HireInnersix";
import HireInnerseven from "./hireinnerpage/HireInnerseven";
import HireInnereight from "./hireinnerpage/HireInnereight";
import HireInnernine from "./hireinnerpage/HireInnernine";
import HireInnerten from "./hireinnerpage/HireInnerten";
import HireInnereleven from "./hireinnerpage/HireInnereleven";
import HireInnertwelve from "./hireinnerpage/HireInnertwelve";
import HireInnerthirteen from "./hireinnerpage/HireInnerthirteen";
import { apiCall, imageUpdate } from "./authentication";
import CommonCta from "./CommonCta";
import Loader from "./Loader";
import "../src/HireInner.css";
import { useParams } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import Form from "./Form/Form";
import Conloader from "./componentsheaderfooter/Conloader";
import Imgloader from "./componentsheaderfooter/Imgloader";

const HireInner = () => {
  const [error, setError] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const prevurlRef = useRef();
  const { slug } = useParams();
  const [users5, setUsers5] = useState([]);
  const [hireinnersection1, setHireinnersection1] = useState([]);
  const [hireinnersection2, setHireinnersection2] = useState([]);
  const [hireinnersection3, setHireinnersection3] = useState([]);
  const [hireinnersection4, setHireinnersection4] = useState([]);
  const [hireinnersection5, setHireinnersection5] = useState([]);
  const [hireinnersection6, setHireinnersection6] = useState([]);
  const [hireinnersection7, setHireinnersection7] = useState([]);
  const [hireinnersection8, setHireinnersection8] = useState([]);
  const [hireinnersection9, setHireinnersection9] = useState([]);
  const [hireinnersection10, setHireinnersection10] = useState([]);
  const [hireinnersection11, setHireinnersection11] = useState([]);
  const [hireinnersection12, setHireinnersection12] = useState([]);
  const [hireinnersection13, setHireinnersection13] = useState([]);
  const [yoastData, setYoastData] = useState({});
  const [hiresection9, setHiresection9] = useState([]);
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);

  let finalreacturl;

  const handleClose = () => {
    setCurrentStep(0);
    const formElement = document.getElementById("formCareer");
    const numericInputs = document.getElementsByClassName("numericInput");

    for (let i = 0; i < numericInputs.length; i++) {
      numericInputs[i].addEventListener("keypress", checkInputValue);
    }
    if (formElement) {
      formElement.reset();
      setError({});
      setIsCaptchaVerified(false);
    }
  };
  const checkInputValue = (e) => {
    const input = document.querySelector(".form-control.numericInput");

    // Check if input exists and the value length is already 10
    if (input && input.value.length >= 10 && e.keyCode !== 8 /* Backspace */) {
      e.preventDefault();
      return;
    }
    // Get the entered key
    const key = String.fromCharCode(e.keyCode);

    // Check if the entered key is a number
    const isNumber = /[0-9]/.test(key);

    // Prevent the input if the entered key is not a number
    if (!isNumber) {
      e.preventDefault();
    }
  };

  const slug1 = `wp/v2/pages?slug=${slug}&acf_format=standard`;
  const type = "get";

  useEffect(() => {
    window.scrollTo(0, 0);
    // Step 1: Fetch data from the initial REST API
    setIsLoading(true);
    const fetchData = async () => {
      try {
        const data = await apiCall(slug1, type);
        setUsers5(data);
        let splitString = data?.[0]?.link.split("/");
        const currentPrevurl = splitString[splitString.length - 3];

        // Update the ref with the new value
        prevurlRef.current = currentPrevurl;
        setYoastData(data[0].yoast_head_json);
        data[0].acf.common_section.forEach((element) => {
          if (element.acf_fc_layout === "banner_section") {
            setHireinnersection1(element);
          }
          if (element.acf_fc_layout === "cta_2_section") {
            setHireinnersection3(element);
          }
          if (element.acf_fc_layout === "our_partner_section") {
            setHireinnersection13(element);
          }

          if (element.acf_fc_layout === "faq_section") {
            setHireinnersection10(element);
          }
          if (element.acf_fc_layout === "portfolio_project_section") {
            setHireinnersection12(element);
          }
          if (element.acf_fc_layout === "cta_section") {
            setHiresection9(element);
          }
        });

        data[0].acf.hire_inner_section.forEach((element) => {
          if (element.acf_fc_layout === "top_talents_section") {
            setHireinnersection2(element);
          }

          if (element.acf_fc_layout === "certified_developer_section") {
            setHireinnersection4(element);
          }

          if (element.acf_fc_layout === "expertise_section") {
            setHireinnersection5(element);
          }

          if (element.acf_fc_layout === "skills_section") {
            setHireinnersection6(element);
          }

          if (element.acf_fc_layout === "techstack_section") {
            setHireinnersection7(element);
          }

          if (element.acf_fc_layout === "step_to_hire") {
            setHireinnersection8(element);
          }

          if (element.acf_fc_layout === "hire_expert_section") {
            setHireinnersection9(element);
          }

          if (element.acf_fc_layout === "accredited_section") {
            setHireinnersection11(element);
          }
        });

        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [slug]); // Empty dependency array ensures the effect runs once

  if (yoastData.og_url !== undefined) {
    const wpurl = yoastData.og_url.toString()
    const newurl = wpurl.replace('wpatlas', 'www');
    const newurl1 = newurl.split('/');
    finalreacturl = `${newurl1[0] + '//' + newurl1[2] + '/hire/' + newurl1[4]}`
  }

  setTimeout(() => {
    imageUpdate();
  }, 1000);

  return (
    <>
      <div>
        {isLoading ? (
          <div className="container">
            <div className="row height-animation align-items-center">
              <div className="col-md-6">
                <Conloader />
              </div>
              <div className="col-md-6">
                <Imgloader />
              </div>
            </div>
          </div>
        ) : (
          <div>
            <HelmetProvider>
              <Helmet>
                <title>{yoastData.title}</title>
                <meta name="description" content={yoastData.description} />
                <link rel="canonical" hreflang="en" href={finalreacturl} />
                <meta
                  property="og:locale"
                  content="en_US"
                  className="yoast-seo-meta-tag"
                />
                <meta
                  property="og:type"
                  content="article"
                  className="yoast-seo-meta-tag"
                />
                <meta
                  property="og:title"
                  content={yoastData.og_title}
                  className="yoast-seo-meta-tag"
                />
                <meta
                  property="og:description"
                  content={yoastData.og_description}
                  className="yoast-seo-meta-tag"
                />
                <meta
                  property="og:url"
                  content={finalreacturl}
                  className="yoast-seo-meta-tag"
                />
                <meta
                  property="og:site_name"
                  content={yoastData.og_site_name}
                  className="yoast-seo-meta-tag"
                />
                <meta
                  property="article:modified_time"
                  content={yoastData.article_modified_time}
                  className="yoast-seo-meta-tag"
                />
                <meta
                  property="og:image"
                  content={yoastData.url}
                  className="yoast-seo-meta-tag"
                />
                {/* <meta property="og:image:width" content={yoastData.og_image.width} className="yoast-seo-meta-tag" />
                    <meta property="og:image:height" content={yoastData.og_image.height} className="yoast-seo-meta-tag" /> */}
                {/* <meta property="og:image:type" content={yoastData.og_image.type} className="yoast-seo-meta-tag" />
                    <meta name="twitter:card" content={yoastData.twitter_card} className="yoast-seo-meta-tag" /> */}
                <meta
                  name="twitter:label1"
                  content="Est. reading time"
                  className="yoast-seo-meta-tag"
                />
                <meta
                  name="twitter:data1"
                  content="1 minute"
                  className="yoast-seo-meta-tag"
                />
              </Helmet>
            </HelmetProvider>
            <HireInnerone
              handleClose={handleClose}
              prevurl={prevurlRef}
              hire1inner={hireinnersection1}
            />
            <HireInnertwo hire2inner={hireinnersection11} />
            <HireInnerthree hire3inner={hireinnersection2} />
            <HireInnerfour hire4inner={hireinnersection3} />
            <HireInnerfive
              handleClose={handleClose}
              hire5inner={hireinnersection4}
            />
            <HireInnersix hire6inner={hireinnersection5} />
            <HireInnerseven
              handleClose={handleClose}
              hire7inner={hireinnersection6}
            />
            <HireInnereight hire8inner={hireinnersection7} />
            <HireInnernine hire9inner={hireinnersection8} />
            <HireInnerten
              handleClose={handleClose}
              hire10inner={hireinnersection9}
            />
            <HireInnereleven hire11innerdata={hireinnersection10} />
            <HireInnertwelve
              hire12inner="hire-inner"
              hire12innerdata={hireinnersection12}
            />
            <HireInnerthirteen hire13inner={hireinnersection13} />
            <CommonCta />
            <div
              class="modal fade"
              id="FormModal"
              tabindex="-1"
              aria-labelledby="FormModal"
              aria-hidden="true"
            >
              <Form />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default HireInner;
