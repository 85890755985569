import React, { useEffect, useState } from "react";
import Button from "../button/button";
import { Link } from "react-router-dom";
import Conloader from "../componentsheaderfooter/Conloader";
import Imgloader from "../componentsheaderfooter/Imgloader";

const Hiresectionone = (props) => {
  const { hire1data } = props;
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (hire1data.section_settings === true) {
      setIsLoading(false);
    }
  }, [hire1data.section_settings]);

  return (
    <>
      {hire1data.section_settings === true ? (
        <section className="banner-inner-section pt-5 pb-md-5 pb-3">
          <div className="container pt-5 pb-5">
            <div className="row align-items-center">
              <div className="inner-content pt-5 pt-md-5 col-md-6">
                {isLoading ? (
                  <Conloader />
                ) : (
                  <>

                    <h5>{hire1data.banner_title}</h5>
                    <h1>{hire1data.banner_sub_title}</h1>
                    <p
                      dangerouslySetInnerHTML={{ __html: hire1data.banner_content }}
                    ></p>
                    <Link
                      to={hire1data?.banner_button?.url}
                      className="btn button-com link-animations"
                      data-bs-toggle="modal"
                      data-bs-target="#FormModal"
                      onClick={props.handleClose}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="11.899"
                        height="19.555"
                        viewBox="0 0 11.899 19.555"
                      >
                        <path
                          id="Path_1094"
                          data-name="Path 1094"
                          d="M1122.005,1674.389l7.656,7.656-7.656,7.656"
                          transform="translate(-1119.884 -1672.268)"
                          fill="none"
                          stroke="#fff"
                        />
                      </svg>
                      {hire1data?.banner_button?.title}
                    </Link>
                  </>
                )}
              </div>
              <div className="col-md-6 pt-md-5 text-md-end mt-md-0 mt-4">
                {isLoading ? (
                  <Imgloader />
                ) : (
                  <img
                    className="doodles-image"
                    src={hire1data.banner_image?.url}
                    alt={hire1data?.banner_button?.title}
                  />
                )}
              </div>
            </div>
          </div>
        </section>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default Hiresectionone;
