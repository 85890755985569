import React, { useEffect, useState } from "react";
import $ from "jquery";
import gsap, { TweenMax, Power0, TimelineMax } from "gsap";
import * as ScrollMagic from "scrollmagic";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
import Conloader from "../componentsheaderfooter/Conloader";

function HireInnerthree(props) {
  const { hire3inner } = props;
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {

    ScrollMagicPluginGsap(ScrollMagic, TweenMax);

    const controller = new ScrollMagic.Controller();

    const scene = new ScrollMagic.Scene({
      triggerElement: '.top-talents-hire-section',
      triggerHook: 0.8, // Adjust this value based on when you want the animation to start      
      duration: "50%",
      reverse: true,
    })


    var animationTimeline = new TimelineMax();
    animationTimeline
      .to(
        $(".hireinneranimation3"),
        {
          x: 0, // Adjust the value as needed
          opacity: 1,
          ease: Power0.easeNone,
        },
        0
      )


    // Set the timeline as the tween for the ScrollMagic scene
    scene.setTween(animationTimeline);
    // Add the scene to the controller
    scene.addTo(controller);


    return () => {
      controller.destroy();
    };
  }, []);

  useEffect(() => {
    if (hire3inner.section_setting == true) {
      setIsLoading(false);
    }
  }, [hire3inner.section_setting])
  return (
    <>
      {hire3inner.section_setting && hire3inner.section_setting == true ? (
        <section className="top-talents-hire-section section-spacing">
          <div className="container">
            <div className="title-box d-flex justify-content-between hireinneranimation3">
              {isLoading ? (
                <Conloader />
              ) : (
                <>
                  <h2 className="pe-5">{hire3inner.top_taltent_title}</h2>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: hire3inner.top_talent_description,
                    }}
                  ></p>
                </>
              )}
            </div>
            <div className="d-flex all-developer hireinneranimation3">
              {hire3inner.top_talent_list &&
                hire3inner.top_talent_list.map((res, index) => {
                  return (
                    <>
                      {isLoading ? (
                        <Conloader />
                      ) : (
                        <div className="box">
                          <h5>{res.list_title}</h5>
                          <ul>
                            {res.list_desc.map((Response, key) => {
                              return (
                                <li
                                  dangerouslySetInnerHTML={{
                                    __html: Response.list,
                                  }}
                                ></li>
                              );
                            })}
                          </ul>
                        </div>
                      )}
                    </>
                  );
                })}
            </div>
          </div>
        </section>
      ) : (
        <div></div>
      )}
    </>
  );
}

export default HireInnerthree;
