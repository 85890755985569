import React, { useState, useEffect } from "react";
import InfographicsDetailone from "./infographicsdetailpage/InfographicsDetailOne";
import InfographicsDetailtwo from "./infographicsdetailpage/InfographicsDetailTwo";
import InfographicsDetailthree from "./infographicsdetailpage/InfographicsDetailThree";

import CommonCta from "./CommonCta";
import Loader from "./Loader";
import "../src/BlogDetail.css";
import { useParams } from "react-router-dom";
import { apiCall } from "./authentication";

const InfographicsDetail = () => {
  const { slug } = useParams();
  const [blogPost, setBlogPost] = useState([]);
  const [blogdata, setBlogData] = useState([]);
  const [load, isLoad] = useState(true);
  const slug1 = `wp/v2/infographics/?slug=${slug}&_embed`;
  const slug2 = `relatedarticles/articles?_embed`;
  const type = "get";
  const fetchPost = async () => {
    isLoad(true);
    try {
      const data = await apiCall(slug1, type);
      setBlogPost(data);
      isLoad(false);
    } catch (error) {
      console.error("Error fetching Blog:", error);
    }
  };

  const fetchBlog = async () => {
    try {
      const data = await apiCall(slug2, type);
      setBlogData(data);
    } catch (error) {
      console.error("Error fetching Blog:", error);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    // Assuming 'id' is defined elsewhere in your component
    fetchPost();
    fetchBlog();
  }, [slug]);
  return (
    <>
      {load ? (
        <></>
      ) : (
        <>
          <InfographicsDetailone data={blogPost} />
          <InfographicsDetailtwo data={blogPost} />
          <InfographicsDetailthree article={blogdata} />
          <CommonCta />
        </>
      )}
    </>
  );
};

export default InfographicsDetail;
