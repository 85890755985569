import React from "react";
import Slider from "react-slick";
import Button from "../button/button";
import OveServices from "../../src/img/servicesimg/our services.svg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

const SliderServices2 = ({ settings, SliderServices1, sliderData }) => {
  return (
    <>
      {sliderData?.section_setting === true ? (
        <Slider className="technologies_content text-white" {...settings}>
          {sliderData.services_list_details &&
            sliderData.services_list_details.map((res, index) => {
              return (
                <>
                  <div className="slider_slid">
                    <div className="row justify-content-around">
                      <div className="col-lg-4 col-md-12 col-12">
                        <div className="text-start">
                          <p
                            className="slide_head"
                            dangerouslySetInnerHTML={{ __html: res.tab_title }}
                          ></p>
                          <p
                            className="slide_body"
                            dangerouslySetInnerHTML={{
                              __html: res.tab_content,
                            }}
                          ></p>
                          {/* <Button name={res.tab_button.title} url={res.tab_button.url} target={res.tab_button.target} /> */}
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-12 slide_img text-center d-flex justify-content-center">
                        <figure className="col-md-12 col-8 p-0">
                          <img
                            className="doodles-image"
                            src={
                              res?.tab_image?.url
                                ? res?.tab_image?.url
                                : res?.tab_image
                            }
                            alt=""
                          />
                        </figure>
                      </div>
                      <div className="col-lg-3 col-md-5 col-12 slide_li">
                        <ul className="text-start">
                          {res?.tab_service_listing?.map((resData, key) => {
                            return (
                              <>
                                <li>
                                  <Link
                                    to={`/our-services/${resData.post_name}`}
                                  >
                                    {resData.post_title}
                                  </Link>
                                </li>
                              </>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
        </Slider>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default SliderServices2;
