import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import PortfolioTwo from "./PortfolioTwo";
import { apiCall } from "../authentication";
import Conloader from "../componentsheaderfooter/Conloader";

const PortfolioOne = ({ portfolio1 }) => {
  const [caseStudy, setCaseStudy] = useState(false);
  const [caseStudyFilter, setCaseStudyFilter] = useState();
  const [technology, setTechnology] = useState([]);
  const [technologyType, setTechnologyType] = useState();
  const [industry, setIndustry] = useState([]);
  const [industryType, setIndustryType] = useState();
  const [isloading, setIsLoading] = useState(true);

  let pathName = window.location.pathname;
  const handleCaseStudyClick = () => {
    setCaseStudy(true);
  };

  const handlePortfolioClick = () => {
    setCaseStudy(false);
  };

  const slug1 = `wp/v2/${!caseStudyFilter ? "technology" : "technologies"
    }/?per_page=50`;
  const slug2 = `wp/v2/industry-type?per_page=100`;
  const type = "get";
  const fetchTechnology = async () => {
    setIsLoading(true);
    try {
      const data = await apiCall(slug1, type);
      const filteredTechnology = data.filter((item) => item.count !== 0);
      setIsLoading(false);
      setTechnology(filteredTechnology);
    } catch (error) {
      console.error("Error fetching Technology:", error);
    }
  };

  const fetchIndustry = async () => {
    setIsLoading(true);
    try {
      const data = await apiCall(slug2, type);
      const filteredIndustry = data.filter((item) => item.count !== 0);
      setIsLoading(false);
      setIndustry(filteredIndustry);
    } catch (error) {
      console.error("Error fetching Industry:", error);
    }
  };

  const handleTechnologyChange = (event) => {
    const selectedTechnology = event.target.value;
    setTechnologyType(selectedTechnology); // Call the filterData function with the selected technology
  };

  const handleIndustryChange = (event) => {
    const selectedIndustry = event.target.value;
    setIndustryType(selectedIndustry); // Call the filterData function with the selected technology
  };

  useEffect(() => {
    if (window.location.pathname === "/case-studies") {
      setCaseStudy(true);
    } else {
      setCaseStudy(false);
    }
    fetchTechnology();
    fetchIndustry();
  }, [pathName]);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchTechnology();
  }, [caseStudyFilter, caseStudy]);

  const handleLinkClick = () => {
    setTechnologyType("Technology");
    setIndustryType("Industry");
    // Add any additional logic you want to execute on link click
  };

  return (
    <>
      <div className="filter_barmain">
        <div className="pt-5">
          {portfolio1 !== null && portfolio1 && (
            <div className="container pt-5 pb-5">
              <div className="row gx-md-5 pt-5 pb-md-5 align-items-center">
                <div className="col-md-8 col-12">
                  {isloading ? (
                    <Conloader />
                  ) : (
                    <>
                      <p className="list-link">{portfolio1[0]?.title?.rendered}</p>
                      <h2
                        className="fw-bold page-title work"
                        dangerouslySetInnerHTML={{
                          __html: portfolio1[0]?.content?.rendered,
                        }}
                      ></h2>
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="py-5 graybg filter_bar">
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="portfolio-tab">
                <div onClick={handleCaseStudyClick}>
                  <p
                    role="button"
                    className={caseStudy ? "active" : ""}
                    onClick={() => setCaseStudyFilter(true)}
                  >
                    Case Studies
                  </p>
                </div>
                <div onClick={handlePortfolioClick}>
                  <p
                    role="button"
                    className={!caseStudy ? "active" : ""}
                    onClick={() => setCaseStudyFilter(false)}
                  >
                    Portfolio
                  </p>
                </div>
              </div>
              <div className="portfolio-selects">
                <div className="row main-select">
                  <div>
                    <select
                      className="form-select select-portfolio"
                      aria-label="Default select example"
                      onChange={handleTechnologyChange}
                      value={technologyType} // Assuming 'technologyType' is the state holding the selected technology ID
                    >
                      <option value="Technology">Technology</option>
                      {technology &&
                        technology !== null &&
                        technology.length > 0 &&
                        technology.map((tech, index) => {
                          return (
                            <option key={index} value={tech.id}>
                              {tech.name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                  <div>
                    <select
                      className="form-select select-portfolio"
                      aria-label="Default select example"
                      onChange={handleIndustryChange}
                      value={industryType} // Assuming 'technologyType' is the state holding the selected technology ID
                    >
                      <option value="Industry">Industry</option>
                      {industry &&
                        industry !== null &&
                        industry.length > 0 &&
                        industry.map((tech, index) => {
                          return (
                            <option
                              key={index}
                              value={tech.id}
                              dangerouslySetInnerHTML={{
                                __html: tech.name,
                              }}
                            />
                          );
                        })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="portfolio-button">
                <Link onClick={() => handleLinkClick()}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16.91"
                    height="17.996"
                    viewBox="0 0 16.91 17.996"
                  >
                    <g id="refresh" transform="translate(-3.547 -3)">
                      <path
                        id="Path_4595"
                        data-name="Path 4595"
                        d="M19.6,12.635a.99.99,0,0,0-1.135.844,6.4,6.4,0,0,1-1.83,3.618A6.5,6.5,0,0,1,7.446,7.905a6.414,6.414,0,0,1,3.5-1.8A6.187,6.187,0,0,1,12.8,6.05a6.414,6.414,0,0,1,2.466.828l-1.3.222a1,1,0,0,0,.168,1.986,1.01,1.01,0,0,0,.17-.014l3.49-.6a1,1,0,0,0,.816-1.155l-.6-3.49a1,1,0,1,0-1.971.338l.157.918a8.423,8.423,0,0,0-3.17-1.025,8.1,8.1,0,0,0-2.428.075A8.379,8.379,0,0,0,6.032,6.491a8.5,8.5,0,0,0,12.02,12.019,8.391,8.391,0,0,0,2.395-4.741,1,1,0,0,0-.844-1.135Z"
                        fill="#212529"
                      />
                    </g>
                  </svg>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <PortfolioTwo
          caseStudydata={caseStudy}
          technology={technologyType}
          industry={industryType}
        />
      </div>
    </>
  );
};

export default PortfolioOne;
