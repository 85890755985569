/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import playicon from "../img/homeimg/play.svg";
import Button from "../button/button";
import $ from "jquery";
import gsap, { TweenMax, Power0, TimelineMax } from "gsap";
import * as ScrollMagic from "scrollmagic";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
import Slider from "react-slick";
import { apiCall } from "../authentication";
import image1 from "../img/placeholder-1-1.webp";
import Conloader from "../componentsheaderfooter/Conloader";
import Imgloader from "../componentsheaderfooter/Imgloader";

const Homesectionnine = (props) => {
  const { section9data } = props;
  const { section91data } = props;
  const [articlesdata, setArticlesdata] = useState([]);
  const [employeeReviewImage, setEmployeeReviewImage] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const reviewsslider = {
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    dots: true,
    arrows: false,
    autoplay: true,
    fade: true,
    speed: 500,
    cssEase: "ease-in-out",
    adaptiveHeight: true,
  };

  let selectedIds = [];
  let stringIds = "";
  let reviewselectedIds = [];
  let reviewstringIds = "";

  if (section9data && Array.isArray(section9data.select_articles)) {
    selectedIds = section9data?.select_articles?.map(
      (section9data) => section9data.ID
    );
    stringIds = selectedIds.join(",");
  }

  if (
    section91data &&
    Array.isArray(section91data?.career_details?.[0].select_employee_reviews)
  ) {
    reviewselectedIds =
      section91data?.career_details?.[0].select_employee_reviews;
    reviewstringIds = reviewselectedIds.toString();
  }

  const slug1 = `wp/v2/posts?per_page=3&page=1&_embed`;
  const slug2 = `homeempreview/empreview/${reviewstringIds}`;

  const type = "get";

  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line no-lone-blocks
    {
      const fetchData = async () => {
        try {
          const dataArray = await apiCall(slug1, type);
          setArticlesdata(dataArray);
        } catch (error) {
          // Handle API error
          console.error("Error fetching data:", error);
        }
      };

      const fetchReview = async () => {
        try {
          const dataArray = await apiCall(slug2, type);
          setEmployeeReviewImage(dataArray);
        } catch (error) {
          // Handle API error
          console.error("Error fetching data:", error);
        }
      };

      fetchData();
      fetchReview();
      ScrollMagicPluginGsap(ScrollMagic, TweenMax);

      const controller = new ScrollMagic.Controller();

      const scene = new ScrollMagic.Scene({
        triggerElement: ".articalanimationstart",
        triggerHook: 0.8, // Adjust this value based on when you want the animation to start
        duration: "50%",
        reverse: true,
      });
      var animationTimeline = new TimelineMax();
      animationTimeline.to(
        $(".commonanimation4"),
        {
          x: 0, // Adjust the value as needed
          opacity: 1,
          ease: Power0.easeNone,
        },
        0
      );

      // Set the timeline as the tween for the ScrollMagic scene
      scene.setTween(animationTimeline);
      // Add the scene to the controller
      scene.addTo(controller);

      return () => {
        controller.destroy();
      };
    }
  }, []);

  useEffect(() => {
    if (section91data.section_setting === true) {
      setIsLoading(false);
    }
  }, [section91data]);

  return (
    <>
      {section91data.section_setting === true ? (
        <div className="section-spacing">
          <div className="container pb-lg-5">
            <div className="row">
              <div className="col-12">
                {isLoading ? (
                  <Conloader />
                ) : (
                  <>
                    <h2 className="page-title fw-bold">
                      {section91data.main_title}
                    </h2>
                    <div className="sub-title fw-bold">
                      {section91data.sub_title}
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="row mt-4 mt-md-5">
              <div className="col-12 position-relative">
                {isLoading ? (
                  <Imgloader />
                ) : (
                  <>
                    <div className="careerblock position-relative">
                      <div className="careerblockimg position-relative">
                        <img
                          src={section91data.life_atlas_image.url}
                          className="img-fluid w-100 careerblockimg"
                          alt={section91data.life_atlas_image.filename}
                        />
                        <iframe
                          class="embed-responsive-item w-100 h-100 atlas_life_videos"
                          src={section91data.video_link}
                          allow="autoplay"
                          allowfullscreen
                        ></iframe>
                      </div>
                      <div className="careerblockcontent">
                        <div>
                          <Link
                            to="#"
                            data-bs-toggle="modal"
                            data-bs-target="#homeModal"
                          >
                            <img src={playicon} className="playimg" alt="Play" />
                          </Link>
                        </div>

                        <div className="titlecareerblock">
                          <h3 className="page-title text-white fw-bold mb-3">
                            {section91data.life_at_atlas_title}
                          </h3>
                          <Link
                            to={section91data.apply_button_link.url}
                            className="btn btn-outline-primary btn-white"
                            target={section91data.apply_button_link.target}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="11.899"
                              height="19.555"
                              viewBox="0 0 11.899 19.555"
                            >
                              <path
                                id="Path_1094"
                                data-name="Path 1094"
                                d="M1122.005,1674.389l7.656,7.656-7.656,7.656"
                                transform="translate(-1119.884 -1672.268)"
                                fill="none"
                                stroke="#234285"
                              />
                            </svg>
                            {section91data.apply_button_link.title}
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="lifeatatlas dot-animation">
                      <Slider id="protfolioslider" {...reviewsslider}>
                        {employeeReviewImage &&
                          employeeReviewImage.map((res, index) => {
                            return (
                              <div className="d-flex align-items-center">
                                <div className="shaluimg">
                                  <img
                                    src={res.featured_image?.src}
                                    className="img-fluid"
                                    alt={res.featured_image?.alt}
                                  />
                                </div>
                                <div className="shalucontent">
                                  <div className="sub-title fw-bold">
                                    WHAT OUR PEOPLE SAY
                                  </div>
                                  <p
                                    className="mb-2"
                                    dangerouslySetInnerHTML={{
                                      __html: res?.what_our_people_say,
                                    }}
                                  ></p>
                                  <div className="f16 d-flex align-items-center justify-content-between">
                                    <i>-{res.title}.</i>
                                    <div className="d-flex dot-flex-testi">
                                      <div className="dot-testi light-green-testi"></div>
                                      <div className="dot-testi green-testi"></div>
                                      <div className="dot-testi blue-testi"></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </Slider>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div
            className="modal fade"
            id="homeModal"
            tabIndex="-1"
            aria-labelledby="homeModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
              <div className="modal-content">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
                <div className="modal-body">
                  <div className="embed-responsive embed-responsive-16by9">
                    <iframe
                      className="embed-responsive-item w-100"
                      src={`${section91data?.video_link}controls=0autoplay=1mute=1rel=0?version=3&autoplay=1&controls=0&&showinfo=0&loop=1`}
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}

      {section9data?.section_setting === true ? (
        <div className="section-spacing">
          <div className="articalanimationstart"></div>
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-6 mb-md-0 mb-2">
                <h2 className="page-title fw-bold commonanimation4">
                  {section9data.article_main_title}
                </h2>
              </div>
              <Button
                target={section9data.article_button_name.target}
                link={section9data.article_button_name.url}
                classadd="col-12 col-md-6 text-md-end commonanimation4"
                name={section9data.article_button_name.title}
              // title={section9data.article_button_name.title}
              />
              <div className="row gx-md-5 mt-4 mx-0 p-0 latest_articles commonanimation4">
                {articlesdata &&
                  articlesdata.length > 0 &&
                  articlesdata.map((key, index) => {
                    const dateString = key.date;
                    const dateObject = new Date(dateString);
                    const day = dateObject.getDate();
                    const monthAbbrev = dateObject.toLocaleString("en-US", {
                      month: "short",
                    });
                    const year = dateObject.getFullYear();
                    const formattedDate = `${day} ${monthAbbrev}, ${year}`;
                    return (
                      <>
                        <div
                          key={index}
                          className="col-md-4 col-12 mb-md-5 mb-4 latest_articles_card dot-animation"
                        >
                          <Link to={`/blog/${key.slug}`}>
                            <div className="text-decoration-none text-dark">
                              <div className="blog-img">
                                {key?._embedded &&
                                  key?._embedded["wp:featuredmedia"] &&
                                  key?._embedded["wp:featuredmedia"][0].source_url ? (

                                  <>
                                    {isLoading ? (
                                      <Imgloader />
                                    ) : (
                                      <img
                                        src={key?._embedded["wp:featuredmedia"][0].source_url}
                                        alt={key._embedded["wp:featuredmedia"][0].title.rendered}
                                      />
                                    )}
                                  </>
                                ) : (
                                  <img src={image1} alt="placeholderimage" />
                                )}
                              </div>
                              {isLoading ? (
                                <Conloader />
                              ) : (
                                <>
                                  <div className="blog-category d-flex justify-content-between mt-md-4 mt-2">
                                    <div className="text-blue fw-bold f14">
                                      {/* {key.title.rendered} */}
                                      {/* {key?.cat_name} */}
                                    </div>
                                    <div className="d-flex dot-flex-testi jujustify-content-md-start justify-content-end">
                                      <div className="dot-testi testidot1  light-green-testi"></div>
                                      <div className="dot-testi testidot2 green-testi"></div>
                                      <div className="dot-testi testidot3 blue-testi"></div>
                                    </div>
                                  </div>
                                  <div className="artical-card-title">
                                    <h3>{key.title.rendered}</h3>
                                    <div className="blogdate f14 mt-md-3 mt-1">
                                      {formattedDate}
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          </Link>
                        </div>
                      </>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Homesectionnine;
