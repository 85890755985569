import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import gsap, { TweenMax, Power0, TimelineMax } from "gsap";
import * as ScrollMagic from "scrollmagic";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
import Imgloader from "../componentsheaderfooter/Imgloader";
import Conloader from "../componentsheaderfooter/Conloader";

const Aboutsectionsix = (props) => {
  const { about6data } = props;
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    ScrollMagicPluginGsap(ScrollMagic, TweenMax);

    const controller = new ScrollMagic.Controller();

    const scene = new ScrollMagic.Scene({
      triggerElement: ".aboutawardlogo",
      triggerHook: 0.8, // Adjust this value based on when you want the animation to start
      duration: "50%",
      reverse: true,
    });

    var animationTimeline = new TimelineMax();
    animationTimeline.to(
      $(".commonanimation3"),
      {
        x: 0, // Adjust the value as needed
        opacity: 1,
        ease: Power0.easeNone,
      },
      0
    );

    // Set the timeline as the tween for the ScrollMagic scene
    scene.setTween(animationTimeline);
    // Add the scene to the controller
    scene.addTo(controller);

    if (about6data.section_settings === true) {
      setIsLoading(false);
    }

    return () => {
      controller.destroy();
    };
  }, []);
  return (
    <>
      {about6data.section_settings == true ? (
        <div className="section-spacing px-3 pt-0 aboutawardlogo">
          <div className="container aboutsix">            
            <div className="row align-items-stretch justify-content-start commonanimation3">
              <div className="col-12">
                  {isLoading ? (
                  <Conloader />
                ) : (
                  <h2 className="page-title fw-bold mb-md-5 mb-3">
                    {about6data?.award_title}
                  </h2>
                )}
              </div>
              {about6data.awards_details &&
                about6data.awards_details.map((res, index) => {
                  return (
                    <div className="col-xl-2 p-xl-3 p-2 col-md-2 col-sm-3 col-4 awards_card">
                      {isLoading ? (
                        <Imgloader />
                      ) : (
                        <Aboutsiximg
                          img={res.award_logo.url}
                          link={res.award_logo.link}
                        />
                      )}
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
};

function Aboutsiximg(props) {
  return (
    <figure>
      <img src={props.img} alt="" />
    </figure>
  );
}

export default Aboutsectionsix;
