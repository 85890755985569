import React from 'react'
import { Link } from 'react-router-dom';
import '../button/button.css'

function Button(props) {
    return (
        <div className={props.classadd}>
            <Link to={props.link} className='btn button-com link-animations' id={props.class} target={props.target} title={props.title}>
                <svg xmlns="http://www.w3.org/2000/svg" width="11.899" height="19.555" viewBox="0 0 11.899 19.555">
                    <path id="Path_1094" data-name="Path 1094" d="M1122.005,1674.389l7.656,7.656-7.656,7.656" transform="translate(-1119.884 -1672.268)" fill="none" stroke="#fff" />
                </svg>
                {props.name}
            </Link>
        </div>
    )
}

export default Button;