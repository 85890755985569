import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import image from "../img/placeholder-1-1.webp";
import { apiCall } from "../authentication";
import { HelmetProvider } from "react-helmet-async";
import { Helmet } from "react-helmet";
import Conloader from "../componentsheaderfooter/Conloader";
import Imgloader from "../componentsheaderfooter/Imgloader";

const ArticleListone = () => {
  const [blog, setBlog] = useState([]);
  const [innerArticle, setInnerArticle] = useState([]);
  const [category, setCategory] = useState(null);
  const [categoryfilter, setCategoryfilter] = useState(0);
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const [yoastData, setYoastData] = useState({});

  let finalreacturl;
  const slug1 = `wp/v2/article-type?per_page=100`;
  const slug2 = "wp/v2/pages?slug=articles";
  const type = "get";

  const fetchArticle = async () => {
    setLoading(true);
    try {
      const slug3 = `wp/v2/articles?per_page=${recordsPerPage}&page=${currentPage}&_embed${categoryfilter == 0 ? "" : `&article-type=${categoryfilter}`
        }`;
      const response = await apiCall(slug3, type, "blog");

      const totalPagesHeader = response.headers.get("X-WP-TotalPages");
      const totalPages = totalPagesHeader ? parseInt(totalPagesHeader) : 0;

      setTotalPages(totalPages);
      const data = await response.data;
      setInnerArticle(data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching article:", error);
    }
  };
  const fetchCategory = async (id) => {
    try {
      const data = await apiCall(slug1, type);
      setCategory(data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const formatDate = (inputDate) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const date = new Date(inputDate);
    return date.toLocaleDateString("en-US", options);
  };

  const fetchBlog = async () => {
    try {
      const data = await apiCall(slug2, type);
      setBlog(data[0]);
      setYoastData(data[0].yoast_head_json);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  function stripHTMLTags(html) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    return doc.body.textContent || "";
  }
  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchBlog();
    fetchArticle();
    fetchCategory();
  }, [categoryfilter, currentPage]);

  // Assuming you have an array of articles in the state
  const [filteredArticles, setFilteredArticles] = useState(innerArticle);

  // Function to filter articles by title
  const filterByTitle = (searchText) => {
    const filtered = innerArticle.filter((item) =>
      item.title.rendered.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredArticles(filtered);
    setCurrentPage(1);
  };
  /* Pagination Code */
  const recordsPerPage = 6;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = filteredArticles;
  const npage = totalPages;
  const numbers = [...Array(npage + 1).keys()].slice(1);
  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  }
  function changeCPage(id) {
    setCurrentPage(id);
  }
  function nextPage() {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
  }
  useEffect(() => {
    window.scrollTo(0, 0);
    // Set filteredArticles based on innerArticle
    setFilteredArticles(innerArticle);
  }, [innerArticle]);

  const MAX_VISIBLE_PAGES = 3; // Number of page numbers to display

  // Updated logic for rendering page numbers
  function renderPageNumbers() {
    let renderedNumbers = [];

    // Create an array of page numbers from 1 to totalPages
    const totalPagesArray = Array.from({ length: totalPages }, (_, i) => i + 1);

    if (totalPages <= MAX_VISIBLE_PAGES) {
      renderedNumbers = totalPagesArray;
    } else {
      const halfMax = Math.floor(MAX_VISIBLE_PAGES / 2);

      if (currentPage <= halfMax) {
        renderedNumbers = totalPagesArray
          .slice(0, MAX_VISIBLE_PAGES - 1)
          .concat(["..."]);
      } else if (currentPage > totalPages - halfMax) {
        renderedNumbers = ["..."].concat(
          totalPagesArray.slice(totalPages - MAX_VISIBLE_PAGES + 1)
        );
      } else {
        const start = currentPage - halfMax;
        const end = currentPage + halfMax - 1;
        renderedNumbers = ["..."].concat(
          totalPagesArray.slice(start, end),
          "..."
        );
      }
    }

    return renderedNumbers.map((n, i) => (
      <li className={`page-item ${currentPage === n ? "active" : ""}`} key={i}>
        {n === "..." ? (
          <span className="page-link">{n}</span>
        ) : (
          <Link href="" className="page-link" onClick={() => changeCPage(n)}>
            {n}
          </Link>
        )}
      </li>
    ));
  }

  const handleCategoryClick = (categoryId) => {
    setCurrentPage(1);
    setCategoryfilter(categoryId === categoryfilter ? null : categoryId);
  };

  if (yoastData.og_url !== undefined) {
    const wpurl = yoastData.og_url.toString()
    const newurl = wpurl.replace('wpatlas', 'www');
    finalreacturl = newurl.slice(0, newurl.lastIndexOf('/'))
  }
  return (
    <>
      <div>
        <HelmetProvider>
          <Helmet>
            <title>{yoastData.title}</title>
            <meta name="description" content={yoastData.description} />
            <link rel="canonical" hreflang="en" href={finalreacturl} />
            <meta
              property="og:locale"
              content="en_US"
              className="yoast-seo-meta-tag"
            />
            <meta
              property="og:type"
              content="article"
              className="yoast-seo-meta-tag"
            />
            <meta
              property="og:title"
              content={yoastData.og_title}
              className="yoast-seo-meta-tag"
            />
            <meta
              property="og:description"
              content={yoastData.og_description}
              className="yoast-seo-meta-tag"
            />
            <meta
              property="og:url"
              content={finalreacturl}
              className="yoast-seo-meta-tag"
            />
            <meta
              property="og:site_name"
              content={yoastData.og_site_name}
              className="yoast-seo-meta-tag"
            />
            <meta
              property="article:modified_time"
              content={yoastData.article_modified_time}
              className="yoast-seo-meta-tag"
            />
            <meta
              property="og:image"
              content={yoastData.url}
              className="yoast-seo-meta-tag"
            />
            {/* <meta property="og:image:width" content={yoastData.og_image.width} className="yoast-seo-meta-tag" />
                    <meta property="og:image:height" content={yoastData.og_image.height} className="yoast-seo-meta-tag" /> */}
            {/* <meta property="og:image:type" content={yoastData.og_image.type} className="yoast-seo-meta-tag" />
                    <meta name="twitter:card" content={yoastData.twitter_card} className="yoast-seo-meta-tag" /> */}
            <meta
              name="twitter:label1"
              content="Est. reading time"
              className="yoast-seo-meta-tag"
            />
            <meta
              name="twitter:data1"
              content="1 minute"
              className="yoast-seo-meta-tag"
            />
          </Helmet>
        </HelmetProvider>
        {
          <div className="pt-5 pb-md-5 pb-3">
            <div className="container pt-5 pb-5">
              <div className="row gx-md-5 pt-5 pb-md-5 align-items-center">
                <div className="col-md-8 col-12">
                  {loading ? (
                    <Conloader />
                  ) : (
                    <>
                      <p className="list-link">{blog?.title?.rendered}</p>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: blog?.content?.rendered,
                        }}
                      ></div>
                    </>
                  )}
                </div>
              </div>
              <div className="filter pb-5">
                {loading ? (
                  <Conloader />
                ) : (
                  <>
                    <div className="all_btn">
                      <Link
                        onClick={() => {
                          setCurrentPage(1);
                          setCategoryfilter(0);
                        }}
                      >
                        All
                      </Link>
                    </div>
                    <div className="filter_tab">
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="currentColor"
                          className="bi bi-chevron-left"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                          />
                        </svg>
                      </span>
                      <div className="filter_nav">
                        {category &&
                          category !== null &&
                          category.map((categ, key) => (
                            <span
                              key={key}
                              role="button"
                              onClick={() => handleCategoryClick(categ?.id)}
                              className={`${categoryfilter === categ?.id
                                ? "active text-custom"
                                : ""
                                }`}
                            >
                              {categ?.name}
                            </span>
                          ))}
                      </div>
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="currentColor"
                          className="bi bi-chevron-right"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                          />
                        </svg>
                      </span>
                    </div>
                    <div className="filter_search">
                      <input
                        type="search"
                        className="form-control"
                        placeholder="Search"
                        onChange={(e) => filterByTitle(e.target.value)}
                      />
                    </div>
                  </>
                )}
              </div>
              <div className="row justify-content-start pt-md-5 gy-md-5">
                {records && records.length > 0 ? (
                  loading ? (
                    <div className="row">
                      <div className="col-md-4" >
                        <Imgloader />
                      </div>
                      <div className="col-md-4" >
                        <Imgloader />
                      </div>
                      <div className="col-md-4" >
                        <Imgloader />
                      </div>
                    </div>
                  ) : (
                    records.map((item) =>
                      loading ? (
                        <div className="row">
                          <div className="col-md-4" >
                            <Imgloader />
                          </div>
                          <div className="col-md-4" >
                            <Imgloader />
                          </div>
                          <div className="col-md-4" >
                            <Imgloader />
                          </div>
                        </div>
                      ) : (
                        <BlogArticles data={item} image1={image} />
                      )
                    )
                  )
                ) : (
                  <p>No matching articles found.</p>
                )}
              </div>
              <div className="section-spacing pb-0">
                {loading ? (
                  <Conloader />
                ) : (
                  <>
                    <nav aria-label="Page navigation example">
                      <ul className="pagination justify-content-center">
                        <li className="page-item">
                          <Link
                            className="page-link"
                            href=""
                            onClick={() => changeCPage(1)}
                          >
                            First
                          </Link>
                        </li>
                        <li className="page-item">
                          <Link className="page-link" href="" onClick={prePage}>
                            {" << "}
                          </Link>
                        </li>
                        {renderPageNumbers()}
                        <li className="page-item">
                          <Link className="page-link" href="" onClick={nextPage}>
                            {" >> "}
                          </Link>
                        </li>
                        <li className="page-item">
                          <Link
                            className="page-link"
                            href=""
                            onClick={() => changeCPage(npage)}
                          >
                            Last
                          </Link>
                        </li>
                      </ul>
                    </nav>
                  </>
                )}
              </div>
            </div>
          </div>
        }
      </div>
    </>
  );
};

function BlogArticles({ data, image1 }) {
  function formatDate(inputDate) {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const date = new Date(inputDate);
    return date.toLocaleDateString("en-US", options);
  }
  return (
    <Link to={`/articles/${data?.slug}`} className="artical-card dot-animation">
      <div className="artical-card-img">
        {data?._embedded &&
          data?._embedded["wp:featuredmedia"] &&
          data?._embedded["wp:featuredmedia"][0].source_url ? (
          <img
            src={data?._embedded["wp:featuredmedia"][0].source_url}
            alt=""
          />
        ) : (
          <img src={image1} alt="placeholderimage" />
        )}
      </div>
      <div className="row justify-content-between align-items-center artical-card-head">
        <div className="col">
          <p>
            {/* Web Development */}
            {data?._embedded?.["wp:term"]?.[0]?.[0]?.name}
          </p>
        </div>
        <div className="col">
          <div className="d-flex justify-content-end dot-flex-testi">
            <div className="dot-testi light-green-testi"></div>
            <div className="dot-testi green-testi"></div>
            <div className="dot-testi blue-testi"></div>
          </div>
        </div>
      </div>
      <div className="artical-card-title">
        <h3>
          {/* Cost to Develop a Website in 2023: The Complete Guide */}
          {data?.title?.rendered}
        </h3>
        <p className="artical-card-date">
          {/* July 26, 2023 */}
          {formatDate(data?.date)}
        </p>
      </div>
    </Link>
  );
}

export default ArticleListone;
