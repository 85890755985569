import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Conloader from "../componentsheaderfooter/Conloader";
const WhitepaperDetailtwo = ({ data }) => {
  const [load, isLoad] = useState(true);

  useEffect(() => {
    // Simulate loading delay
    const delay = setTimeout(() => {
      isLoad(false);
    }, 1000); // Adjust the delay as needed

    return () => clearTimeout(delay);
  }, []);

  return (
    <>
      <div className="section-spacing pt-0">
        <div className="container">
          <div className="row potision-relative align-items-start">
            <div
              className="col-12"
            >
              {load ? (
                <Conloader />
              ) : (
                <div dangerouslySetInnerHTML={{ __html: data[0]?.content?.rendered }}>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WhitepaperDetailtwo;
