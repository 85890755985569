import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CareerForm from "../Form/CareerFrom";
import Conloader from "../componentsheaderfooter/Conloader";

const CareersOne = (props) => {
  const { careers3data } = props;
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
  const [error, setError] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const handleClose = (e) => {
    const elements = document.querySelectorAll(".careers_heading");

    elements.forEach((element) => {
      element.innerText = "Submit your CV";
    });
    const formElement = document.getElementById("careerSubmit");
    if (formElement) {
      formElement.reset();
      setError({});
      setIsCaptchaVerified(false);
    }
  };
  useEffect(() => {
    if (careers3data.section_setting === true) {
      setIsLoading(false);
    }
  }, [careers3data.section_setting]);
  return (
    <>
      {careers3data.section_setting === true ? (
        <div className="job-section section-spacing">
          <div className="container">
            <div>
              <div className="row">
                <div className="col-12">
                  {isLoading ? (
                    <Conloader />
                  ) : (
                    <>
                      <div
                        className="page-title"
                        dangerouslySetInnerHTML={{
                          __html:
                            careers3data?.image_with_right_text[0]?.right_text,
                        }}
                      ></div>
                      <div className="carrer-team">
                        <div className="career-aplly mt-md-4 mt-2">
                          <Link
                            to="#"
                            data-bs-toggle="modal"
                            data-bs-target="#FormModal"
                            onClick={handleClose}
                          >
                            {
                              careers3data?.image_with_right_text[0]?.button_link
                                ?.title
                            }
                          </Link>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default CareersOne;
