import React, { useEffect, useState } from "react";
import EmploySlider from "./EmploySlider";
import $ from "jquery";
import gsap, { TweenMax, Power0, TimelineMax } from "gsap";
import * as ScrollMagic from "scrollmagic";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
import Imgloader from "../componentsheaderfooter/Imgloader";
import Conloader from "../componentsheaderfooter/Conloader";

const Aboutsectionseven = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const employ1Setting = {
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: true,
    dots: true,
    arrows: false,
    autoplay: true,
    focusOnSelect: true,
    centerMode: false,
    centerPadding: "0",
    cssEase: "ease",
    // adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 780,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const { about7data } = props;

  const seledtedreviewemp = about7data.select_employee_reviews.map(
    (reviews, index) => {
      return reviews.ID;
    }
  );

  useEffect(() => {
    ScrollMagicPluginGsap(ScrollMagic, TweenMax);

    const controller = new ScrollMagic.Controller();

    const scene = new ScrollMagic.Scene({
      triggerElement: ".aboutemployeereviewsection",
      triggerHook: 0.8, // Adjust this value based on when you want the animation to start
      duration: "50%",
      reverse: true,
    });

    var animationTimeline = new TimelineMax();
    animationTimeline.to(
      $(".commonanimation4"),
      {
        x: 0, // Adjust the value as needed
        opacity: 1,
        ease: Power0.easeNone,
      },
      0
    );

    // Set the timeline as the tween for the ScrollMagic scene
    scene.setTween(animationTimeline);
    // Add the scene to the controller
    scene.addTo(controller);
    scene.on("enter", (event) => {
      $(".aboutemployeereviewsection").addClass("title-colorchange");
    });

    // Remove class on scene leave (reverse)
    scene.on("leave", (event) => {
      $(".aboutemployeereviewsection").removeClass("title-colorchange");
    });
    if (about7data.section_settings === true) {
      setIsLoading(false);
    }
    return () => {
      controller.destroy();
    };
  }, []);

  return (
    <>
      {about7data.section_settings === true ? (
        <div className="section-spacing atlasdark px-3 aboutemployeereviewsection commonanimation4">
          <div className="container aboutseven">
            {isLoading ? (
              <Conloader />
            ) : (
              <h2 className="page-title fw-bold mb-md-5 mb-4 text-white">
                {about7data?.employee_review_title}
              </h2>
            )}
            {isLoading ? (
              <Imgloader />
            ) : (
              <EmploySlider
                sliders={employ1Setting}
                employedata={about7data}
                selectedIDreview={seledtedreviewemp}
              />
            )}
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default Aboutsectionseven;
