import React, { useEffect, useState } from "react";
import Button from "../button/button";
import { Spinner } from "../Spinner";
import Imgloader from "../componentsheaderfooter/Imgloader";
import Conloader from "../componentsheaderfooter/Conloader";
import Videosdddd from './blurry.mp4'

const Homesectionone = (props) => {
  
  const { section1data } = props;
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (section1data.section_settings === true) {
      setIsLoading(false);
    }
  }, [section1data.section_settings]);

  return (
    <>
      {section1data.section_settings === true ? (
        <div id="home-banner-videos" className="pt-md-5 pb-md-5 pt-5 pb-3 home-banner height-animation">
          <div className="container pt-5 pb-5">
            <div className="row gx-md-5 pt-md-5 pb-md-5 align-items-stretch overflow-hidden justify-content-center">
              <div className="">
                {isLoading ? (
                  <div className="height-animation">
                    <Imgloader />
                  </div>
                ) : (
                  <>
                    <video className="bannervideo bg-videos-add" autoPlay playsInline muted loop>
                      <source src={Videosdddd} type="video/mp4" />
                    </video>
                  </>
                )}
              </div>
              <div className="align-self-center home-banner-texts">
                {isLoading ? (
                  <div className="height-animation">
                    <Conloader />
                  </div>
                ) : (
                  <>
                    <div className="fw-500 mb-4">{section1data.banner_title}</div>
                    <h1 className="fw-bold page-title mb-4">
                      {section1data.banner_sub_title}
                    </h1>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: section1data.banner_content,
                      }}
                    />
                    <Button
                      link={section1data.banner_button.url}
                      target={section1data.banner_button.target}
                      name={section1data.banner_button.title}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default Homesectionone;
