import React, { useEffect, useState, useRef } from "react";
import Slider1 from "./Slider1";
import Slider2 from "./Slider2";
import gsap, { TweenMax, TimelineMax } from "gsap";
import * as ScrollMagic from "scrollmagic";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
import Imgloader from "../componentsheaderfooter/Imgloader";
import Conloader from "../componentsheaderfooter/Conloader";

const Homesectionsix = (props) => {
  const { section6data } = props;
  const slider1Ref = useRef(null);
  const slider2Ref = useRef(null);
  const [slidersInitialized, setSlidersInitialized] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    ScrollMagicPluginGsap(ScrollMagic, TweenMax);
    setIsLoading(false);

    var controllerMid = new ScrollMagic.Controller();

    // First ScrollMagic Scene
    const scene1 = new ScrollMagic.Scene({
      triggerElement: ".technologianimationstart", // Replace with your trigger element
      triggerHook: 0.5, // Trigger the animation when the element is in the middle of the viewport
      duration: "50%", // Length of the animation trigger
      reverse: true,
    });

    var animationTimeline = new TimelineMax();

    // Set the timeline as the tween for the ScrollMagic scene
    scene1.setTween(animationTimeline);

    if (slidersInitialized) {
      scene1.addTo(controllerMid);
      scene1.setClassToggle("#technologies_slider", "technologystart");
    }

    return () => {
      controllerMid.destroy();
    };
  }, [slidersInitialized]);

  const slider1Settings = {
    asNavFor: slider2Ref.current,
    ref: slider1Ref,
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    dots: false,
    arrows: true,
    prevArrow: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="34.088"
        height="63.932"
        viewBox="0 0 34.088 63.932"
      >
        <path
          id="Path_5406"
          data-name="Path 5406"
          d="M249.94,7979.085l-30.906,30.906L249.94,8040.9"
          transform="translate(-216.913 -7978.025)"
          fill="none"
          stroke="#A2BA5F"
          strokeWidth="3"
        />
      </svg>
    ),
    nextArrow: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="34.088"
        height="63.933"
        viewBox="0 0 34.088 63.933"
      >
        <path
          id="Path_5407"
          data-name="Path 5407"
          d="M249.94,7979.085l-30.906,30.906L249.94,8040.9"
          transform="translate(251 8041.957) rotate(180)"
          fill="none"
          stroke="#A2BA5F"
          strokeWidth="3"
        />
      </svg>
    ),

    centerMode: false,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 780,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    afterChange: () => {
      // Set the slidersInitialized state to true
      setSlidersInitialized(true);
    },
  };

  const slider2Settings = {
    asNavFor: slider1Ref.current,
    ref: slider2Ref,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: false,
    infinite: true,
    speed: 1000,
    afterChange: () => {
      // Set the slidersInitialized state to true
      setSlidersInitialized(true);
    },
  };

  return (
    <>
      {section6data.section_setting === true ? (
        <div
          className="section-spacing home_page_slider atlasdark"
          id="technologies_slider"
        >
          <div className="technologianimationstart"></div>
          <div className="container">
            <div className="row">
              <div className="col-12 mb-4">
                {isLoading ? (
                  <Conloader />
                ) : (
                  <>
                    <h2 className="page-title fw-bold  mb-2 text-white d-table">
                      {section6data.main_title}
                    </h2>
                    <div className="sub-title fw-600 mb-4 text-white">
                      {section6data.sub_title}
                    </div>
                  </>
                )}
              </div>
              <div className="col-12">
                {isLoading ? (
                  <Imgloader />
                ) : (
                  <>
                    <Slider1
                      settings={slider1Settings}
                      slider2={slider2Ref}
                      sliderdata={section6data.technologies}
                    />
                    <Slider2
                      settings={slider2Settings}
                      slider1={slider1Ref}
                      sliderdata1={section6data.technologies}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default Homesectionsix;
