import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Loader from "../Loader";
import image from "../img/placeholder-1-1.webp";
import { HelmetProvider } from "react-helmet-async";
import { Helmet } from "react-helmet";
import { apiCall } from "../authentication";
import Conloader from "../componentsheaderfooter/Conloader";
import Imgloader from "../componentsheaderfooter/Imgloader";
const PressReleaseListone = () => {
  const [blog, setBlog] = useState([]);
  const [innerArticle, setInnerArticle] = useState([]);
  const [loading, setLoading] = useState(true);
  const [yoastData, setYoastData] = useState({});

  let finalreacturl;
  const slug1 = `wp/v2/press-release?per_page=100&_embed`;
  const slug2 = "wp/v2/pages?slug=press-release&_embed";
  const type = "get";
  const fetchArticle = async () => {
    setLoading(true);
    try {
      const data = await apiCall(slug1, type);
      setInnerArticle(data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching article:", error);
    }
  };

  const fetchBlog = async () => {
    try {
      const data = await apiCall(slug2, type);
      setBlog(data[0]);
      setYoastData(data[0].yoast_head_json);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  function stripHTMLTags(html) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    return doc.body.textContent || "";
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchBlog();
    fetchArticle();
  }, []);

  const renderedContent = blog?.content?.rendered;

  const renderContent = () => {
    if (!renderedContent) {
      return null; // Handle cases where the content is empty or not available
    }
    const contentArray = renderedContent.split(/(<h3[^>]*>.*?<\/h3>)/);
    return (
      <>
        {loading ? (
          <Conloader />
        ) : (
          <>
            <p className="list-link">{blog?.title?.rendered}</p>
            <div
              dangerouslySetInnerHTML={{
                __html: blog?.content?.rendered,
              }}
            ></div>
          </>
        )}
      </>
    );
  };

  if (yoastData.og_url !== undefined) {
    const wpurl = yoastData.og_url.toString()
    const newurl = wpurl.replace('wpatlas', 'www');
    finalreacturl = newurl.slice(0, newurl.lastIndexOf('/'))
  }

  return (
    <>
      <div>
        <HelmetProvider>
          <Helmet>
            <title>{yoastData.title}</title>
            <meta name="description" content={yoastData.description} />
            <link rel="canonical" hreflang="en" href={finalreacturl} />
            <meta
              property="og:locale"
              content="en_US"
              className="yoast-seo-meta-tag"
            />
            <meta
              property="og:type"
              content="article"
              className="yoast-seo-meta-tag"
            />
            <meta
              property="og:title"
              content={yoastData.og_title}
              className="yoast-seo-meta-tag"
            />
            <meta
              property="og:description"
              content={yoastData.og_description}
              className="yoast-seo-meta-tag"
            />
            <meta
              property="og:url"
              content={finalreacturl}
              className="yoast-seo-meta-tag"
            />
            <meta
              property="og:site_name"
              content={yoastData.og_site_name}
              className="yoast-seo-meta-tag"
            />
            <meta
              property="article:modified_time"
              content={yoastData.article_modified_time}
              className="yoast-seo-meta-tag"
            />
            <meta
              property="og:image"
              content={yoastData.url}
              className="yoast-seo-meta-tag"
            />
            {/* <meta property="og:image:width" content={yoastData.og_image.width} className="yoast-seo-meta-tag" />
                    <meta property="og:image:height" content={yoastData.og_image.height} className="yoast-seo-meta-tag" /> */}
            {/* <meta property="og:image:type" content={yoastData.og_image.type} className="yoast-seo-meta-tag" />
                    <meta name="twitter:card" content={yoastData.twitter_card} className="yoast-seo-meta-tag" /> */}
            <meta
              name="twitter:label1"
              content="Est. reading time"
              className="yoast-seo-meta-tag"
            />
            <meta
              name="twitter:data1"
              content="1 minute"
              className="yoast-seo-meta-tag"
            />
          </Helmet>
        </HelmetProvider>
        <div className="pt-5 pb-md-5 pb-3">
          <div className="container pt-5 pb-5">
            <div className="row gx-md-5 pt-5 pb-md-5 align-items-center">
              <div className="col-md-8 col-12">
                {loading ? (
                  <Conloader />
                ) : (
                  <>
                    {renderContent()}
                  </>
                )}
              </div>
            </div>
            <div className="row justify-content-start pt-md-5 gy-md-5">
              {innerArticle && innerArticle.length > 0 ? (
                loading ? (
                  <Imgloader />
                ) : (
                  innerArticle.map((item) => (
                    <BlogArticles data={item} image1={image} />
                  ))
                )
              ) : (
                <p>No matching articles found.</p>
              )}
            </div>
          </div>
        </div>
      </div >
    </>
  );
};

function BlogArticles({ data, image1 }) {
  function formatDate(inputDate) {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const date = new Date(inputDate);
    return date.toLocaleDateString("en-US", options);
  }
  return (
    <div className="artical-card dot-animation">
      <div className="artical-card-img">
        <Link to={`/press-release/${data?.slug}`}>
          {data?._embedded &&
            data?._embedded["wp:featuredmedia"] &&
            data?._embedded["wp:featuredmedia"][0].source_url ? (
            <img
              src={data?._embedded["wp:featuredmedia"][0].source_url}
              alt=""
            />
          ) : (
            <img src={image1} alt="placeholderimage" />
          )}
        </Link>
      </div>
      <div className="row justify-content-between align-items-center artical-card-head">
        <div className="col">
          <div className="d-flex justify-content-end dot-flex-testi">
            <div className="dot-testi light-green-testi"></div>
            <div className="dot-testi green-testi"></div>
            <div className="dot-testi blue-testi"></div>
          </div>
        </div>
      </div>
      <div className="artical-card-title">
        <h3>
          {/* Cost to Develop a Website in 2023: The Complete Guide */}
          {data?.title?.rendered}
        </h3>
        <p className="artical-card-date">
          {/* July 26, 2023 */}
          {formatDate(data?.date)}
        </p>
      </div>
    </div>
  );
}

export default PressReleaseListone;
