import React, { useState, useEffect } from "react";
import ServicesDetailoone from "./servicesdetailpage/ServicesDetailoone";
import ServicesDetailotwo from "./servicesdetailpage/ServicesDetailotwo";
import ServicesDetailothree from "./servicesdetailpage/ServicesDetailothree";
import ServicesDetailofour from "./servicesdetailpage/ServicesDetailofour";
import ServicesDetailofive from "./servicesdetailpage/ServicesDetailofive";
import Servicesectiontwo from "./Service/Servicesectiontwo";
import ServiceDetailoseven from "./servicesdetailpage/ServiceDetailoseven";
import ServiceDetailosix from "./servicesdetailpage/ServiceDetailosix";
import ServiceDetailoeight from "./servicesdetailpage/ServiceDetailoeight";
import Loader from "./Loader";
import "../src/ServicesDetail.css";
import { useParams } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { Helmet } from "react-helmet";
import CommonCta from "./CommonCta";
import ReCAPTCHA from "react-google-recaptcha";
import Form from "./Form/Form";
import { apiCall, imageUpdate } from "./authentication";
import Conloader from "./componentsheaderfooter/Conloader";
import Imgloader from "./componentsheaderfooter/Imgloader";

const ServicesDetail = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [error, setError] = useState([]);
  const { slug } = useParams();
  let [serviceDetail, setServiceDetail] = useState([]);
  const [servicedetail1, setServiceDetail1] = useState([]);
  const [servicedetail2, setServiceDetail2] = useState([]);
  const [servicedetail3, setServiceDetail3] = useState([]);
  const [servicedetail4, setServiceDetail4] = useState([]);
  const [servicedetail41, setServiceDetail41] = useState([]);

  const [servicedetail5, setServiceDetail5] = useState([]);
  const [servicedetail6, setServiceDetail6] = useState([]);
  const [servicedetail61, setServiceDetail61] = useState([]);
  const [servicedetail7, setServiceDetail7] = useState([]);
  const [homesection8, setHomesection8] = useState([]);
  const [homesection10, setHomesection10] = useState([]);
  const [yoastData, setYoastData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [servicessection2, setServicessection2] = useState([]);
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
  let finalreacturl;
  const handleClose = () => {
    setCurrentStep(0);
    const formElement = document.getElementById("formCareer");
    const numericInputs = document.getElementsByClassName("numericInput");

    for (let i = 0; i < numericInputs.length; i++) {
      numericInputs[i].addEventListener("keypress", checkInputValue);
    }
    if (formElement) {
      formElement.reset();
      setError({});
      setIsCaptchaVerified(false);
    }
  };

  const checkInputValue = (e) => {
    const input = document.querySelector(".form-control.numericInput");

    // Check if input exists and the value length is already 10
    if (input && input.value.length >= 10 && e.keyCode !== 8 /* Backspace */) {
      e.preventDefault();
      return;
    }
    // Get the entered key
    const key = String.fromCharCode(e.keyCode);

    // Check if the entered key is a number
    const isNumber = /[0-9]/.test(key);

    // Prevent the input if the entered key is not a number
    if (!isNumber) {
      e.preventDefault();
    }
  };

  const slug1 = `wp/v2/pages?slug=${slug}`;
  const type = "get";
  useEffect(() => {
    window.scrollTo(0, 0);
    setIsLoading(true);
    const fetchData = async () => {
      try {
        const data = await apiCall(slug1, type);
        setYoastData(data[0].yoast_head_json);
        data[0].acf.service_inner_sections.forEach((element) => {
          if (element.acf_fc_layout === "service_list_section") {
            setServicessection2(element);
          }

          if (element.acf_fc_layout === "text_with_side_list_section") {
            setServiceDetail2(element);
          }

          if (element.acf_fc_layout === "service_process_of_design_section") {
            setServiceDetail3(element);
          }

          if (element.acf_fc_layout === "why_choose_section") {
            setServiceDetail4(element);
          }

          if (element.acf_fc_layout === "why_choose_second_section") {
            setServiceDetail41(element);
          }

          if (element.acf_fc_layout === "design_tools_section") {
            setServiceDetail5(element);
          }
        });

        data[0].acf.common_section.forEach((element) => {
          if (element.acf_fc_layout === "banner_section") {
            setServiceDetail1(element);
          }
          if (element.acf_fc_layout === "portfolio_project_section") {
            setServiceDetail6(element);
          }
          if (element.acf_fc_layout === "cta_2_section") {
            setServiceDetail61(element);
          }
          if (element.acf_fc_layout === "faq_section") {
            setServiceDetail7(element);
          }
          if (element.acf_fc_layout === "testimonials_section") {
            setHomesection8(element);
          }
          if (element.acf_fc_layout === "cta_section") {
            setHomesection10(element);
          }
        });
        // Process data from the initial API response
        setServiceDetail(data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [slug]);


  if (yoastData.og_url !== undefined) {
    const wpurl = yoastData.og_url.toString()
    const newurl = wpurl.replace('wpatlas', 'www');
    finalreacturl = newurl.slice(0, newurl.lastIndexOf('/'))
  }

  setTimeout(() => {
    imageUpdate();
  }, 1000);

  return (
    <>
      <div>
        {isLoading ? (
          <div className="container">
            <div className="row height-animation align-items-center">
              <div className="col-md-6">
                <Conloader />
              </div>
              <div className="col-md-6">
                <Imgloader />
              </div>
            </div>
          </div>
        ) : (
          <div>
            <HelmetProvider>
              <Helmet>
                <title>{yoastData.title}</title>
                <meta name="description" content={yoastData.description} />
                <link rel="canonical" hreflang="en" href={finalreacturl} />
                <meta
                  property="og:locale"
                  content="en_US"
                  className="yoast-seo-meta-tag"
                />
                <meta
                  property="og:type"
                  content="article"
                  className="yoast-seo-meta-tag"
                />
                <meta
                  property="og:title"
                  content={yoastData.og_title}
                  className="yoast-seo-meta-tag"
                />
                <meta
                  property="og:description"
                  content={yoastData.og_description}
                  className="yoast-seo-meta-tag"
                />
                <meta
                  property="og:url"
                  content={finalreacturl}
                  className="yoast-seo-meta-tag"
                />
                <meta
                  property="og:site_name"
                  content={yoastData.og_site_name}
                  className="yoast-seo-meta-tag"
                />
                <meta
                  property="article:modified_time"
                  content={yoastData.article_modified_time}
                  className="yoast-seo-meta-tag"
                />
                <meta
                  property="og:image"
                  content={yoastData.url}
                  className="yoast-seo-meta-tag"
                />
                {/* <meta property="og:image:width" content={yoastData.og_image.width} className="yoast-seo-meta-tag" />
                    <meta property="og:image:height" content={yoastData.og_image.height} className="yoast-seo-meta-tag" /> */}
                {/* <meta property="og:image:type" content={yoastData.og_image.type} className="yoast-seo-meta-tag" />
                    <meta name="twitter:card" content={yoastData.twitter_card} className="yoast-seo-meta-tag" /> */}
                <meta
                  name="twitter:label1"
                  content="Est. reading time"
                  className="yoast-seo-meta-tag"
                />
                <meta
                  name="twitter:data1"
                  content="1 minute"
                  className="yoast-seo-meta-tag"
                />
              </Helmet>
            </HelmetProvider>
            <ServicesDetailoone
              handleClose={handleClose}
              inner={servicedetail1}
            />
            <ServicesDetailotwo inner={servicedetail2} />
            <ServicesDetailothree inner={servicedetail3} />
            <ServicesDetailofour
              innerSecond={servicedetail41}
              inner={servicedetail4}
            />
            <ServicesDetailofive inner={servicedetail5} />
            <ServiceDetailosix
              innerwork={servicedetail6}
              innercta2={servicedetail61}
            />
            <ServiceDetailoseven inner={servicedetail7} />
            <ServiceDetailoeight inner={homesection8} />
            <Servicesectiontwo inner={servicessection2} />

            {<CommonCta />}
            <div
              class="modal fade"
              id="FormModal"
              tabindex="-1"
              aria-labelledby="FormModal"
              aria-hidden="true"
            >
              <Form />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ServicesDetail;
