import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BackArrow from "../../src/img/hireimg/back-arrow.svg";
import Conloader from "../componentsheaderfooter/Conloader";
import Imgloader from "../componentsheaderfooter/Imgloader";

const ServicesDetailoone = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (props.inner.section_settings === true) {
      setIsLoading(false);
    }
  }, [props.inner.section_settings])

  return (
    <>
      <section className="banner-inner-section pt-5 pb-md-5 pb-3">
        <div className="container pt-5">
          <div className="row align-items-center pt-5 pb-md-5">
            <div className="inner-content col-md-6">
              {isLoading ? (
                <Conloader />
              ) : (
                <>
                  <Link to="/our-services" className="d-inline-block">
                    <img
                      src={BackArrow}
                      alt=""
                      className="img-fluid mb-xl-4 mb-3"
                    />
                  </Link>
                  <h1>{props.inner?.banner_title}</h1>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: props.inner?.banner_content,
                    }}
                  ></p>
                  <div className="dark-bg">
                    <Link
                      className="btn button-com link-animations"
                      data-bs-toggle="modal"
                      data-bs-target="#FormModal"
                      onClick={props.handleClose}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="11.899"
                        height="19.555"
                        viewBox="0 0 11.899 19.555"
                      >
                        <path
                          id="Path_1094"
                          data-name="Path 1094"
                          d="M1122.005,1674.389l7.656,7.656-7.656,7.656"
                          transform="translate(-1119.884 -1672.268)"
                          fill="none"
                          stroke="#fff"
                        />
                      </svg>
                      {props.inner?.banner_button?.title}
                    </Link>
                  </div>
                </>
              )}
            </div>
            <div className="col-md-6 text-md-end">
              {isLoading ? (
                <Imgloader />
              ) : (
                <img
                  src={props.inner?.banner_image?.url}
                  className="img-fluid doodles-image"
                  alt=""
                />
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ServicesDetailoone;
