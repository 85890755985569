import React from 'react'

function Conloader() {
    return (
        <div id='content' className=''>
            <div id="title" className='shimmer'></div>
            <div id='desc'>
                <div className='line shimmer'></div>
                <div className='line shimmer'></div>
                <div className='line shimmer'></div>
                <div className='line shimmer'></div>
            </div>
        </div>
    )
}

export default Conloader;