import React, { useState, useEffect } from "react";
import Loader from "../Loader";
import { Spinner } from "../Spinner";
import { apiCall } from "../authentication";
import Imgloader from "../componentsheaderfooter/Imgloader";
import Conloader from "../componentsheaderfooter/Conloader";
const ClientLogoone = (props) => {
  const { client1logo, data } = props;
  const [termId, setTermId] = useState(14);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingImg, setIsLoadingImg] = useState(true);

  let [ourClient, setOurClient] = useState([]);
  const slug1 = `clientpartners/clientpartlist/?partner_type=${termId}&per_page=100`;
  const type = "get";
  const fetchTestimonialsYoutube = async () => {
    setIsLoading(false);
    setIsLoadingImg(true);
    try {
      const data = await apiCall(slug1, type);
      setOurClient(data);
      setIsLoadingImg(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchTestimonialsYoutube();
  }, [termId]);
  return (
    <>
      {client1logo.section_setting == true ? (
        <div>
          <div className="client-logo-section pt-5 pb-md-5 pb-3">
            <div className="container pt-5 pb-5">
              <div className="row gx-md-5 pt-5 pb-md-5 align-items-center">
                <div className="clients-content mb-5">
                  {isLoading ? (
                    <Conloader />
                  ) : (
                    <>
                      <h3>{client1logo?.client_main_title}</h3>
                      <h2>{client1logo?.client_sub_title}</h2>
                    </>
                  )}
                </div>
                <div id="exTab1" className="container">
                  <div className="accordion" id="accordionExample">
                    <div className="accordion-item accordion-item-nav">
                      <h2
                        className="accordion-header"
                        id="headingOne"
                        onClick={() =>
                          setTermId(client1logo.clienttype[0]?.term_id)
                        }
                      >
                        <button
                          className="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          <div className="client-tebimation d-flex align-items-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="28.048"
                              height="29.742"
                              viewBox="0 0 28.048 29.742"
                            >
                              <g
                                id="Group_5061"
                                data-name="Group 5061"
                                transform="translate(-1035.762 -213.92)"
                              >
                                <g
                                  id="g3628"
                                  transform="translate(1037.153 221.112)"
                                >
                                  <path
                                    id="path3630"
                                    d="M0-82.514a13.472,13.472,0,0,1,2.18-3.736"
                                    transform="translate(0 86.249)"
                                    fill="none"
                                    stroke="#000000"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    strokeWidth="1"
                                  />
                                </g>
                                <g
                                  id="g3632"
                                  transform="translate(1036.262 224.406)"
                                >
                                  <path
                                    id="path3634"
                                    d="M-572.013,0a13.439,13.439,0,0,1,1.07,5.273,13.483,13.483,0,0,1-13.483,13.483A13.483,13.483,0,0,1-597.909,5.273a13.519,13.519,0,0,1,.355-3.088"
                                    transform="translate(597.909)"
                                    fill="none"
                                    stroke="#000000"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    strokeWidth="1"
                                  />
                                </g>
                                <g
                                  id="g3636"
                                  transform="translate(1043.531 216.196)"
                                >
                                  <path
                                    id="path3638"
                                    d="M0-33.443a13.425,13.425,0,0,1,6.214-1.514,13.426,13.426,0,0,1,8.463,2.986"
                                    transform="translate(0 34.957)"
                                    fill="none"
                                    stroke="#000000"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    strokeWidth="1"
                                  />
                                </g>
                                <g
                                  id="g3640"
                                  transform="translate(1053.379 228.702)"
                                >
                                  <path
                                    id="path3642"
                                    d="M-58.252-102.965l.316-.446a1.734,1.734,0,0,0-.413-2.417l-2.54-1.8"
                                    transform="translate(60.889 107.626)"
                                    fill="none"
                                    stroke="#000000"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    strokeWidth="1"
                                  />
                                </g>
                                <g
                                  id="g3644"
                                  transform="translate(1046.127 234.845)"
                                >
                                  <path
                                    id="path3646"
                                    d="M-2.245-44.714l-.1,2.738A1.377,1.377,0,0,0-1.769-40.8a1.377,1.377,0,0,0,1.584.009L3.8-43.564A6.056,6.056,0,0,0,5.29-45.038l1.2-1.7"
                                    transform="translate(2.347 46.739)"
                                    fill="none"
                                    stroke="#000000"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    strokeWidth="1"
                                  />
                                </g>
                                <g
                                  id="g3648"
                                  transform="translate(1044.117 225.49)"
                                >
                                  <path
                                    id="path3650"
                                    d="M-97.6-1.079a2.528,2.528,0,0,0-2.559,1.018l-1.455,2.056a2.2,2.2,0,0,0-.074,2.428"
                                    transform="translate(102.021 1.128)"
                                    fill="none"
                                    stroke="#000000"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    strokeWidth="1"
                                  />
                                </g>
                                <g
                                  id="g3652"
                                  transform="translate(1039.769 216.23)"
                                >
                                  <path
                                    id="path3654"
                                    d="M-.464-122.154a2.612,2.612,0,0,0-.021.331v2.1a.677.677,0,0,0,1.063.556L2.13-120.24a3.014,3.014,0,0,1,1.719-.538h2a2.468,2.468,0,0,0,1.745-.723l1.971-1.972a1.356,1.356,0,0,0,.063-1.85l-.96-1.1a1.114,1.114,0,0,1-.141-1.26"
                                    transform="translate(0.485 127.684)"
                                    fill="none"
                                    stroke="#000000"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    strokeWidth="1"
                                  />
                                </g>
                                <g
                                  id="g3656"
                                  transform="translate(1057.756 222.934)"
                                >
                                  <path
                                    id="path3658"
                                    d="M-.992,0A4.651,4.651,0,0,0-.628,2.55L.214,4.426a2.369,2.369,0,0,0,3.483,1L4.323,5"
                                    transform="translate(1.037)"
                                    fill="none"
                                    stroke="#000000"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    strokeWidth="1"
                                  />
                                </g>
                                <g
                                  id="g3660"
                                  transform="translate(1057.498 218.326)"
                                >
                                  <path
                                    id="path3662"
                                    d="M-125.815-66.816a7.631,7.631,0,0,1-2.423,4.614.6.6,0,0,1-.846,0c-.794-.786-2.427-2.663-2.427-4.789a2.85,2.85,0,0,1,2.881-2.85A2.882,2.882,0,0,1-125.815-66.816Z"
                                    transform="translate(131.51 69.841)"
                                    fill="none"
                                    stroke="#000000"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    strokeWidth="1"
                                  />
                                </g>
                                <g
                                  id="g3664"
                                  transform="translate(1058.954 219.889)"
                                >
                                  <path
                                    id="path3666"
                                    d="M-61.6-30.8A1.394,1.394,0,0,1-63-29.406,1.394,1.394,0,0,1-64.391-30.8,1.394,1.394,0,0,1-63-32.195,1.394,1.394,0,0,1-61.6-30.8Z"
                                    transform="translate(64.391 32.195)"
                                    fill="none"
                                    stroke="#000000"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    strokeWidth="1"
                                  />
                                </g>
                                <g
                                  id="g3668"
                                  transform="translate(1048.536 222.871)"
                                >
                                  <path
                                    id="path3670"
                                    d="M-125.815-66.816a7.631,7.631,0,0,1-2.423,4.614.6.6,0,0,1-.846,0c-.794-.786-2.427-2.663-2.427-4.789a2.85,2.85,0,0,1,2.881-2.85A2.882,2.882,0,0,1-125.815-66.816Z"
                                    transform="translate(131.51 69.841)"
                                    fill="none"
                                    stroke="#000000"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    strokeWidth="1"
                                  />
                                </g>
                                <g
                                  id="g3672"
                                  transform="translate(1049.991 224.434)"
                                >
                                  <path
                                    id="path3674"
                                    d="M-61.6-30.8A1.394,1.394,0,0,1-63-29.406,1.394,1.394,0,0,1-64.391-30.8,1.394,1.394,0,0,1-63-32.195a1.4,1.4,0,0,1,.276.027"
                                    transform="translate(64.391 32.195)"
                                    fill="none"
                                    stroke="#000000"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    strokeWidth="1"
                                  />
                                </g>
                                <g
                                  id="g3676"
                                  transform="translate(1044.117 229.557)"
                                >
                                  <path
                                    id="path3678"
                                    d="M-125.816-66.817a7.63,7.63,0,0,1-2.423,4.614.6.6,0,0,1-.846,0c-.794-.786-2.427-2.663-2.427-4.789a2.85,2.85,0,0,1,2.881-2.85A2.882,2.882,0,0,1-125.816-66.817Z"
                                    transform="translate(131.512 69.842)"
                                    fill="none"
                                    stroke="#000000"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    strokeWidth="1"
                                  />
                                </g>
                                <g
                                  id="g3680"
                                  transform="translate(1045.573 231.119)"
                                >
                                  <path
                                    id="path3682"
                                    d="M-61.6-30.8A1.394,1.394,0,0,1-63-29.406,1.394,1.394,0,0,1-64.391-30.8,1.394,1.394,0,0,1-63-32.195,1.394,1.394,0,0,1-61.6-30.8Z"
                                    transform="translate(64.391 32.195)"
                                    fill="none"
                                    stroke="#000000"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    strokeWidth="1"
                                  />
                                </g>
                                <g
                                  id="g3684"
                                  transform="translate(1037.821 214.42)"
                                >
                                  <path
                                    id="path3686"
                                    d="M-125.815-66.816a7.631,7.631,0,0,1-2.423,4.614.6.6,0,0,1-.846,0c-.794-.786-2.427-2.663-2.427-4.789a2.85,2.85,0,0,1,2.881-2.85A2.882,2.882,0,0,1-125.815-66.816Z"
                                    transform="translate(131.51 69.841)"
                                    fill="none"
                                    stroke="#000000"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    strokeWidth="1"
                                  />
                                </g>
                                <g
                                  id="g3688"
                                  transform="translate(1039.276 215.982)"
                                >
                                  <path
                                    id="path3690"
                                    d="M-61.6-30.8A1.394,1.394,0,0,1-63-29.406,1.394,1.394,0,0,1-64.391-30.8,1.394,1.394,0,0,1-63-32.195,1.394,1.394,0,0,1-61.6-30.8Z"
                                    transform="translate(64.391 32.195)"
                                    fill="none"
                                    stroke="#000000"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    strokeWidth="1"
                                  />
                                </g>
                              </g>
                            </svg>
                            <p>{client1logo.clienttype[0]?.name}</p>
                          </div>
                        </button>
                      </h2>
                      <h2
                        className="accordion-header"
                        id="headingTwo"
                        onClick={() =>
                          setTermId(client1logo.clienttype[1]?.term_id)
                        }
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          <div className="client-tebimation d-flex align-items-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="26.767"
                              height="34.025"
                              viewBox="0 0 26.767 34.025"
                            >
                              <g id="citizenship" transform="translate(0)">
                                <path
                                  id="Path_5549"
                                  data-name="Path 5549"
                                  d="M35.465,19.03a13.247,13.247,0,0,0-4.792-5.542l1.069-1.851a5.1,5.1,0,0,0-8.829-5.1,5.032,5.032,0,0,0-.162,4.77A13.352,13.352,0,0,0,23.347,38c9.577.158,16.294-10.37,12.119-18.972Zm-1.3.112-2.574,2.608a2.758,2.758,0,0,0-.8,1.951v1.785a1.547,1.547,0,0,1-.478,1.121L27.24,29.577a2.213,2.213,0,0,1-3.086-.026l-.517-.517a2.214,2.214,0,0,1-.222-2.856,2.743,2.743,0,0,0,.54-1.643V21.162a2.784,2.784,0,0,0-2.775-2.77,1.564,1.564,0,0,1-1.137-.5l-3.234-3.474A12.051,12.051,0,0,1,23.346,12.5l.068,0,2.54,4.4a1.595,1.595,0,0,0,2.747,0l1.364-2.363a12.048,12.048,0,0,1,4.1,4.6ZM23.966,7.148A3.883,3.883,0,0,1,30.69,11.03C29.948,12.317,28.4,15,27.649,16.3a.371.371,0,0,1-.643,0L23.966,11.03a3.843,3.843,0,0,1,0-3.882ZM11.2,24.645a12.152,12.152,0,0,1,.1-1.534l2.322,2.35a3.271,3.271,0,0,0,2.094.951,2.029,2.029,0,0,1,1.888,2.011v2.2c.082,1.479-1.522,2.2-1.741,3.584A12.114,12.114,0,0,1,11.2,24.645ZM23.346,36.788a12.053,12.053,0,0,1-6.4-1.831,2.682,2.682,0,0,1,.764-1.664,3.756,3.756,0,0,0,1.107-2.674c-.026-1.38.262-3.363-.878-4.408A3.233,3.233,0,0,0,15.8,25.2a2.054,2.054,0,0,1-1.312-.595l-2.9-2.936a12.091,12.091,0,0,1,4.23-6.532l3.339,3.586a2.784,2.784,0,0,0,2.025.884,1.564,1.564,0,0,1,1.56,1.555v3.371a1.54,1.54,0,0,1-.3.924,3.433,3.433,0,0,0,.342,4.434,3.55,3.55,0,0,0,5.307.558l3.071-2.97A2.789,2.789,0,0,0,32,25.486c.063-.915-.245-2.253.449-2.882L34.7,20.331c3.094,7.7-3.019,16.619-11.349,16.457Z"
                                  transform="translate(-9.962 -3.979)"
                                />
                                <path
                                  id="Path_5550"
                                  data-name="Path 5550"
                                  d="M36.763,13.381a2.8,2.8,0,0,0,0-5.6A2.8,2.8,0,0,0,36.763,13.381Zm0-4.382a1.584,1.584,0,1,1-1.584,1.584A1.584,1.584,0,0,1,36.763,9Z"
                                  transform="translate(-19.397 -5.473)"
                                />
                              </g>
                            </svg>
                            <p>{client1logo.clienttype[1]?.name}</p>
                          </div>
                        </button>
                      </h2>
                      <h2
                        className="accordion-header"
                        id="headingThree"
                        onClick={() =>
                          setTermId(client1logo.clienttype[2]?.term_id)
                        }
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          <div className="client-tebimation d-flex align-items-center">
                            <svg
                              id="governmental"
                              xmlns="http://www.w3.org/2000/svg"
                              width="31.4"
                              height="31.401"
                              viewBox="0 0 31.4 31.401"
                            >
                              <path
                                id="Path_5551"
                                data-name="Path 5551"
                                d="M31.894,17.205A.506.506,0,0,0,32.4,16.7V14.673a.506.506,0,0,0-.506-.506h-6.61a8.607,8.607,0,0,0-8.077-8.077V5.4l3.723-1.4a.506.506,0,0,0,.051-.928L16.929,1.052a.506.506,0,0,0-.735.453V6.089a8.607,8.607,0,0,0-8.077,8.077H1.506A.506.506,0,0,0,1,14.673V16.7a.506.506,0,0,0,.506.506h.506V29.36H1.506A.506.506,0,0,0,1,29.867v2.026a.506.506,0,0,0,.506.506H31.894a.506.506,0,0,0,.506-.506V29.867a.506.506,0,0,0-.506-.506h-.506V17.205ZM17.207,2.325l2.279,1.14-2.279.854ZM31.387,16.192H26.829a.506.506,0,0,0-.331-.475l-1.46-.538h6.349ZM21.765,29.36V28.347H24.8V29.36Zm-3.039,0H14.674V27.334a2.026,2.026,0,0,1,4.052,0ZM16.7,24.3a3.042,3.042,0,0,0-3.039,3.039V29.36H12.648V27.841a.506.506,0,0,0-.506-.506h-.506V21.257h.506a.506.506,0,0,0,.506-.506V19.231h8.1V20.75a.506.506,0,0,0,.506.506h.506v6.077h-.506a.506.506,0,0,0-.506.506V29.36H19.739V27.334A3.042,3.042,0,0,0,16.7,24.3Zm-5.065-5.065v1.013H8.6V19.231Zm-1.013,2.026v6.077H9.61V21.257Zm1.013,7.09V29.36H8.6V28.347Zm11.142-1.013V21.257H23.79v6.077Zm-1.013-7.09V19.231H24.8v1.013ZM7.584,18.218V16.547L16.7,13.189l9.116,3.358v1.671ZM16.7,7.076a7.6,7.6,0,0,1,7.571,7.09H22.289l-5.414-1.994a.5.5,0,0,0-.35,0l-5.414,1.994H9.129A7.6,7.6,0,0,1,16.7,7.076Zm-14.687,8.1H8.362l-1.46.538a.506.506,0,0,0-.331.475H2.013Zm1.013,2.026H6.571v1.519a.506.506,0,0,0,.506.506h.506V20.75a.506.506,0,0,0,.506.506H8.6v6.077H8.09a.506.506,0,0,0-.506.506V29.36H3.026ZM31.387,31.386H2.013V30.373H31.387ZM30.374,29.36H25.816V27.841a.506.506,0,0,0-.506-.506H24.8V21.257h.506a.506.506,0,0,0,.506-.506V19.231h.506a.506.506,0,0,0,.506-.506V17.205h3.545Z"
                                transform="translate(-1 -0.998)"
                              />
                              <path
                                id="Path_5552"
                                data-name="Path 5552"
                                d="M32.077,39.506A.506.506,0,0,0,31.571,39H26.506a.506.506,0,0,0-.506.506v2.026a.506.506,0,0,0,.506.506h5.065a.506.506,0,0,0,.506-.506Zm-1.013,1.519H27.013V40.013h4.052Z"
                                transform="translate(-13.339 -19.753)"
                              />
                              <path
                                id="Path_5553"
                                data-name="Path 5553"
                                d="M9.532,38H7.506A.506.506,0,0,0,7,38.506v6.077a.506.506,0,0,0,.506.506H9.532a.506.506,0,0,0,.506-.506V38.506A.506.506,0,0,0,9.532,38Zm-.506,6.077H8.013V39.013H9.026Z"
                                transform="translate(-3.961 -19.26)"
                              />
                              <path
                                id="Path_5554"
                                data-name="Path 5554"
                                d="M51.506,38a.506.506,0,0,0-.506.506v6.077a.506.506,0,0,0,.506.506h2.026a.506.506,0,0,0,.506-.506V38.506A.506.506,0,0,0,53.532,38Zm1.519,6.077H52.013V39.013h1.013Z"
                                transform="translate(-25.677 -19.26)"
                              />
                              <path
                                id="Path_5555"
                                data-name="Path 5555"
                                d="M30.519,27a1.519,1.519,0,1,0,1.519,1.519A1.519,1.519,0,0,0,30.519,27Zm0,2.026a.506.506,0,1,1,.506-.506A.506.506,0,0,1,30.519,29.026Z"
                                transform="translate(-14.819 -13.831)"
                              />
                            </svg>
                            <p>{client1logo.clienttype[2]?.name}</p>
                          </div>
                        </button>
                      </h2>
                      <h2
                        className="accordion-header"
                        id="headingFour"
                        onClick={() =>
                          setTermId(client1logo.clienttype[3]?.term_id)
                        }
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFour"
                          aria-expanded="false"
                          aria-controls="collapseFour"
                        >
                          <div className="client-tebimation d-flex align-items-center">
                            <svg
                              id="magistrate"
                              xmlns="http://www.w3.org/2000/svg"
                              width="36.84"
                              height="30.948"
                              viewBox="0 0 36.84 30.948"
                            >
                              <path
                                id="Path_5556"
                                data-name="Path 5556"
                                d="M18.421,32.415a.576.576,0,0,1-.245-.055L.332,23.965a.576.576,0,0,1,0-1.042l17.844-8.4a.576.576,0,0,1,.489,0l17.844,8.4a.576.576,0,0,1,0,1.042l-17.844,8.4A.576.576,0,0,1,18.421,32.415ZM1.929,23.444l16.492,7.771,16.492-7.771L18.421,15.682Z"
                                transform="translate(-0.001 -14.47)"
                              />
                              <path
                                id="Path_5557"
                                data-name="Path 5557"
                                d="M22.576,62.2A.576.576,0,0,1,22,61.62V55.576a.576.576,0,0,1,1.151,0V61.62A.576.576,0,0,1,22.576,62.2Z"
                                transform="translate(-15.668 -43.335)"
                              />
                              <path
                                id="Path_5558"
                                data-name="Path 5558"
                                d="M102.576,62.2A.576.576,0,0,1,102,61.62V55.576a.576.576,0,1,1,1.151,0V61.62A.576.576,0,0,1,102.576,62.2Z"
                                transform="translate(-72.643 -43.335)"
                              />
                              <path
                                id="Path_5559"
                                data-name="Path 5559"
                                d="M34.088,83.06C27.578,83.06,22,79.494,22,76.576a.576.576,0,0,1,1.151,0c0,1.825,4.605,5.333,10.937,5.333S45.025,78.4,45.025,76.576a.576.576,0,0,1,1.151,0C46.176,79.494,40.6,83.06,34.088,83.06Z"
                                transform="translate(-15.668 -58.291)"
                              />
                              <path
                                id="Path_5560"
                                data-name="Path 5560"
                                d="M71.171,59.674A.576.576,0,0,1,70.6,59.1V48.23L62.28,44.071a.576.576,0,1,1,.512-1.03l8.634,4.317a.576.576,0,0,1,.319.515V59.1A.576.576,0,0,1,71.171,59.674Z"
                                transform="translate(-44.117 -34.769)"
                              />
                              <path
                                id="Path_5561"
                                data-name="Path 5561"
                                d="M89.727,101.454a1.727,1.727,0,1,1,1.727-1.727A1.727,1.727,0,0,1,89.727,101.454Zm0-2.3a.576.576,0,1,0,.576.576A.576.576,0,0,0,89.727,99.151Z"
                                transform="translate(-62.673 -73.959)"
                              />
                              <path
                                id="Path_5562"
                                data-name="Path 5562"
                                d="M91.3,110.536H89.57a.576.576,0,0,1-.576-.625l.288-3.454a.576.576,0,1,1,1.151.043.576.576,0,1,1,1.151-.043l.288,3.454a.576.576,0,0,1-.576.625Zm-1.1-1.151h.478l-.239-2.829a.218.218,0,0,1,0-.043.22.22,0,0,1,0,.043Z"
                                transform="translate(-63.38 -79.588)"
                              />
                            </svg>
                            <p>{client1logo.clienttype[3]?.name}</p>
                          </div>
                        </button>
                      </h2>
                    </div>
                    <div className="accordion-item">
                      <div
                        id="collapseOne"
                        className="accordion-collapse collapse show"
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <div className="tab-pane active" id="1a">
                            <div className="row row-cols-lg-5 align-items-center gx-md-5">
                              {isLoadingImg ? (
                                <Imgloader /> // Show the spinner when loading
                              ) : ourClient.length > 0 ? (
                                // Map through the array and access the 'source_url' property
                                ourClient.map((image, index) => (
                                  <ClientLogoImg
                                    img={image.featured_image?.src}
                                    alt={image.featured_image?.alt}
                                    key={index}
                                  />
                                ))
                              ) : (
                                <p>No images found</p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        id="collapseTwo"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingTwo"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <div className="row row-cols-lg-5 align-items-center gx-md-5">
                            {isLoadingImg ? (
                              <Imgloader /> // Show the spinner when loading
                            ) : ourClient.length > 0 ? (
                              // Map through the array and access the 'source_url' property
                              ourClient.map((image, index) => (
                                <ClientLogoImg
                                  img={image.featured_image?.src}
                                  alt={image.featured_image?.alt}
                                  key={index}
                                />
                              ))
                            ) : (
                              <p>No images found</p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div
                        id="collapseThree"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <div className="row row-cols-lg-5 align-items-center gx-md-5">
                            {isLoadingImg ? (
                              <Imgloader /> // Show the spinner when loading
                            ) : ourClient.length > 0 ? (
                              // Map through the array and access the 'source_url' property
                              ourClient.map((image, index) => (
                                <ClientLogoImg
                                  img={image.featured_image?.src}
                                  alt={image.featured_image?.alt}
                                  key={index}
                                />
                              ))
                            ) : (
                              <p>No images found</p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div
                        id="collapseFour"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingFour"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <div className="row row-cols-lg-5 align-items-center gx-md-5">
                            {isLoadingImg ? (
                              <Imgloader /> // Show the spinner when loading
                            ) : ourClient.length > 0 ? (
                              // Map through the array and access the 'source_url' property
                              ourClient.map((image, index) => (
                                <ClientLogoImg
                                  img={image.featured_image?.src}
                                  alt={image.featured_image?.alt}
                                  key={index}
                                />
                              ))
                            ) : (
                              <p>No images found</p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
};

const ClientLogoImg = (props) => {
  return (
    <>
      <div className="col-xl-2 col-md-3 col-6 col-lg px-md-2 pt-3 px-2">
        <div className="client-inner-logo">
          <img src={props.img} className="img-fluid" alt="" />
        </div>
      </div>
    </>
  );
};

export default ClientLogoone;
