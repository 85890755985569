import React, { useEffect, useState } from "react";
import Conloader from "../componentsheaderfooter/Conloader";
const PressReleaseDetailtwo = ({ data }) => {
  const [load, isLoad] = useState(true);

  useEffect(() => {
    const delay = setTimeout(() => {
      isLoad(false);
    }, 1000);

    return () => clearTimeout(delay);
  }, []);
  return (
    <>
      <div className="section-spacing pt-0">
        <div className="container">
          <div className="row potision-relative align-items-start">
            <div className="col-12">
              {load ? (
                <Conloader />
              ) : (
                <div dangerouslySetInnerHTML={{ __html: data[0]?.content?.rendered }}>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PressReleaseDetailtwo;
