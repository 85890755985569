import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import quickicon from "../img/homeimg/quicklinkicon.svg";
import contactlogo from "../img/homeimg/footer-logo-icon.png";
import Loader from "../Loader";
import Rating from "../Rating/Rating";
import { apiCall } from "../authentication";

const Footer = () => {
  const [footerdata, setFooterdata] = useState([]);
  const [companymenu, setCompanymenu] = useState([]);
  const [quickmenu, setQuickmenu] = useState([]);
  const [designmenu, setDesignmenu] = useState([]);
  const [hiremenu, setHiremenu] = useState([]);
  const [bottommenu, setBottommenu] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const slug1 = "options/all?_fields=footer_section";
  const slug2 = "atlasmenu/menu/21";
  const slug3 = "atlasmenu/menu/22";
  const slug4 = "atlasmenu/menu/481";
  const slug5 = "atlasmenu/menu/23";
  const slug6 = "atlasmenu/menu/4";

  const type = "get";
  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchFooter = async () => {
      try {
        const data = await apiCall(slug1, type);
        setFooterdata(data.footer_section);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching article:", error);
      }
    };
    fetchFooter();
    const fetchMenu21 = async () => {
      try {
        const data = await apiCall(slug2, type);
        setCompanymenu(data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching article:", error);
      }
    };
    fetchMenu21();
    const fetchMenu22 = async () => {
      try {
        const data = await apiCall(slug3, type);
        setDesignmenu(data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching article:", error);
      }
    };
    fetchMenu22();
    const fetchMenu481 = async () => {
      try {
        const data = await apiCall(slug4, type);
        setBottommenu(data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching article:", error);
      }
    };
    fetchMenu481();
    const fetchMenu23 = async () => {
      try {
        const data = await apiCall(slug5, type);
        setHiremenu(data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching article:", error);
      }
    };
    fetchMenu23();
    const fetchMenu4 = async () => {
      try {
        const data = await apiCall(slug6, type);
        setQuickmenu(data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching article:", error);
      }
    };
    fetchMenu4();
  }, []);

  return (
    <>
      <Rating />
      {isLoading ? (
        <div></div>
      ) : (
        <footer className="section-spacing blackbg text-white position-relative pb-5">
          <div className="container position-relative customzindex">
            <div className="row">
              <div className="col-12">
                <div className="footer-quicklink">
                  Quick Links{" "}
                  <img
                    src={quickicon}
                    className="img-fluid quick-icon"
                    alt="QuickIcon"
                  />
                </div>
              </div>
            </div>
            <div className="row pt-5 justify-content-end">
              <div className="col-lg-4 col-sm-6 col-12 order-sm-2 mb-md-0 mb-1 align-self-end text-center">
                <img
                  src={contactlogo}
                  className="img-fluid col-sm-12 col-7 p-0"
                  alt="Contact"
                />
              </div>
              <div className="col-12 col-lg-4 col-sm-6 order-sm-1 px-md-2 p-3">
                <h3 className="page-title fw-bold mb-md-5">
                  {footerdata.contact_us_title}
                </h3>
                <div className="pt-lf-5 pt-3">
                  {footerdata.address_details &&
                    footerdata.address_details.length > 0 &&
                    footerdata.address_details.map((key, index) => (
                      <div key={index}>
                        <div
                          className="sub-title"
                          dangerouslySetInnerHTML={{
                            __html: key.address_title,
                          }}
                        />
                        <div className="d-flex mt-4 footer-contact-main">
                          <div className="footer-contact-icon">
                            <img
                              src={key.address_logo.url}
                              className="img-fluid"
                              alt={key.address_logo.alt}
                            />
                          </div>
                          <div
                            className="footer-contact-details f16"
                            dangerouslySetInnerHTML={{
                              __html: key.address_content,
                            }}
                          />
                        </div>
                      </div>
                    ))}
                </div>
              </div>
              <div className="col-12 col-lg-2 col-sm-6 mt-sm-3 mt-lg-0 order-3 px-md-2 p-3">
                {footerdata.footer_col_1_details &&
                  footerdata.footer_col_1_details.length > 0 &&
                  footerdata.footer_col_1_details.map((key, index) => (
                    <h3 key={index} className="sub-title fw-bold text-green">
                      {key.nav_menu_title}
                    </h3>
                  ))}
                <ul className="footer-list f16">
                  {quickmenu &&
                    quickmenu.length > 0 &&
                    quickmenu.map((key, index) => {
                      const urlParts = key.url
                        .split("/")
                        .filter((part) => part !== ""); // Remove empty segments
                      const lastPart = urlParts[urlParts.length - 1]; // Retrieves the last non-empty segment of the URL
                      return (
                        <li key={index}>
                          <Link
                            to={lastPart}
                            className="text-white text-decoration-none"
                          >
                            {key.title}{" "}
                            {/* Display lastPart or fallback to key.title */}
                          </Link>
                        </li>
                      );
                    })}
                </ul>
              </div>
              <div className="col-12 col-lg-2 col-sm-6 col-sm-6 mt-sm-3 mt-lg-0 order-4 px-md-2 p-3">
                {footerdata.footer_col_2_details &&
                  footerdata.footer_col_2_details.length > 0 &&
                  footerdata.footer_col_2_details.map((key, index) => (
                    <h3 key={index} className="sub-title fw-bold text-green">
                      {key.nav_menu_title}
                    </h3>
                  ))}
                <ul className="footer-list f16">
                  {companymenu &&
                    companymenu.length > 0 &&
                    companymenu.map((key, index) => {
                      const urlParts = key.url
                        .split("/")
                        .filter((part) => part !== ""); // Remove empty segments
                      const lastPart = urlParts[urlParts.length - 1]; // Retrieves the last non-empty segment of the URL
                      return (
                        <li key={index}>
                          <Link
                            to={"our-services/" + lastPart}
                            className="text-white text-decoration-none"
                          >
                            {key.title}{" "}
                            {/* Display lastPart or fallback to key.title */}
                          </Link>
                        </li>
                      );
                    })}
                </ul>
              </div>
              <div className="col-12 col-lg-4 m-0 mt-sm-3 mt-lg-0 order-5 row p-0">
                <div className="col-12 col-sm-6 px-md-2 p-3">
                  {footerdata.footer_col_3_details &&
                    footerdata.footer_col_3_details.length > 0 &&
                    footerdata.footer_col_3_details.map((key, index) => (
                      <h3 key={index} className="sub-title fw-bold text-green">
                        {key.footer_col_3_nav_menu_title}
                      </h3>
                    ))}
                  <ul className="footer-list f16">
                    {designmenu &&
                      designmenu.length > 0 &&
                      designmenu.map((key, index) => {
                        const urlParts = key.url
                          .split("/")
                          .filter((part) => part !== ""); // Remove empty segments
                        const lastPart = urlParts[urlParts.length - 1]; // Retrieves the last non-empty segment of the URL
                        return (
                          <li key={index}>
                            <Link
                              to={"our-services/" + lastPart}
                              className="text-white text-decoration-none"
                            >
                              {key.title}{" "}
                              {/* Display lastPart or fallback to key.title */}
                            </Link>
                          </li>
                        );
                      })}
                  </ul>
                </div>
                <div className="col-12 col-sm-6 mt-sm-0 px-md-2 p-3">
                  {footerdata.footer_col_4_details &&
                    footerdata.footer_col_4_details.length > 0 &&
                    footerdata.footer_col_4_details.map((key, index) => (
                      <h3 key={index} className="sub-title fw-bold text-green">
                        {key.footer_col_4_nav_menu_title}
                      </h3>
                    ))}
                  <ul className="footer-list f16">
                    {hiremenu &&
                      hiremenu.length > 0 &&
                      hiremenu.map((key, index) => {
                        const urlParts = key.url
                          .split("/")
                          .filter((part) => part !== ""); // Remove empty segments
                        const lastPart = urlParts[urlParts.length - 1]; // Retrieves the last non-empty segment of the URL
                        return (
                          <li key={index}>
                            <Link
                              to={"hire/" + lastPart}
                              className="text-white text-decoration-none"
                            >
                              {key.title}{" "}
                              {/* Display lastPart or fallback to key.title */}
                            </Link>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>
            </div>
            <div className="row mt-md-5 mt-4">
              <div className="col-12 text-center">
                <ul className="footer-last-menu">
                  {bottommenu &&
                    bottommenu.length > 0 &&
                    bottommenu.map((key, index) => {
                      const urlParts = key.url
                        .split("/")
                        .filter((part) => part !== ""); // Remove empty segments
                      const lastPart = urlParts[urlParts.length - 1]; // Retrieves the last non-empty segment of the URL

                      return (
                        <li key={index}>
                          <Link
                            to={lastPart}
                            className="text-white text-decoration-none"
                          >
                            {key.title}
                          </Link>
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
          </div>
          <div className="copytext-main f16 d-flex">
            <span
              className="text-white"
              dangerouslySetInnerHTML={{ __html: footerdata.copyright_text }}
            ></span>
            <a
              href="//www.dmca.com/Protection/Status.aspx?ID=29566011-0cfb-49ac-8b86-d7115ed9f634"
              title="DMCA.com Protection Status"
              class="dmca-badge"
            >
              <img
                src="https://images.dmca.com/Badges/dmca_protected_sml_120m.png?ID=29566011-0cfb-49ac-8b86-d7115ed9f634"
                alt="DMCA.com Protection Status"
              />
            </a>
          </div>
        </footer>
      )}
    </>
  );
};

export default Footer;
