import React, { useEffect, useState } from "react";
import image from "../img/placeholder-1-1.webp";
import Imgloader from "../componentsheaderfooter/Imgloader";
import Conloader from "../componentsheaderfooter/Conloader";

const PortfolioInnerOne = ({ post }) => {
  const [isLoading, setIsLoading] = useState(true);

  let workIndex = localStorage.getItem("work index");
  if (!workIndex) {
    workIndex = "0";
  }
  useEffect(() => {
    if (post.acf.case_studies_detail_sections[0].section_settings === true) {
      setIsLoading(false);
    }
  }, [post.acf.case_studies_detail_sections[0].section_settings === true]);
  return (
    <>
      {post &&
        post.acf &&
        post.acf.case_studies_detail_sections &&
        post.acf.case_studies_detail_sections.length > 0 &&
        post.acf.case_studies_detail_sections[0].section_settings === true && (
          <section
            className={`thinkvacuum-section pt-5 mt-5 pt-3 home-banner  pn-color${workIndex}`}
          >
            <div className="container pt-5">
              <div className="row vacuum-container mt-5 pt-5">
                <div className="col-md-6">
                  {isLoading ? (
                    <Imgloader />
                  ) : (
                    <div className="vacuum-inner-text p-3 p-md-5 ">
                      <h2
                        dangerouslySetInnerHTML={{
                          __html:
                            post?.acf?.case_studies_detail_sections[0]
                              ?.case_study_inner_title,
                        }}
                      />
                      {post?.acf?.case_studies_detail_sections[0] &&
                        post?.acf?.case_studies_detail_sections[0]
                          .case_study_inner_image ? (
                        <img
                          src={
                            post?.acf?.case_studies_detail_sections[0]
                              ?.case_study_inner_image
                          }
                          className="img-fluid"
                          alt={
                            post?.acf?.case_studies_detail_sections[0]
                              ?.case_study_inner_title
                          }
                        />
                      ) : (
                        <img src={image} alt="placeholderimage" />
                      )}
                    </div>
                  )}
                </div>
                <div className="col-md-6">
                  {isLoading ? (
                    <Conloader />
                  ) : (
                    <div className="vacuum-content p-3 p-md-5 ">
                      <p
                        dangerouslySetInnerHTML={{
                          __html:
                            post?.acf?.case_studies_detail_sections[0]
                              ?.case_study_inner_content,
                        }}
                      />
                      <div className="d-flex mt-5 vacuum-project justify-content-between">
                        <div className="project-text">
                          <h3
                            dangerouslySetInnerHTML={{
                              __html:
                                post?.acf?.case_studies_detail_sections[0]
                                  ?.case_study_inner_details[0]
                                  ?.case_study_inner_title,
                            }}
                          />
                          <p
                            dangerouslySetInnerHTML={{
                              __html:
                                post?.acf?.case_studies_detail_sections[0]
                                  ?.case_study_inner_details[0]
                                  ?.case_study_inner_description,
                            }}
                          ></p>
                        </div>
                        <div className="project-text">
                          <h3
                            dangerouslySetInnerHTML={{
                              __html:
                                post?.acf?.case_studies_detail_sections[0]
                                  ?.case_study_inner_details[1]
                                  ?.case_study_inner_title,
                            }}
                          />
                          <p
                            dangerouslySetInnerHTML={{
                              __html:
                                post?.acf?.case_studies_detail_sections[0]
                                  ?.case_study_inner_details[1]
                                  ?.case_study_inner_description,
                            }}
                          ></p>
                        </div>
                      </div>
                      <div className="project-text mt-4">
                        <h3
                          dangerouslySetInnerHTML={{
                            __html:
                              post?.acf?.case_studies_detail_sections[0]
                                ?.case_study_inner_details[2]
                                ?.case_study_inner_title,
                          }}
                        />
                        <p
                          dangerouslySetInnerHTML={{
                            __html:
                              post?.acf?.case_studies_detail_sections[0]
                                ?.case_study_inner_details[2]
                                ?.case_study_inner_description,
                          }}
                        ></p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>
        )}
    </>
  );
};
export default PortfolioInnerOne;
