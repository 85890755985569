import React, { useEffect, useState } from "react";
import BackArrow from "../../src/img/hireimg/back-arrow.svg";
import AtlasIcon1 from "../../src/img/servicesimg/atlas-icon.svg";
import image1 from "../img/placeholder-1-1.webp";
import { Link } from "react-router-dom";
import Conloader from "../componentsheaderfooter/Conloader";
import Imgloader from "../componentsheaderfooter/Imgloader";

const BlogDetailone = ({ data }) => {
  const [isLoading, setIsLoading] = useState(true);
  const formatDate = (inputDate) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const date = new Date(inputDate);
    return date.toLocaleDateString("en-US", options);
  };
  useEffect(() => {
    const delay = setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    return () => clearTimeout(delay);
  }, []);
  return (
    <>
      {data &&
        data.map((link, index) => {
          return (
            <>
              <div key={index} className="pt-md-5 pt-3 pb-3" id="blog-content">
                <div className="container pt-5">
                  <div className="row gx-md-5 pt-5 align-items-center">
                    <div className="col-12 dot-animation">
                      {isLoading ? (
                        <Conloader />
                      ) : (
                        <>
                          <div className="blog-content">
                            <Link to="/blog">
                              <img src={BackArrow} alt="" className="img-fluid" />
                            </Link>
                            <h1 dangerouslySetInnerHTML={{ __html: link.title.rendered }} ></h1>
                          </div>
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="author-content d-flex align-items-center mt-4">
                              <div className="author-icon">
                                <img
                                  src={AtlasIcon1}
                                  alt=""
                                  className="img-fluid"
                                />
                              </div>
                              <div className="author-text">
                                <h3>Author</h3>
                                <p>Atlas Softweb</p>
                              </div>
                              <div className="author-text">
                                <p>Published</p>
                                <h3>
                                  {formatDate(
                                    link?.yoast_head_json?.article_published_time
                                  )}
                                </h3>
                              </div>
                            </div>
                            <div className="d-flex dot-flex align-items-end">
                              <div className="dot-sec"></div>
                              <div className="dot-sec1"></div>
                              <div className="dot-sec2"></div>
                            </div>
                          </div>
                        </>
                      )}
                      <div className="blog-image mt-4">
                        {isLoading ? (
                          <Imgloader />
                        ) : (
                          <>
                            {link?._embedded &&
                              link?._embedded["wp:featuredmedia"] &&
                              link?._embedded["wp:featuredmedia"][0].source_url ? (
                              <img
                                src={
                                  link?._embedded["wp:featuredmedia"][0].source_url
                                }
                                alt={link.title.rendered}
                                className="border-radius"
                              />
                            ) : (
                              <img src={image1} alt="placeholderimage" />
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        })}
    </>
  );
};

export default BlogDetailone;
