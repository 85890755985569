import React, { useEffect, useState } from "react";
import "../src/PortfolioInner.css";
import PortfolioInnerOne from "./portfolioinnerpage/PortfolioInnerOne";
import PortfolioInnerTwo from "./portfolioinnerpage/PortfolioInnerTwo";
import PortfolioInnerThree from "./portfolioinnerpage/PortfolioInnerThree";
import { useParams } from "react-router-dom";
import PortfolioInnerFour from "./portfolioinnerpage/PortfolioInnerFour";
import { HelmetProvider } from "react-helmet-async";
import { Helmet } from "react-helmet";
import { apiCall } from "./authentication";
import Conloader from "./componentsheaderfooter/Conloader";
import Imgloader from "./componentsheaderfooter/Imgloader";

const PortfolioInner = () => {
  const { id } = useParams();
  const [postDetail, setPostDetail] = useState([]);
  const [yoastData, setYoastData] = useState({});
  const [isLoading, setIsloading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchPost();
  }, [id]);

  const slug1 = `wp/v2/project/?slug=${id}&acf_format=standard`;
  const type = "get";

  let finalreacturl;

  const fetchPost = async () => {
    try {
      setIsloading(true);
      const data = await apiCall(slug1, type);
      setPostDetail(data[0]);
      setYoastData(data[0].yoast_head_json);
      setIsloading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  if (yoastData.og_url !== undefined) {
    const wpurl = yoastData.og_url.toString()
    const newurl = wpurl.replace('wpatlas', 'www');
    const newurl1 = newurl.replace('.com/project', '.com/work');
    finalreacturl = newurl1.slice(0, newurl1.lastIndexOf('/'))
  }
console.log(postDetail);
  return (
    <>
      {isLoading ? (
        <div className="container">
          <div className="row height-animation align-items-center">
            <div className="col-md-6">
              <Imgloader />
            </div>
            <div className="col-md-6">
              <Conloader />
            </div>
          </div>
        </div>
      ) : (
        <>
          <HelmetProvider>
            <Helmet>
              <title>{yoastData.title}</title>
              <meta name="description" content={yoastData.description} />
              <link rel="canonical" hreflang="en" href={finalreacturl} />
              <meta
                property="og:locale"
                content="en_US"
                className="yoast-seo-meta-tag"
              />
              <meta
                property="og:type"
                content="article"
                className="yoast-seo-meta-tag"
              />
              <meta
                property="og:title"
                content={yoastData.og_title}
                className="yoast-seo-meta-tag"
              />
              <meta
                property="og:description"
                content={yoastData.og_description}
                className="yoast-seo-meta-tag"
              />
              <meta
                property="og:url"
                content={finalreacturl}
                className="yoast-seo-meta-tag"
              />
              <meta
                property="og:site_name"
                content={yoastData.og_site_name}
                className="yoast-seo-meta-tag"
              />
              <meta
                property="article:modified_time"
                content={yoastData.article_modified_time}
                className="yoast-seo-meta-tag"
              />
              <meta
                property="og:image"
                content={yoastData.url}
                className="yoast-seo-meta-tag"
              />
              {/* <meta property="og:image:width" content={yoastData.og_image.width} className="yoast-seo-meta-tag" />
                    <meta property="og:image:height" content={yoastData.og_image.height} className="yoast-seo-meta-tag" /> */}
              {/* <meta property="og:image:type" content={yoastData.og_image.type} className="yoast-seo-meta-tag" />
                    <meta name="twitter:card" content={yoastData.twitter_card} className="yoast-seo-meta-tag" /> */}
              <meta
                name="twitter:label1"
                content="Est. reading time"
                className="yoast-seo-meta-tag"
              />
              <meta
                name="twitter:data1"
                content="1 minute"
                className="yoast-seo-meta-tag"
              />
            </Helmet>
          </HelmetProvider>
          <PortfolioInnerOne post={postDetail} />
          <PortfolioInnerTwo post={postDetail} />
          <PortfolioInnerThree post={postDetail} />
          <PortfolioInnerFour post={postDetail} />
        </>
      )}
    </>
  );
};

export default PortfolioInner;
