import React, { useState, useEffect } from "react";
import BackArrow from "../../src/img/hireimg/back-arrow.svg";
import { Link } from "react-router-dom";
import Conloader from "../componentsheaderfooter/Conloader";
import Imgloader from "../componentsheaderfooter/Imgloader";

const HireInnerone = (props) => {
  const { hire1inner } = props;
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (hire1inner?.section_settings === true) {
      setIsLoading(false);
    }
  }, [hire1inner?.section_settings]);
  return (
    <>
      {hire1inner?.section_settings === true ? (
        <section className="banner-inner-section pt-5 pb-md-5 pb-3">
          <div className="container pt-5 pb-5">
            <div className="row pt-5 pb-md-5">
              <div className="col-md-6">
                {isLoading ? (
                  <Conloader />
                ) : (
                  <>
                    <div className="inner-content">
                      <Link
                        to={`/hire-developers/${props?.prevurl?.current}`}
                        className="d-inline-block"
                      >
                        <img
                          src={BackArrow}
                          alt=""
                          className="img-fluid mb-xl-5 mb-2"
                        />
                      </Link>
                      <h5>{hire1inner.banner_title}</h5>
                      <h1>{hire1inner.banner_sub_title}</h1>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: hire1inner.banner_content,
                        }}
                      ></p>

                      <div className="dark-bg">
                        <Link
                          to={hire1inner?.banner_button?.url}
                          className="btn button-com link-animations"
                          title={hire1inner?.banner_button?.title}
                          data-bs-toggle="modal"
                          data-bs-target="#FormModal"
                          onClick={props.handleClose}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="11.899"
                            height="19.555"
                            viewBox="0 0 11.899 19.555"
                          >
                            <path
                              id="Path_1094"
                              data-name="Path 1094"
                              d="M1122.005,1674.389l7.656,7.656-7.656,7.656"
                              transform="translate(-1119.884 -1672.268)"
                              fill="none"
                              stroke="#fff"
                            />
                          </svg>
                          {hire1inner?.banner_button?.title}
                        </Link>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className="col-md-6 pt-md-0 pt-5 text-md-end mt-md-0 mt-4">
                {isLoading ? (
                  <Imgloader />
                ) : (
                  <>
                    <img
                      src={hire1inner?.banner_image?.url}
                      className="img-fluid doodles-image"
                      alt={hire1inner?.banner_button?.title}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </section>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default HireInnerone;
