import React, { useEffect, useState } from "react";
import Conloader from "../componentsheaderfooter/Conloader";

const NewsRoomsone = (props) => {
  const { news1data } = props;
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (news1data.section_settings === true) {
      setIsLoading(false);
    }
  }, [news1data.section_settings]);

  return (
    <>
      {news1data.section_settings === true ? (
        <div className="pt-5 pb-md-5 pb-3">
          <div className="container pt-5 pb-5">
            <div className="row gx-md-5 pt-5 pb-md-5 align-items-center">
              <div className="col-md-8 col-12">
                {isLoading ? (
                  <Conloader />
                ) : (
                  <>
                    <p className="list-link">{news1data.banner_title}</p>
                    <h2 className="fw-bold page-title">
                      {news1data.banner_sub_title}
                    </h2>
                    <p className="atlas-paragraph">{news1data.banner_content}</p>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default NewsRoomsone;
