import React, { useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Loader from "../Loader";
import { apiCall } from "../authentication";
import Conloader from "../componentsheaderfooter/Conloader";

const Hiresectionseven = (props) => {
  const { hire7data } = props;
  const [partnerData, setpartnerData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  let selectedIds = [];
  let stringIds = "";

  if (hire7data && Array.isArray(hire7data.partners_logo)) {
    selectedIds = hire7data.partners_logo?.map(
      (section9data) => section9data.ID
    );
    stringIds = selectedIds.toString();
  }
  const slug1 = `wp/v2/partners?include=${stringIds}&_embed`;
  const type = "get";
  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      try {
        const dataArray = await apiCall(slug1, type);
        setpartnerData(dataArray);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const logosetting = {
    slidesToShow: 5,
    slidesToScroll: 1,
    infinite: true,
    dots: false,
    arrows: false,
    autoplay: true,
    cssEase: "ease",
    // adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 780,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <>
      <div>
        <div>
          {hire7data.section_settings === true ? (
            <div className="section-spacing hiremainslider">
              <div className="home-banner">
                <div className="">
                  <div className="flex-c">
                    <div className="lion-box w50 w-800-100 fadeIn">
                      <div id="lionCanvasDark" className="darkAnim">
                        <div className="initDot"></div>
                      </div>
                      <div id="particles-js"></div>
                    </div>
                    <div className="owl-main">
                      <div className="owl-content container pt-0">
                        <div className="row mx-0">
                          {isLoading ? (
                            <Conloader />
                          ) : (
                            <>
                              <h2 className="col-12">
                                {hire7data.our_partner_text}
                              </h2>
                            </>
                          )}
                        </div>
                      </div>

                      <Slider
                        className="logo-slider mt-md-5"
                        {...logosetting}
                      >
                        {partnerData &&
                          partnerData.length > 0 &&
                          partnerData.map((key1, index) => (
                            <div
                              key={index}
                              className="position-relative item"
                            >
                              {isLoading ? (
                                <Conloader />
                              ) : (
                                <>
                                  <img
                                    className="img-fluid"
                                    src={
                                      key1._embedded["wp:featuredmedia"][0]
                                        .source_url
                                    }
                                    alt={key1.title.rendered}
                                  />
                                </>
                              )}
                            </div>
                          ))}
                      </Slider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </div>
    </>
  );
};

function HireSlide(props) {
  return (
    <>
      <div className="item">
        <div className="white-box">
          <img src={props.img} alt="" className="img-fuiud" />
        </div>
      </div>
    </>
  );
}

export default Hiresectionseven;
