import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import BackArrow from "../../src/img/hireimg/back-arrow.svg";
import { apiCall } from "../authentication";
import countryCodes from 'country-codes-list';
import Conloader from "../componentsheaderfooter/Conloader";
import Imgloader from "../componentsheaderfooter/Imgloader";

const Contactone = (props) => {
  const { contact1data } = props;
  const { contact1datainner } = props;
  const [contactform, setContactForm] = useState([]);
  const [error, setError] = useState([]);
  const [newerror, setNewError] = useState([]);
  const [newsletterForm, setNewsletterForm] = useState([]);
  const [isCaptchaVerifiedNews, setIsCaptchaVerifiedNews] = useState(false);
  const [myCountryCodesObject, setMyCountryCodesObject] = useState({});
  const phoneCode = countryCodes.customList('countryCode', ' +{countryCallingCode}');
  const [phoneCodeVal, setPhoneCodeVal] = useState(phoneCode['IN']);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const slug1 = "custom/v1/contact-form-fields/497";
  const slug2 = "contact-form-7/v1/contact-forms/497/feedback";
  const slug3 = "contact-form-7/v1/contact-forms/752/feedback";
  const slug4 = "custom/v1/contact-form-fields/752";
  const type = "get";
  const type2 = "post";

  useEffect(() => {
    const fetchForm = async () => {
      try {
        const data = await apiCall(slug1, type);
        setContactForm(data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching article:", error);
      }
    };
    fetchForm();
  }, []);

  const checkInputValue = (e) => {
    const key = String.fromCharCode(e.keyCode);
    const isNumber = /[0-9]/.test(key);
    if (!isNumber) {
      e.preventDefault();
    }
  };
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);

  const handleCaptchaChange = (value) => {
    setIsCaptchaVerified(true);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    // if (isCaptchaVerified) {
    const formData = new FormData(document.getElementById("contact-api-form"));

    let isEmpty = false;
    for (const entry of formData.entries()) {
      const [fieldName, value] = entry;

      if (value.trim() === "") {
        if (fieldName === "Email") {
          setError((error) => ({
            ...error,
            [fieldName]: `Please enter valid ${fieldName}`,
          }));
        } else {
          setError((error) => ({
            ...error,
            [fieldName]: `Please fill ${fieldName} field`,
          }));
        }
        isEmpty = true; // Set isEmpty to true if any field is empty
      } else {
        setError((error) => ({
          ...error,
          [fieldName]: "",
        }));
      }
      if (fieldName === "Email") {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isValid = emailPattern.test(value);
        if (!isValid) {
          isEmpty = true;
          setError((error) => ({
            ...error,
            [fieldName]: `Please fill valid ${fieldName}`,
          }));
        } else {
          setError((error) => ({
            ...error,
            [fieldName]: "",
          }));
        }
      }
    }

    const selectedColors = formData.getAll("serviceSelect");
    formData.append("serviceSelect", JSON.stringify(selectedColors));
    Object.fromEntries(formData);

    // if (!isEmpty) {
    //   if (isCaptchaVerified) {
    const submitForm = async () => {
      try {
        const data = await apiCall(slug2, type2, formData);

        if (data.status === "mail_sent") {
          window.location.replace("/thankyou");
        } else {
          alert(data.data.message);
        }
      } catch (error) {
        console.error("Error submitting data:", error);
      }
    };
    submitForm();
    //   } else {
    //     alert("reCAPTCHA verification failed!");
    //   }
    // }
  };

  const handleInputClass = () => {
    const titletBox = document.querySelector("#contact-api-form .title-box");
    document
      .querySelector("#contact-api-form .con_can")
      .classList.remove("d_close");
    document
      .querySelector("#contact-api-form .con_can")
      .classList.add("d_open");
    document
      .querySelector("#contact-api-form .backbuttons")
      .classList.remove("d_open");
    document
      .querySelector("#contact-api-form .backbuttons")
      .classList.add("d_close");
    const form = document.getElementById("contact-api-form");
    const formGroups = form.querySelectorAll(".form-group");
    // Iterate over the form groups and toggle classes
    formGroups.forEach((formGroup) => {
      if (formGroup.classList.contains("d_open")) {
        formGroup.classList.add("d_close");
        formGroup.classList.remove("d_open");
      } else {
      }
    });
  };
  const handleInputRequest = (event) => {
    const numericInputs = document.getElementsByClassName("numericInput");

    for (let i = 0; i < numericInputs.length; i++) {
      numericInputs[i].addEventListener("keypress", checkInputValue);
    }

    event.preventDefault();
    const form = document.getElementById("contact-api-form");

    const formGroups = form.querySelectorAll(".form-group");

    // Iterate over the form groups and toggle classes
    formGroups.forEach((formGroup) => {
      if (formGroup.classList.contains("d_close")) {
        formGroup.classList.remove("d_close");
        formGroup.classList.add("d_open");
      } else {
      }
    });

    document
      .querySelector("#contact-api-form .con_can")
      .classList.remove("d_open");
    document
      .querySelector("#contact-api-form .con_can")
      .classList.add("d_close");
    document
      .querySelector("#contact-api-form .backbuttons")
      .classList.remove("d_close");
    document
      .querySelector("#contact-api-form .backbuttons")
      .classList.add("d_open");
  };
  const handleLinkClick = () => {
    // Assuming tidioChatApi is globally accessible
    if (window.tidioChatApi) {
      window.tidioChatApi.display(true);
      window.tidioChatApi.open();
    }
  };

  const handleCaptchaChangeNewsletter = (value) => {
    // This function will be called when the user successfully verifies the reCAPTCHA.
    setIsCaptchaVerifiedNews(true);
  };

  useEffect(() => {
    //For Newslatter
    const fetchForm = async () => {
      try {
        const data = await apiCall(slug4, type);
        setNewsletterForm(data);
      } catch (error) {
        console.error("Error submitting data:", error);
      }
    };
    fetchForm();
  }, []);

  const handleFormNewSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);

    let isEmpty = false;
    for (const entry of formData.entries()) {
      const [fieldName, value] = entry;
      if (value.trim() === "") {
        setNewError((newerror) => ({
          ...newerror,
          [fieldName]: `Please fill ${fieldName} field`,
        }));
        isEmpty = true; // Set isEmpty to true if any field is empty
      } else {
        setNewError((newerror) => ({
          ...newerror,
          [fieldName]: "",
        }));
      }
      if (fieldName === "email") {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isValid = emailPattern.test(value);
        if (!isValid) {
          isEmpty = true;
          setError((newerror) => ({
            ...newerror,
            [fieldName]: `Please fill valid ${fieldName}`,
          }));
        } else {
          setError((newerror) => ({
            ...newerror,
            [fieldName]: "",
          }));
        }
      }
    }

    if (!isEmpty) {
      if (isCaptchaVerifiedNews) {
        const submitForm = async () => {
          try {
            const data = await apiCall(slug3, type2, formData);
            if (data.status === "mail_sent") {
              window.location.replace("/thankyou");
            } else {
              alert(data.message);
            }
          } catch (error) {
            console.error("Error submitting data:", error);
          }
        };
        submitForm();
      } else {
        alert("reCAPTCHA verification failed!");
      }
    }
    // You can perform additional actions or make API reqnewerroruests here
  };

  const handleClose = () => {
    const formElement = document.getElementById("newsletter-form");
    if (formElement) {
      formElement.reset();
      setError({});
      setIsCaptchaVerified(false);
    }
  };

  useEffect(() => {
    const fetchedCountryCodes = countryCodes.customList('countryCode', '{countryNameEn}');
    setMyCountryCodesObject(fetchedCountryCodes);
  }, []);

  const getPhonecode = (e) => {
    const fullCountryName = e.target.value;
    const countryCode = Object.keys(myCountryCodesObject).find(
      key => myCountryCodesObject[key] === fullCountryName
    );

    setPhoneCodeVal(phoneCode[countryCode]);
    setCount(phoneCode[countryCode].length);
  };

  const handleInputChange = (e) => {
    const varLength = e.target.value.length;
    if (varLength < count) {
    } else {
      setPhoneCodeVal(e.target.value);
    }
  };


  return (
    <>
      {contact1datainner.section_settings === true ? (
        <section className="create-experiences-section pt-5 pb-md-5 pb-3">
          <div className="container pt-5 pb-5">
            <div className="d-flex pt-5 pb-md-5">
              <div className="title-form">
                {isLoading ? (
                  <>
                    <Conloader />
                    <Conloader />
                  </>
                ) : (
                  <div className="title-box">
                    <div id="contactForm">
                      <form
                        className="form-control"
                        id="contact-api-form"
                        onSubmit={handleFormSubmit}
                      >
                        <div className="d_open">
                          <div className="title-box">
                            <div className="d_open con_can">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: contact1datainner.contact_left_text,
                                }}
                              />
                            </div>
                            <div className="d_close backbuttons">
                              <Link
                                to="#"
                                onClick={handleInputClass}
                                className="backbutton"
                              >
                                <img
                                  src={BackArrow}
                                  className="img-fluid"
                                  alt=""
                                />
                              </Link>
                            </div>
                          </div>
                          {contactform &&
                            contactform.map((response, key) => {
                              if (key < 2) {
                                return (
                                  <>
                                    <div className="form-group">
                                      <>
                                        <div className="lable-and-input">
                                          <label
                                            className="contact-label"
                                            for={response.name}
                                          >
                                            {response.label.replaceAll("-", " ")}
                                          </label>

                                          <div className="error-messages">
                                            <input
                                              onClick={handleInputRequest}
                                              className={`contact-input ${response.type === "tel"
                                                ? "numericInput"
                                                : ""
                                                }`}
                                              type={response.type}
                                              id={response.name}
                                              name={response.name}
                                              placeholder={response.options?.[0]}
                                            />
                                            {Object.keys(error).length > 0 && (
                                              <>
                                                {Object.entries(error).map(
                                                  ([fieldName, errorMessage]) =>
                                                    fieldName ===
                                                      response.name ? (
                                                      <p
                                                        className="text-danger"
                                                        key={fieldName}
                                                      >
                                                        {errorMessage}
                                                      </p>
                                                    ) : null
                                                )}
                                              </>
                                            )}
                                          </div>
                                        </div>
                                      </>
                                    </div>
                                  </>
                                );
                              } else {
                                return (
                                  <>
                                    <div className="form-group d_close">
                                      {response.label === "serviceSelect" ? (
                                        <>
                                          {response.options.map((data, index) => {
                                            return (
                                              <>
                                                <div className="services-select">
                                                  <input
                                                    type={response.type}
                                                    name={response.name}
                                                    id={response.name + index}
                                                    value={data}
                                                  />
                                                  <label
                                                    for={response.name + index}
                                                  >
                                                    {data.replaceAll("-", " ")}
                                                  </label>
                                                </div>
                                              </>
                                            );
                                          })}
                                          {Object.keys(error).length > 0 && (
                                            <div className="error-messages">
                                              {Object.entries(error).map(
                                                ([fieldName, errorMessage]) =>
                                                  fieldName === response.name ? (
                                                    <p
                                                      className="text-danger"
                                                      key={fieldName}
                                                    >
                                                      {errorMessage}
                                                    </p>
                                                  ) : null
                                              )}
                                            </div>
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          <div
                                            className={`lable-and-input ${response.type === "textarea"
                                              ? "textareadiv"
                                              : ""
                                              }`}
                                          >
                                            <label
                                              className="contact-label"
                                              for={response.name}
                                            >
                                              {response.label.replaceAll(
                                                "-",
                                                " "
                                              )}
                                            </label>
                                            <div className="error-messages">
                                              {response.name === "Country" ? (
                                                <select className="contact-input" name="Country" id="countries" onChange={getPhonecode}>
                                                  {myCountryCodesObject && Object.values(myCountryCodesObject).map((countryName, index) => (
                                                    <option key={index} selected={countryName === 'India'} value={countryName}>
                                                      {countryName}
                                                    </option>
                                                  ))}
                                                </select>
                                              ) : (
                                                <>
                                                  {response.type === 'tel' ? (
                                                    <input
                                                      className={`contact-input ${response.type === "tel"
                                                        ? "numericInput"
                                                        : ""
                                                        }`}
                                                      type={response.type}
                                                      id={response.name}
                                                      name={response.name}
                                                      onChange={handleInputChange}
                                                      value={phoneCodeVal}
                                                      placeholder={
                                                        response.options?.[0]
                                                      }
                                                    />
                                                  ) : (
                                                    <input
                                                      className={`contact-input ${response.type === "tel"
                                                        ? "numericInput"
                                                        : ""
                                                        }${response.type === "textarea"
                                                          ? "textareainput"
                                                          : ""
                                                        }`}
                                                      type={response.type}
                                                      id={response.name}
                                                      name={response.name}
                                                      placeholder={
                                                        response.options?.[0]
                                                      }
                                                    />
                                                  )
                                                  }
                                                </>
                                              )}

                                              {Object.keys(error).length > 0 && (
                                                <>
                                                  {Object.entries(error).map(
                                                    ([fieldName, errorMessage]) =>
                                                      fieldName ===
                                                        response.name ? (
                                                        <p
                                                          className="text-danger"
                                                          key={fieldName}
                                                        >
                                                          {errorMessage}
                                                        </p>
                                                      ) : null
                                                  )}
                                                </>
                                              )}
                                            </div>
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  </>
                                );
                              }
                            })}
                        </div>
                        <div className="recaptcha-error-messages error-messages">
                          <ReCAPTCHA
                            sitekey="6Ldr0V8pAAAAAL6BsypDa0TA5tSgQsVKIoRsNz1s"
                            onChange={handleCaptchaChange}
                            className="google-recaptcha"
                          />
                          {Object.keys(error).length > 0 && (
                            <>
                              {Object.entries(error).map(
                                ([fieldName, errorMessage]) =>
                                  fieldName === "g-recaptcha-response" ? (
                                    <p className="text-danger" key={fieldName}>
                                      {errorMessage}
                                    </p>
                                  ) : null
                              )}
                            </>
                          )}
                        </div>
                        <button type="submit" className="btn">
                          Send
                        </button>
                      </form>
                    </div>
                  </div>
                )}
              </div>
              <div className="contact-details">
                {isLoading ? (
                  <>
                    <Imgloader />
                    <Conloader />
                  </>
                ) : (
                  <>
                    <img
                      className="doodles-image"
                      src={contact1data.contact_image_details}
                      alt={contact1data.contact_image_details?.filename}
                    />
                    <div className="details">
                      {contact1data?.contact_details &&
                        contact1data?.contact_details.map((res, index) => {
                          return (
                            <div className="text">
                              <img
                                src={res.contact_section_logo}
                                alt={res.contact_section_logo}
                              />
                              <div>
                                {res.contact_content_link.title ==
                                  "Chat with us live" ? (
                                  <Link
                                    to="javascript:;"
                                    onClick={handleLinkClick}
                                    title={res.contact_content_link.title}
                                    target={res.contact_content_link.target}
                                  >
                                    {res.contact_content_link.title}
                                  </Link>
                                ) : res.contact_content_link.title ==
                                  "Subscribe to our newsletter" ? (
                                  <Link
                                    to="javascript:;"
                                    data-bs-toggle="modal"
                                    data-bs-target="#NewsModal"
                                  >
                                    {res.contact_content_link.title}
                                  </Link>
                                ) : (
                                  <Link
                                    to={res.contact_content_link.url}
                                    title={res.contact_content_link.title}
                                    target={res.contact_content_link.target}
                                  >
                                    {res.contact_content_link.title}
                                  </Link>
                                )}
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div
            class="modal fade"
            id="NewsModal"
            tabindex="-1"
            aria-labelledby="NewsModal"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered modal-lg">
              <div class="modal-content">
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
                <div class="modal-body">
                  <div id="quoteForm">
                    <h4>Subscribe to our newsletter</h4>
                    <form id="newsletter-form" onSubmit={handleFormNewSubmit}>
                      {newsletterForm &&
                        newsletterForm.map((response, key) => {
                          return (
                            <>
                              <div className="form-group">
                                <label>
                                  {response.label.replaceAll("-", " ")}*
                                </label>
                                <div className="error-messages">
                                  <input
                                    className={`form-control ${response.type === "tel"
                                      ? "numericInput"
                                      : ""
                                      }`}
                                    name={response.name}
                                    type={response.type}
                                  />
                                  {Object.keys(newerror).length > 0 && (
                                    <>
                                      {Object.entries(newerror).map(
                                        ([fieldName, errorMessage]) =>
                                          fieldName === response.name ? (
                                            <p
                                              className="text-danger"
                                              key={fieldName}
                                            >
                                              {errorMessage}
                                            </p>
                                          ) : null
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>
                            </>
                          );
                        })}
                      <div className="recaptcha-error-messages error-messages">
                        <ReCAPTCHA
                          sitekey="6Ldr0V8pAAAAAL6BsypDa0TA5tSgQsVKIoRsNz1s"
                          onChange={handleCaptchaChangeNewsletter}
                          className="recaptcha google-recaptcha google-recaptchacenter"
                        />
                        {Object.keys(newerror).length > 0 && (
                          <>
                            {Object.entries(newerror).map(
                              ([fieldName, errorMessage]) =>
                                fieldName === "g-recaptcha-response" ? (
                                  <p className="text-danger" key={fieldName}>
                                    {errorMessage}
                                  </p>
                                ) : null
                            )}
                          </>
                        )}
                      </div>
                      <div className="submit_button">
                        <button type="submit" className="btn btn-primary">
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default Contactone;
