import React, { useEffect, useState } from 'react'
import Conloader from '../componentsheaderfooter/Conloader';

function BrandThree(props) {
    const { brands3data } = props
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (brands3data.section_settings === true) {
            setIsLoading(false);
        }
    }, [brands3data.section_settings]);

    return (
        <>
            <div>
                {brands3data.section_settings === true ? (
                    <div className="section-spacing atlasdark" id='brand-today'>
                        <div className="container">
                            {isLoading ? (
                                <Conloader />
                            ) : (
                                <div className='brand-today'>
                                    <h3>{brands3data.brand_title}</h3>
                                    <p className='text-white' dangerouslySetInnerHTML={{ __html: brands3data.brand_content }}></p>
                                </div>
                            )}
                        </div>
                    </div>
                ) : (
                    <div></div>
                )}
            </div>
        </>
    )
}

export default BrandThree