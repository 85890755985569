import React, { useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Homesectionone from "./homepage/Homesectionone";
import Homesectiontwo from "./homepage/Homesectiontwo";
import Homesectionthree from "./homepage/Homesectionthree";
import Homesectionfour from "./homepage/Homesectionfour";
import Homesectionfive from "./homepage/Homesectionfive";
import Homesectionsix from "./homepage/Homesectionsix";
import Homesectionseven from "./homepage/Homesectionseven";
import Homesectioneight from "./homepage/Homesectioneight";
import Scrolldown from "./componentsheaderfooter/Scroll";
import "./Home.css";
import Homesectionnine from "./homepage/Homesectionnine";
import CommonCta from "./CommonCta";
import { apiCall, imageUpdate } from "./authentication";
import Imgloader from "./componentsheaderfooter/Imgloader";
import Conloader from "./componentsheaderfooter/Conloader";
const Home = () => {
  const [users1, setUsers1] = useState([]);
  const [homesection1, setHomesection1] = useState([]);
  const [homesection2, setHomesection2] = useState([]);
  const [homesection3, setHomesection3] = useState([]);
  const [homesection4, setHomesection4] = useState([]);
  const [homesection5, setHomesection5] = useState([]);
  const [homesection6, setHomesection6] = useState([]);
  const [homesection7, setHomesection7] = useState([]);
  const [homesection8, setHomesection8] = useState([]);
  const [homesection9, setHomesection9] = useState([]);
  const [homesection91, setHomesection91] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [yoastData, setYoastData] = useState({});
  const [sectionOneHeight, setSectionOneHeight] = useState(0);

  const updateSectionOneHeight = (height) => {
    setSectionOneHeight(height);
  };

  const slug = "wp/v2/pages/?slug=home&acf_format=standard";
  const type = "get";
  let finalreacturl;
  const fetchData = async () => {
    try {
      const result = await apiCall(slug, type);
      // Process data from the initial API response
      setUsers1(result);
      setHomesection1(result[0].acf.common_section[0]);
      setYoastData(result[0].yoast_head_json);

      result[0].acf.common_section.forEach((element) => {
        if (element.acf_fc_layout === "banner_section") {
          setHomesection1(element);
        }
        if (element.acf_fc_layout === "testimonials_section") {
          setHomesection8(element);
        }
        if (element.acf_fc_layout === "article_section") {
          setHomesection9(element);
        }
        if (element.txt_img_sec_name === "text_with_image_section_2") {
          setHomesection7(element);
        }
        if (element.txt_img_sec_name === "text_with_image_section_1") {
          setHomesection5(element);
        }
        if (element.acf_fc_layout === "our_partner_section") {
          setHomesection2(element);
        }
      });

      result[0].acf.middle_content_part.forEach((element) => {
        if (element.acf_fc_layout === "what_we_are") {
          setHomesection4(element);
        }
        if (element.acf_fc_layout === "career_section") {
          setHomesection91(element);
        }
        if (element.acf_fc_layout === "technologies") {
          setHomesection6(element);
        }
        if (element.acf_fc_layout === "services_list_with_images") {
          setHomesection3(element);
        }
      });

      setIsLoading(false);
      // })
    } catch (error) {
      // Handle API error
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    // Step 1: Fetch data from the initial REST API
    fetchData();



  }, [slug]); // Empty dependency array ensures the effect runs once
  console.log(yoastData.og_url);
  if (yoastData.og_url !== undefined) {
    const wpurl = yoastData.og_url.toString()
    const newurl = wpurl.replace('wpatlas', 'www');
    finalreacturl = newurl.slice(0, newurl.lastIndexOf('/'))
  }
  setTimeout(() => {
    imageUpdate();
  }, 1000);
  return (
    <>
      <div>
        {isLoading ? (
          <div className="container">
            <div className="row height-animation align-items-center">
              <div className="col-md-6">
                <Conloader />
              </div>
              <div className="col-md-6">
                <Imgloader />
              </div>
            </div>
          </div>
        ) : (
          <div>
            <HelmetProvider>
              <Helmet>
                <title>{yoastData.title}</title>
                <meta name="description" content={yoastData.description} />
                <link rel="canonical" hreflang="en" href={finalreacturl} />
                <meta
                  property="og:locale"
                  content="en_US"
                  className="yoast-seo-meta-tag"
                />
                <meta
                  property="og:type"
                  content="article"
                  className="yoast-seo-meta-tag"
                />
                <meta
                  property="og:title"
                  content={yoastData.og_title}
                  className="yoast-seo-meta-tag"
                />
                <meta
                  property="og:description"
                  content={yoastData.og_description}
                  className="yoast-seo-meta-tag"
                />
                <meta
                  property="og:url"
                  content={finalreacturl}
                  className="yoast-seo-meta-tag"
                />
                <meta
                  property="og:site_name"
                  content={yoastData.og_site_name}
                  className="yoast-seo-meta-tag"
                />
                <meta
                  property="article:modified_time"
                  content={yoastData.article_modified_time}
                  className="yoast-seo-meta-tag"
                />
                <meta
                  property="og:image"
                  content={yoastData.url}
                  className="yoast-seo-meta-tag"
                />
                {/* <meta property="og:image:width" content={yoastData.og_image.width} className="yoast-seo-meta-tag" />
                      <meta property="og:image:height" content={yoastData.og_image.height} className="yoast-seo-meta-tag" /> */}
                {/* <meta property="og:image:type" content={yoastData.og_image.type} className="yoast-seo-meta-tag" />
                      <meta name="twitter:card" content={yoastData.twitter_card} className="yoast-seo-meta-tag" /> */}
                <meta
                  name="twitter:label1"
                  content="Est. reading time"
                  className="yoast-seo-meta-tag"
                />
                <meta
                  name="twitter:data1"
                  content="1 minute"
                  className="yoast-seo-meta-tag"
                />
              </Helmet>
            </HelmetProvider>
            <Scrolldown />
            <Homesectionone section1data={homesection1} updateHeight={updateSectionOneHeight} />
            <Homesectiontwo section2data={homesection2} />
            <Homesectionthree section3data={homesection3} sectionOneHeight={sectionOneHeight}/>
            <Homesectionfour section4data={homesection4} />
            <Homesectionfive section5data={homesection5} />
            <Homesectionsix section6data={homesection6} />
            <Homesectionseven section7data={homesection7} />
            <Homesectioneight section8data={homesection8} />
            <Homesectionnine
              section9data={homesection9}
              section91data={homesection91}
            />
            <CommonCta />
            <div className="container">
              <div className="dots dot1"></div>
              <div className="dots dot2"></div>
              <div className="dots dot3"></div>
              <ul className="dotBox">
                <li className="dotIn1"></li>
                <li className="dotIn2"></li>
                <li className="dotIn3"></li>
                <li className="dotIn4"></li>
                <li className="dotIn5"></li>
                <li className="dotIn6"></li>
                <li className="dotIn7"></li>
                <li className="dotIn8"></li>
                <li className="dotIn9"></li>
                <li className="dotIn10"></li>
              </ul>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Home;
