import React, { useEffect, useState } from 'react'

const BackToTopButton = () => {

  const [isVisible, setIsVisible] = useState(false);

  // Scroll event listener to show/hide the button
  useEffect(() => {
    const toggleVisibility = () => {
      if (window.scrollY  > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility);

    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  return (
    isVisible && (
      <div id="back-to-top"><a href="#" title="Click to Top"><svg width="15px" height="10px" viewBox="0 0 15 10" enable-background="new 0 0 15 10">
			<path fill="#FFFFFF" d="M14.85,9.075l-0.751,0.771C14.008,9.938,13.888,10,13.753,10c-0.121,0-0.256-0.063-0.346-0.154L7.5,2.76 L1.593,9.846C1.503,9.938,1.368,10,1.248,10S0.992,9.938,0.902,9.846L0.15,9.075C0.06,8.982,0,8.844,0,8.721 c0-0.124,0.06-0.263,0.15-0.355l7.004-8.211C7.244,0.062,7.38,0,7.5,0s0.256,0.062,0.346,0.154l7.004,8.211 C14.939,8.458,15,8.597,15,8.721C15,8.844,14.939,8.982,14.85,9.075z"></path>
		</svg></a></div>
    )
  )
}

export default BackToTopButton
