import React from 'react';
import Artical1 from '../../src/img/servicesimg/artical1.png';
import Artical2 from '../../src/img/servicesimg/artical2.png';
import Artical3 from '../../src/img/servicesimg/artical3.png';

const NewsRoomstwo = () => {
    return (
        <div className='section-spacing pb-0'>
            <div className='container'>
                <div className='row justify-content-between'>
                    <NewaRoomArticles img={Artical1} head="Web Development" title="Cost to Develop a Website in 2023: The Complete Guide" date="July 26, 2023" />
                    <NewaRoomArticles img={Artical2} head="Online System" title="Building a Restaurant Online Reservation System Using WordPress" date="July 25, 2023" />
                    <NewaRoomArticles img={Artical3} head="Website Design" title="How to Turn Your Site into a Revenue Driver with Atlas SoftWeb’s Website Design Services" date="July 24, 2023" />
                    <NewaRoomArticles img={Artical1} head="Web Development" title="Cost to Develop a Website in 2023: The Complete Guide" date="July 26, 2023" />
                    <NewaRoomArticles img={Artical2} head="Online System" title="Building a Restaurant Online Reservation System Using WordPress" date="July 25, 2023" />
                    <NewaRoomArticles img={Artical3} head="Website Design" title="How to Turn Your Site into a Revenue Driver with Atlas SoftWeb’s Website Design Services" date="July 24, 2023" />
                    <NewaRoomArticles img={Artical1} head="Web Development" title="Cost to Develop a Website in 2023: The Complete Guide" date="July 26, 2023" />
                    <NewaRoomArticles img={Artical2} head="Online System" title="Building a Restaurant Online Reservation System Using WordPress" date="July 25, 2023" />
                    <NewaRoomArticles img={Artical3} head="Website Design" title="How to Turn Your Site into a Revenue Driver with Atlas SoftWeb’s Website Design Services" date="July 24, 2023" />
                </div>
            </div>
        </div>
    )
}

function NewaRoomArticles(props) {
    return (
        <div className='artical-card'>
            <div className='artical-card-img'>
                <img src={props.img} alt='' />
            </div>
            <div className='row justify-content-between align-items-center artical-card-head'>
                <div className='col'>
                    <p>
                        {props.head}
                    </p>
                </div>
                <div className='col'>
                    <div className="d-flex justify-content-end dot-flex-testi">
                        <div className="dot-testi light-green-testi"></div>
                        <div className="dot-testi green-testi"></div>
                        <div className="dot-testi blue-testi"></div>
                    </div>
                </div>
            </div>
            <div className='artical-card-title'>
                <h3>
                    {/* Cost to Develop a Website in 2023: The Complete Guide */}
                    {props.title}
                </h3>
                <p className='artical-card-date'>
                    {/* July 26, 2023 */}
                    {props.date}
                </p>
            </div>
        </div>
    )
}

export default NewsRoomstwo;