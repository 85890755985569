import React from "react";
import "./Thankyou.css";
import { Link } from "react-router-dom";

function Thankyou() {
  // const previousURL = document.referrer;
  // let pathname = "";
  // if (previousURL) {
  //   const urlObject = new URL(previousURL);

  //   // Get the pathname
  //   pathname = urlObject.pathname;
  // }

  return (
    <div className="container section-spacing" id="Thankyou">
      <div className="mt-5">
        <div className="thank-icon mb-2">
          <svg width="115px" height="115px" viewBox="0 0 133 133" version="1.1">
            <g
              id="check-group"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
            >
              <circle
                id="filled-circle"
                fill="#a2ba5f"
                cx="66.5"
                cy="66.5"
                r="54.5"
              />
              <circle
                id="white-circle"
                fill="#FFFFFF"
                cx="66.5"
                cy="66.5"
                r="55.5"
              />
              <circle
                id="outline"
                stroke="#a2ba5f"
                stroke-width="4"
                cx="66.5"
                cy="66.5"
                r="54.5"
              />
              <polyline
                id="check"
                stroke="#FFFFFF"
                stroke-width="5.5"
                points="41 70 56 85 92 49"
              />
            </g>
          </svg>
        </div>
        <h3>Thank you for reaching out to us.</h3>
              <p className="link-thank mb-3">We have received your request for a quote and have assigned a dedicated relationship manager to assist you. Expect to hear from them within the next 48 hours.</p>
              <p className="link-thank mb-3">
              In the meantime, feel free to explore our blogs for valuable insights. For urgent matters or further assistance, please don't hesitate to contact us at{" "}
                <Link to="mailto:info@atlassoftweb.com">
                  info@atlassoftweb.com
                </Link>.
              </p>
              <p className="link-thank mb-3">We're eager to help you with your needs.</p>
      </div>
    </div>
  );
}

export default Thankyou;
