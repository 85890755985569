import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import gsap, { TweenMax, Power0, TimelineMax } from "gsap";
import * as ScrollMagic from "scrollmagic";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
import Conloader from "../componentsheaderfooter/Conloader";

const CareersFour = (props) => {
  const { careers4data } = props;
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    ScrollMagicPluginGsap(ScrollMagic, gsap);
    const controllerMeed = new ScrollMagic.Controller({
      globalSceneOptions: { duration: 200, triggerHook: 0.5 },
    });

    careers4data?.perk_and_benefit_list.forEach((res, index) => {
      const triggerElement = document.querySelector("#careerss-card");

      new ScrollMagic.Scene({ triggerElement: `#careerss-card${index}` })
        .setClassToggle(`#careerss-card${index}`, "green-active")
        .addIndicators()
        .addTo(controllerMeed);
    });
  });
  useEffect(() => {
    if (careers4data.section_setting === true) {
      setIsLoading(false);
    }
  }, [careers4data]);
  return (
    <>
      {careers4data.section_setting === true ? (
        <section className="benefits-section">
          <div className="container">
            <div className="row">
              <div className="col-md-4 mt-md-5 pt-md-5 mt-5 mb-4 mb-md-5 pb-2 md-pb-5">
                {isLoading ? (
                  <Conloader />
                ) : (
                  <div className="perks-content">
                    <h3>{careers4data.perk_and_benefit}</h3>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: careers4data.perk_and_benefit_description,
                      }}
                    ></p>
                  </div>
                )}
              </div>
              <div className="col-md-1"></div>
              <div className="col-md-7 mt-md-5 mt-3 pt-md-5 mb-4 mb-md-5 pb-2 md-pb-5 overflow-hidden">
                <div className="position-relative">
                  {careers4data.perk_and_benefit_list &&
                    careers4data.perk_and_benefit_list.map((res, index) => {
                      return (
                        <>
                          {isLoading ? (
                            <Conloader />
                          ) : (
                            <div
                              id={`careerss-card${index}`}
                              className="perks-inner-section mb-4"
                            >
                              <div
                                id={`careerss-cards${index}`}
                                className="perks-text d-flex align-items-center box-black"
                              >
                                <img
                                  src={res.image.url}
                                  className="img-fluid"
                                  alt=""
                                />
                                <p>{res.content}</p>
                              </div>
                              <div className="d-flex dot-flex mt-md-4 mt-2 justify-content-end">
                                <div className="dot-sec"></div>
                                <div className="dot-sec1"></div>
                                <div className="dot-sec2"></div>
                              </div>
                            </div>)}
                        </>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default CareersFour;
