import React, { useEffect, useRef, useState } from "react";
import $ from "jquery";
import gsap, { TweenMax, Power0, TimelineMax } from "gsap";
import * as ScrollMagic from "scrollmagic";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
import Conloader from "../componentsheaderfooter/Conloader";

const Hiresectionsix = (props) => {
  const { hire6data } = props;
  const imageRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    ScrollMagicPluginGsap(ScrollMagic, TweenMax);

    const controller = new ScrollMagic.Controller();

    const scene = new ScrollMagic.Scene({
      triggerElement: ".hireproseccsection",
      triggerHook: 0.8, // Adjust this value based on when you want the animation to start
      duration: "50%",
      reverse: true,
    });

    var animationTimeline = new TimelineMax();
    animationTimeline.to(
      $(".commonanimation4"),
      {
        x: 0, // Adjust the value as needed
        opacity: 1,
        ease: Power0.easeNone,
      },
      0
    );

    // Set the timeline as the tween for the ScrollMagic scene
    scene.setTween(animationTimeline);
    // Add the scene to the controller
    scene.addTo(controller);

    // Intersection Observer to load image once it appears on the screen
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    const observer = new IntersectionObserver(handleIntersection, options);

    function handleIntersection(entries) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // Load the image when it becomes visible
          observer.unobserve(entry.target);
          gsap.to(entry.target, {
            duration: 0.5,
            opacity: 1,
            ease: Power0.easeNone,
          });
        }
      });
    }

    observer.observe(imageRef.current);

    return () => {
      controller.destroy();
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    if (hire6data.section_setting === true) {
      setIsLoading(false);
    }
  }, [hire6data.section_setting])

  return (
    <>
      {hire6data.section_setting === true ? (
        <div className="section-spacing atlasdark hireproseccsection">
          <div className="container">
            <div className="title-box commonanimation4">
              {isLoading ? (
                <Conloader />
              ) : (
                <>
                  <h2 className="text-white">{hire6data.hiring_process_title}</h2>
                </>
              )}
            </div>
            <div className="mt-4 commonanimation4">
              <figure>
                <img
                  ref={imageRef}
                  src={hire6data.hiring_process_list.url}
                  alt={hire6data.hiring_process_list.title}
                  style={{ opacity: 0 }}
                />
              </figure>
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default Hiresectionsix;
