import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import gsap, { TweenMax, Power0, TimelineMax } from "gsap";
import * as ScrollMagic from "scrollmagic";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
import { apiCall } from "../authentication";
import Conloader from "../componentsheaderfooter/Conloader";

const ServiceDetailosix = (props) => {
  const [portfolioPost, setPortfolioPost] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const handleLinkClick = () => {
    // Assuming tidioChatApi is globally accessible
    if (window.tidioChatApi) {
      window.tidioChatApi.display(true);
      window.tidioChatApi.open();
    }
  };

  const selectedIds = props?.innerwork.select_portfolio_project?.map(
    (article) => article
  );

  const stringIds = selectedIds.toString();

  useEffect(() => {
    ScrollMagicPluginGsap(ScrollMagic, TweenMax);

    const controller = new ScrollMagic.Controller();

    const scene = new ScrollMagic.Scene({
      triggerElement: ".servicesdetail7section",
      triggerHook: 0.7, // Adjust this value based on when you want the animation to start
      duration: "50%",
      reverse: true,
    });

    const scene1 = new ScrollMagic.Scene({
      triggerElement: ".servicesdetail8section",
      triggerHook: 0.7, // Adjust this value based on when you want the animation to start
      duration: "50%",
      reverse: true,
    });

    var animationTimeline = new TimelineMax();
    animationTimeline.to(
      $(".servicesdanimation7"),
      {
        x: 0, // Adjust the value as needed
        opacity: 1,
        ease: Power0.easeNone,
      },
      0
    );

    var animationTimeline1 = new TimelineMax();
    animationTimeline1.to(
      $(".servicesdanimation8"),
      {
        x: 0, // Adjust the value as needed
        opacity: 1,
        ease: Power0.easeNone,
      },
      0
    );

    // Set the timeline as the tween for the ScrollMagic scene
    scene.setTween(animationTimeline);
    scene1.setTween(animationTimeline1);
    // Add the scene to the controller
    scene.addTo(controller);
    scene1.addTo(controller);
    scene.setClassToggle(".servicesdetail8section", "title-colorchange");
    scene1.setClassToggle(".servicesdetail7section", "title-colorchange");

    return () => {
      controller.destroy();
    };
  }, []);

  const slug1 = `wp/v2/project?include=${stringIds}&_fields=id,title,_links,slug,_embedded.wp:featuredmedia&_embed`;
  const type = "get";
  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchPost = async () => {
      try {
        const data = await apiCall(slug1, type);
        setPortfolioPost(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    if (props.innercta2?.section_setting === true) {
      setIsLoading(false);
    }
    // Assuming 'id' is defined elsewhere in your component
    fetchPost();
  }, []);

  return (
    <>
      <div>
        {props?.innerwork?.section_setting === true &&
          props.innercta2?.section_setting === true ? (
          <>
            <div className="section-spacing">
              <section id="recent-work" className="servicesdetail7section">
                <div className="container servicesdanimation7">
                  <div className="row mx-0 p-0 justify-content-end align-items-center recent-work">
                    {isLoading ? (
                      <Conloader />
                    ) : (
                      <>
                        <div className="col-md-6 p-0">
                          <h5>{props?.innerwork?.portfolio_title}</h5>
                        </div>
                        <div className="col-md-6 p-0 recent-btn">
                          <Link
                            className="btn button-com"
                            to={props?.innerwork?.portfolio_button?.url}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="11.899"
                              height="19.555"
                              viewBox="0 0 11.899 19.555"
                            >
                              <path
                                id="Path_1094"
                                data-name="Path 1094"
                                d="M1122.005,1674.389l7.656,7.656-7.656,7.656"
                                transform="translate(-1119.884 -1672.268)"
                                fill="none"
                                stroke="#fff"
                              ></path>
                            </svg>
                            {props?.innerwork?.portfolio_button?.title}
                          </Link>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="work_card">
                    {portfolioPost?.map((res, key) => {
                      return (
                        <>
                          <div key={key} className="work_card-inner">
                            {isLoading ? (
                              <Conloader />
                            ) : (
                              <figure>
                                <Link to={"/work/" + res.slug}>
                                  <img
                                    src={
                                      res?._embedded["wp:featuredmedia"]?.[0]
                                        .source_url
                                    }
                                    alt={res?.title?.rendered}
                                  />
                                </Link>
                                <div className="dots">
                                  <span className="green"></span>
                                  <span className="black"></span>
                                  <span className="blue"></span>
                                </div>
                              </figure>
                            )}
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              </section>

              <section className="let-discuss-section servicesdetail8section servicesdanimation8">
                <div className="container">
                  {isLoading ? (
                    <Conloader />
                  ) : (
                    <div className="inner-content text-center">
                      <div className="title-box">
                        <h2>{props.innercta2?.cta_2_title}</h2>
                      </div>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: props.innercta2?.cta_2_content,
                        }}
                      ></p>
                      <Link
                        to="javascript:void(0);"
                        // to={props.innercta2?.cta_2_button?.url}
                        className="comman-btn top-space white-bg"
                        onClick={handleLinkClick}
                      >
                        {props.innercta2?.cta_2_button?.title}
                      </Link>
                    </div>
                  )}
                </div>
              </section>
            </div>
          </>
        ) : null}
      </div>
    </>
  );
};

export default ServiceDetailosix;
