import React from "react";
import { useState, useEffect } from "react";
import YouTube from "../../src/img/testimonial/youtube.png";
import { Link } from "react-router-dom";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import { apiCall } from "../authentication";
import Conloader from "../componentsheaderfooter/Conloader";
import Imgloader from "../componentsheaderfooter/Imgloader";

const Testimonialone = (props) => {
  const { test1data, test2data } = props;
  const [isLoading, setIsLoading] = useState([]);

  let [testimonialyoutube, setTestimonialyoutube] = useState([]);

  const [isVisible, setIsVisible] = useState(false);

  const handleVisibilityChange = (visible) => {
    if (visible) {
      setIsVisible(true);
    }
  };
  const slug1 = "wp/v2/testimonial?_embed";
  const type = "get";
  const fetchTestimonialsYoutube = async () => {
    try {
      const data = await apiCall(slug1, type);
      setTestimonialyoutube(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchTestimonialsYoutube();
    if (test1data?.section_settings === true || test2data?.section_setting === true) {
      setIsLoading(false);
    }
  }, [test1data?.section_settings, test2data?.section_setting]);

  return (
    <>
      <div className="pt-md-5 pb-md-5 pt-3 pb-3">
        <div className="container pt-5 pb-5">
          <section className="testimonials-section pt-md-5 pb-md-5">
            <div>
              {test1data && test1data?.section_settings === true && (
                <div className="row pb-lg-5 mb-lg-5">
                  <div className="col-12 pb-4 mb-lg-5">
                    {isLoading ? (
                      <Conloader />
                    ) : (
                      <>
                        <div className="client-content-inner">
                          <h3>{test1data?.banner_title}</h3>
                          <h2>{test1data?.banner_sub_title}</h2>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              )}
              {test2data &&
                test2data?.section_setting === true &&
                test2data?.counter_section.length > 0 && (
                  <>
                    <div className="row pt-4 mt-md-4 pb-5 mb-md-5">
                      {test2data.counter_section &&
                        test2data.counter_section.map((res, index) => {
                          return (
                            <VisibilitySensor
                              partialVisibility
                              onChange={handleVisibilityChange}
                            >
                              <div className="col-md-4 col-12">
                                {isLoading ? (
                                  <Imgloader />
                                ) : (
                                  <>
                                    <div className="d-flex client-flex align-items-start testimonial-counter">
                                      <div className="client-img counter-icon">
                                        <img
                                          src={res.testimonial_counter_icon}
                                          alt=""
                                          className="img-fluid"
                                        />
                                      </div>
                                      <div className="client-text">
                                        <h2>
                                          <CountUp
                                            start={0}
                                            end={res.testimonial_counter_numbers}
                                            duration={4}
                                          />
                                        </h2>
                                        <p>{res.testimonial_counter_text}</p>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                            </VisibilitySensor>
                          );
                        })}
                    </div>
                  </>
                )}
              <div className="row">
                {testimonialyoutube &&
                  testimonialyoutube.length > 0 &&
                  testimonialyoutube !== null &&
                  testimonialyoutube?.map((item, key) => {
                    return (
                      <>
                        <div class="col-md-6 mb-4 pb-4">
                          {isLoading ? (
                            <Imgloader />
                          ) : (
                            <>
                              <div class="client-main-content d-flex align-items-center">
                                <img
                                  src={
                                    item?._embedded?.["wp:featuredmedia"]?.[0]
                                      ?.source_url
                                  }
                                  class="img-fluid"
                                  alt=""
                                />
                                <div class="client-content">
                                  <h3>{item.title.rendered}</h3>
                                  <p>{item.acf.designation}</p>
                                </div>
                              </div>
                              <div class="watch-content mt-4">
                                {/* Using dangerouslySetInnerHTML to render HTML content */}
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: item.content.rendered,
                                  }}
                                ></div>
                                <p>
                                  <b>{item?.acf.testimonial_youtube_title}</b>
                                </p>
                                {item.acf.testimonial_youtube_link !== "" && (
                                  <Link
                                    to={item.acf.testimonial_youtube_link}
                                    target="_blank"
                                    rel="nofollow"
                                  >
                                    <img
                                      src={YouTube}
                                      className="img-fluid"
                                      alt="YouTube link"
                                    />
                                  </Link>
                                )}
                              </div>
                            </>
                          )}
                        </div >
                      </>
                    );
                  })}
              </div>
            </div>
          </section>
        </div >
      </div >
    </>
  );
};

export default Testimonialone;
