import React, { useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import CareersInnerone from "./careersinnerpage/CareersInnerone";
import "../src/CareersInner.css";
import { apiCall } from "./authentication";
import Conloader from "./componentsheaderfooter/Conloader";
import Imgloader from "./componentsheaderfooter/Imgloader";

const CareersInner = () => {
  const [yoastData, setYoastData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const slug1 = "wp/v2/career";
  const type = "get";
  useEffect(() => {
    const fetchCareer = async () => {
      try {
        const data = await apiCall(slug1, type);
        setYoastData(data[0].yoast_head_json);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchCareer();
  }, []);

  return (
    <>
      {isLoading ? (
        <div className="container">
          <div className="row height-animation align-items-center">
            <div className="col-md-8">
              <Conloader />
            </div>
            <div className="col-md-4">
              <Conloader />
            </div>
            <div className="col-md-8">
              <Conloader />
            </div>
            <div className="col-md-4">
              <Conloader />
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div>
            <HelmetProvider>
              <Helmet>
                <title>{yoastData.title}</title>
                <meta name="description" content={yoastData.description} />
                <meta
                  property="og:locale"
                  content="en_US"
                  className="yoast-seo-meta-tag"
                />
                <meta
                  property="og:type"
                  content="article"
                  className="yoast-seo-meta-tag"
                />
                <meta
                  property="og:title"
                  content={yoastData.og_title}
                  className="yoast-seo-meta-tag"
                />
                <meta
                  property="og:description"
                  content={yoastData.og_description}
                  className="yoast-seo-meta-tag"
                />
                <meta
                  property="og:url"
                  content={yoastData.og_url}
                  className="yoast-seo-meta-tag"
                />
                <meta
                  property="og:site_name"
                  content={yoastData.og_site_name}
                  className="yoast-seo-meta-tag"
                />
                <meta
                  property="article:modified_time"
                  content={yoastData.article_modified_time}
                  className="yoast-seo-meta-tag"
                />
                <meta
                  property="og:image"
                  content={yoastData.url}
                  className="yoast-seo-meta-tag"
                />
                {/* <meta property="og:image:width" content={yoastData.og_image.width} className="yoast-seo-meta-tag" />
                    <meta property="og:image:height" content={yoastData.og_image.height} className="yoast-seo-meta-tag" /> */}
                {/* <meta property="og:image:type" content={yoastData.og_image.type} className="yoast-seo-meta-tag" />
                    <meta name="twitter:card" content={yoastData.twitter_card} className="yoast-seo-meta-tag" /> */}
                <meta
                  name="twitter:label1"
                  content="Est. reading time"
                  className="yoast-seo-meta-tag"
                />
                <meta
                  name="twitter:data1"
                  content="1 minute"
                  className="yoast-seo-meta-tag"
                />
              </Helmet>
            </HelmetProvider>
            <CareersInnerone />
          </div>
        </div>
      )}
    </>
  );
};

export default CareersInner;
