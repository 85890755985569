import React, { useEffect, useState } from "react";
import Work1 from "../../src/img/work-1.png";
import SlidDetail from "./SlidDetail";
import $ from "jquery";
import gsap, { TweenMax, Power0, TimelineMax } from "gsap";
import * as ScrollMagic from "scrollmagic";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
import Conloader from "../componentsheaderfooter/Conloader";
import Imgloader from "../componentsheaderfooter/Imgloader";

const ServicesDetailofour = ({ inner, innerSecond }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoading1, setIsLoading1] = useState(true);
  const slider1Services = {
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: true,
    dots: true,
    arrows: false,
    focusOnSelect: true,
    autoplay: true,
    centerMode: false,
    centerPadding: "0",
    cssEase: "ease",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 970,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  useEffect(() => {
    ScrollMagicPluginGsap(ScrollMagic, TweenMax);

    const controller = new ScrollMagic.Controller();
    const controller1 = new ScrollMagic.Controller();

    const scene = new ScrollMagic.Scene({
      triggerElement: ".servicesdetail4section",
      triggerHook: 0.7, // Adjust this value based on when you want the animation to start
      duration: "50%",
      reverse: true,
    });

    const scene1 = new ScrollMagic.Scene({
      triggerElement: ".servicesdetail5section",
      triggerHook: 0.7, // Adjust this value based on when you want the animation to start
      duration: "50%",
      reverse: true,
    });

    var animationTimeline = new TimelineMax();
    animationTimeline.to(
      $(".servicesdanimation2"),
      {
        x: 0, // Adjust the value as needed
        opacity: 1,
        ease: Power0.easeNone,
      },
      0
    );

    var animationTimeline1 = new TimelineMax();
    animationTimeline1.to(
      $(".servicesdanimation21"),
      {
        x: 0, // Adjust the value as needed
        opacity: 1,
        ease: Power0.easeNone,
      },
      0
    );

    // Set the timeline as the tween for the ScrollMagic scene
    scene.setTween(animationTimeline);
    scene1.setTween(animationTimeline1);
    // Add the scene to the controller
    scene.addTo(controller);
    scene1.addTo(controller1);
    scene.setClassToggle(".servicesdetail4section", "title-colorchange");
    scene1.setClassToggle(".servicesdetail5section", "title-colorchange");

    if (inner?.section_setting === true) {
      setIsLoading(false);
    }
    if (innerSecond?.section_setting === true) {
      setIsLoading1(false);
    }

    return () => {
      controller.destroy();
    };
  }, []);

  return (
    <>
      {inner?.section_setting === true && (
        <>
          <section id="choose-slider" className="servicesdetail4section">
            <div className="container">
              <div className="row m-0 p-0 justify-content-between choose_head servicesdanimation2">
                {isLoading ? (
                  <Conloader />
                ) : (
                  <>
                    <div className="col-md-4 p-0">
                      <h2>{inner?.why_choose_title}</h2>
                    </div>
                    <div className="col-md-7 p-0">
                      <p
                        dangerouslySetInnerHTML={{
                          __html: inner?.why_choose_description,
                        }}
                      ></p>
                    </div>
                  </>
                )}
              </div>
              <div className="slider_choose servicesdanimation2">
                <div className="slider">
                  {inner &&
                    inner !== null &&
                    inner?.why_choose_type.length > 0 && (
                      <>
                        {isLoading ? (
                          <Imgloader />
                        ) : (
                          <SlidDetail
                            settings={slider1Services}
                            data={inner?.why_choose_type}
                          />
                        )}
                      </>
                    )}
                </div>
              </div>
            </div>
          </section>
        </>
      )}
      {innerSecond?.section_setting === true && (
        <>
          <section id="choose-accordion" className="servicesdetail5section">
            <div className="container">
              <div className="row m-0 p-0 justify-content-between choose_head servicesdanimation21">
                {isLoading1 ? (
                  <Conloader />
                ) : (
                  <>
                    <div className="col-md-4 p-0">
                      <h2>{innerSecond?.why_section_title}</h2>
                    </div>
                    <div className="col-md-7 p-0">
                      <p
                        dangerouslySetInnerHTML={{
                          __html: innerSecond?.why_section_desc,
                        }}
                      ></p>
                    </div>
                  </>
                )}
              </div>
              <div className="accordion_section servicesdanimation21">
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item col-md-6 col-12">
                    {isLoading1 ? (
                      <Conloader />
                    ) : (
                      <>
                        {innerSecond &&
                          inner !== null &&
                          innerSecond?.why_section_service_list.length > 0 &&
                          innerSecond?.why_section_service_list.map(
                            (detail, key) => {
                              return (
                                <h2
                                  className="accordion-header"
                                  id={`headingOne${key}`}
                                >
                                  <button
                                    className={`accordion-button ${key === 0 ? "" : "collapsed"
                                      }`}
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target={`#collapseOne${key}`}
                                    aria-expanded={key === 0 ? true : false}
                                    aria-controls={`collapseOne${key}`}
                                  >
                                    <span>0{key + 1} </span> {detail?.text}
                                  </button>
                                </h2>
                              );
                            }
                          )}
                      </>
                    )}
                  </div>
                  <div className="accordion-item col-md-6 col-12">
                    {isLoading1 ? (
                      <Imgloader />
                    ) : (
                      <>
                        {innerSecond &&
                          inner !== null &&
                          innerSecond?.why_section_service_list.length > 0 &&
                          innerSecond?.why_section_service_list.map(
                            (details, index) => {
                              return (
                                <div
                                  id={`collapseOne${index}`}
                                  className={`accordion-collapse ${index === 0 ? "collapse show" : "collapse"
                                    }`}
                                  aria-labelledby={`headingOne${index}`}
                                  data-bs-parent="#accordionExample"
                                >
                                  <div className="accordion-body">
                                    <figure>
                                      <img
                                        src={details?.image?.url}
                                        alt={details?.text}
                                      />
                                    </figure>
                                  </div>
                                </div>
                              );
                            }
                          )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div >
          </section >
        </>
      )}
    </>
  );
};

export default ServicesDetailofour;
